<template>
    <div id="integrations" class="pageStructure">
        <div class="allSection">
            <section class="sectionGauche pt-9 pb-15"
                     style="background: linear-gradient(rgba(29, 131, 211, 0.38) 0px, rgba(29, 131, 211, 0));">
                <div class="container">
                    <div class="my-7"><h4 class="text-center w-100"><i
                            class="fa-regular fa-plug titleIcon mr-2"></i>{{ $t('message.integrations') }}</h4></div>
                </div>
            </section>
            <div class="container">
                <section class="presentComp mt-8">
                    <div class="integrationAll aircall">
                        <div class="d-flex pt-8">
                            <div>
                                <div class="v-image v-responsive imgSelectionTI theme--light" style="height: 75px; width: 75px;">
                                    <div class="v-responsive__sizer" style="padding-bottom: 100%;"></div>
                                    <div class="v-image__image v-image__image--cover" style="background-image: url(https://www.objectif-crm.com/assets/images/aircall.png); background-position: center center;"></div>
                                    <div class="v-responsive__content" style="width: 320px;"></div>
                                </div>
                            </div>
                            <div class="titleVCard">
                                <div class="ocStrong">AIRCALL</div>
                                <div>{{ $t('message.ACtitle') }}</div>
                            </div>
                        </div>
                        <div class="appContentExplic">
                            <p>{{ $t('message.integrationBenefits') }}</p>
                            <ul>
                                <li v-html="$t('message.customerExperience')"></li>
                                <li v-html="$t('message.taskAutomation')"></li>
                                <li v-html="$t('message.performanceTracking')"></li>
                                <li v-html="$t('message.enhancedCollaboration')"></li>
                            </ul>
                            <p>{{ $t('message.summary') }}</p>
                        </div>
                    </div>
                    <div class="integrationAll ringover">
                        <div class="d-flex pt-8">
                            <div>
                                <div class="v-image v-responsive imgSelectionTI theme--light" style="height: 75px; width: 75px;">
                                    <div class="v-responsive__sizer" style="padding-bottom: 100%;"></div>
                                    <div class="v-image__image v-image__image--cover" style="background-image: url(https://application.betool.fr/img/ringover.49ca855d.png); background-position: center center;"></div>
                                    <div class="v-responsive__content" style="width: 800px;"></div>
                                </div>
                            </div>
                            <div class="titleVCard">
                                <div class="ocStrong">Ringover</div>
                                <div>{{ $t('message.RHtitle') }}</div>
                            </div>
                        </div>
                        <div class="appContentExplic">
                            <p>{{ $t('message.integrationBenefits2') }}</p>
                            <ul>
                                <li v-html="$t('message.costReduction')"></li>
                                <li v-html="$t('message.customerPersonalization')"></li>
                                <li v-html="$t('message.productivityImprovement')"></li>
                            </ul>
                            <p>{{ $t('message.summary2') }}</p>
                        </div>
                    </div>
                    <div class="integrationAll Gmap">
                        <div class="d-flex pt-8">
                            <div>
                                <v-img class="imgSelectionTI" height="75" width="75" src="https://application.betool.fr/img/googlemap.5bfcb1ce.png"></v-img>
                            </div>
                            <div class="titleVCard">
                                <div class="ocStrong">GOOGLE MAP</div>
                                <div>{{ $t('message.MAPtitle') }}</div>
                            </div>
                        </div>
                        <div class="appContentExplic">
                            <p>{{ $t('message.integrationBenefits3') }}</p>
                            <ul>
                                <li v-html="$t('message.customerLocalization')"></li>
                                <li v-html="$t('message.travelOptimization')"></li>
                                <li v-html="$t('message.dataAccuracy')"></li>
                                <li v-html="$t('message.dataVisualization')"></li>
                                <li v-html="$t('message.customerExperience3')"></li>
                            </ul>
                            <p>{{ $t('message.summary3') }}</p>
                        </div>
                    </div>
                    <div class="integrationAll stripe">
                        <div class="d-flex pt-8">
                            <div>
                                <v-img class="imgSelectionTI" height="75" width="75" src="https://application.betool.fr/img/stripe.3ab89de6.jpg"></v-img>
                            </div>
                            <div class="titleVCard">
                                <div class="ocStrong">STRIPE</div>
                                <div>{{ $t('message.SPtitle') }}</div>
                            </div>
                        </div>
                        <div class="appContentExplic">
                            <p>{{ $t('message.integrationBenefits4') }}</p>
                            <ul>
                                <li v-html="$t('message.paymentFacilitation')"></li>
                                <li v-html="$t('message.paymentAutomation')"></li>
                                <li v-html="$t('message.paymentSecurity')"></li>
                                <li v-html="$t('message.paymentTracking')"></li>
                                <li v-html="$t('message.toolIntegration')"></li>
                            </ul>
                            <p>{{ $t('message.summary4') }}</p>
                        </div>
                    </div>
                    <div class="integrationAll Gdrive">
                        <div class="d-flex pt-8">
                            <div>
                                <v-img class="imgSelectionTI" height="75" width="75" src="https://application.betool.fr/img/googledrive.8845336d.png"></v-img>
                            </div>
                            <div class="titleVCard">
                                <div class="ocStrong">GOOGLE DRIVE</div>
                                <div>{{ $t('message.GDtitle') }}</div>
                            </div>
                        </div>
                        <div class="appContentExplic">
                            <p>{{ $t('message.integrationBenefits5') }}</p>
                            <ul>
                                <li v-html="$t('message.centralizedStorage')"></li>
                                <li v-html="$t('message.quickAccess')"></li>
                                <li v-html="$t('message.realTimeCollaboration')"></li>
                                <li v-html="$t('message.dataBackupSecurity')"></li>
                                <li v-html="$t('message.toolIntegration5')"></li>
                            </ul>
                            <p>{{ $t('message.summary5') }}</p>
                        </div>
                    </div>
                    <div class="integrationAll yousign">
                        <div class="d-flex pt-8">
                            <div>
                                <v-img class="imgSelectionTI" height="75" width="75" src="https://application.betool.fr/img/yousign.0ea0dfff.jpg"></v-img>
                            </div>
                            <div class="titleVCard">
                                <div class="ocStrong">YOUSIGN</div>
                                <div>{{ $t('message.YStitle') }}</div>
                            </div>
                        </div>
                        <div class="appContentExplic">
                            <p>{{ $t('message.integrationBenefits6') }}</p>
                            <ul>
                                <li v-html="$t('message.simplifiedContractManagement')"></li>
                                <li v-html="$t('message.reducedSigningDelays')"></li>
                                <li v-html="$t('message.improvedProductivity')"></li>
                                <li v-html="$t('message.documentSecurity')"></li>
                                <li v-html="$t('message.toolIntegration6')"></li>
                            </ul>
                            <p>{{ $t('message.summary6') }}</p>
                        </div>
                    </div>
                    <div class="integrationAll allmysms">
                        <div class="d-flex pt-8">
                            <div>
                                <v-img class="imgSelectionTI" height="75" width="75" src="https://application.betool.fr/img/allmysms.2366c9ca.png"></v-img>
                            </div>
                            <div class="titleVCard">
                                <div class="ocStrong">Allmysms</div>
                                <div>{{ $t('message.AMStitle') }}</div>
                            </div>
                        </div>
                        <div class="appContentExplic">
                            <p>{{ $t('message.integrationBenefits7') }}</p>
                            <ul>
                                <li v-html="$t('message.improvedCommunication')"></li>
                                <li v-html="$t('message.increasedOpenRate')"></li>
                                <li v-html="$t('message.automatedSending')"></li>
                                <li v-html="$t('message.messagePersonalization')"></li>
                                <li v-html="$t('message.dataCentralization')"></li>
                            </ul>
                            <p>{{ $t('message.summary7') }}</p>
                        </div>
                    </div>
                    <div class="integrationAll docusign">
                        <div class="d-flex pt-8">
                            <div>
                                <v-img class="imgSelectionTI" height="75" width="75" src="https://www.objectif-crm.com/assets/images/docusignIcon.png"></v-img>
                            </div>
                            <div class="titleVCard">
                                <div class="ocStrong">Docusign</div>
                                <div>{{ $t('message.DStitle') }}</div>
                            </div>
                        </div>
                        <div class="appContentExplic">
                            <p>{{ $t('message.integrationBenefits8') }}</p>
                            <ul>
                                <li v-html="$t('message.electronicSignature8')"></li>
                                <li v-html="$t('message.documentCentralization')" ></li>
                                <li v-html="$t('message.processAutomation')"></li>
                                <li v-html="$t('message.integrationWithOtherApps')"></li>
                                <li v-html="$t('message.dataSecurity')"></li>
                            </ul>
                            <p>{{ $t('message.summary8') }}</p>
                        </div>
                    </div>
                </section>
                <div class="spacer"></div>
                <section>
                    <p></p>
                    <div class="row">
                        <div class="col-12 col-md-6 p-0 px-7">
                            <v-img width="100%" src="https://www.objectif-crm.com/assets/images/app1.jpg"></v-img>
                        </div>
                        <div class="col-12 col-md-6 p-0 px-7">
                            <v-img width="100%" src="https://www.objectif-crm.com/assets/images/app2.jpg"></v-img>
                        </div>
                    </div>
                </section>
            </div>
            <section class="sectionPub mt-8 pb-15">
                <div class="mr-2 w-100">
                    <div class="text-center actionSection v-card v-card--flat v-sheet theme--light">
                        <h4>{{ $t('message.customSoftware') }}</h4>
                        <div class="py-3">
                            <p class="mb-0 descripH1" style="max-width: 100%;">{{ $t('message.freeTrial') }}</p>
                        </div>
                        <div class="v-card__actions infoSpecial VCA justify-content-center">
                            <button type="button" class="v-btn v-btn--outlined theme--light v-size--default">
                                <span class="v-btn__content">{{ $t('message.getStarted') }} <i class="ml-2 fa-regular fa-right-long"></i></span>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
export default {
    name: "integrationsApp"
}
</script>

<style scoped>

</style>