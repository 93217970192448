var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-none"},[_c('section',{staticClass:"sectionPricing mt-8 pb-cus-150"},[_c('h4',{staticClass:"text-center m-0 w-100 mb-15",staticStyle:{"max-width":"100% !important"}},[_vm._v("Obtenir plus au meilleur prix")]),_c('div',{staticClass:"tablePrice"},[_c('div',{staticClass:"v-data-table theme--light"},[_c('div',{staticClass:"v-data-table__wrapper"},[_c('table',[_vm._m(0),_c('tbody',[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_c('tr',{staticClass:"noHover"},[_c('td'),_c('td',{staticClass:"BgColorOc leTitleB"},[_c('v-btn',{attrs:{"text":"","light":"","href":"https://application.betool.fr/register?acces=freetrial"}},[_vm._v("Commencez"),_c('br'),_vm._v("l'essai gratuit")])],1),_c('td',{staticClass:"text-center"}),_c('td',{staticClass:"text-center"}),_c('td',{staticClass:"text-center"})])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"text-left w-25 textTable"},[_vm._v("Ce graphique représente une liste partielle des fonctionnalités disponibles dans signNow, DocuSign, Adobe Sign, et HelloSign. ")]),_c('th',{staticClass:"thParam text-center BgColorOc leTitleH"},[_vm._v("beTool ")]),_c('th',{staticClass:"thParam text-center"},[_vm._v("Monday")]),_c('th',{staticClass:"thParam text-center"},[_vm._v("Sales Force")]),_c('th',{staticClass:"thParam text-center"},[_vm._v("Zoho")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"thParamP"},[_vm._v("Gerer la comptabilite de(s) entreprise(s) ")]),_c('td',{staticClass:"BgColorOc"},[_vm._v("x")]),_c('td',{staticClass:"text-center"},[_vm._v("x")]),_c('td',{staticClass:"text-center"},[_vm._v("x")]),_c('td',{staticClass:"text-center"},[_vm._v("x")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"thParamP"},[_vm._v("Marketing")]),_c('td',{staticClass:"BgColorOc"},[_vm._v("x")]),_c('td',{staticClass:"text-center"},[_vm._v("x")]),_c('td',{staticClass:"text-center"},[_vm._v("x")]),_c('td',{staticClass:"text-center"},[_vm._v("x")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"thParamP"},[_vm._v("Email")]),_c('td',{staticClass:"BgColorOc"},[_vm._v("x")]),_c('td',{staticClass:"text-center"},[_vm._v("x")]),_c('td',{staticClass:"text-center"},[_vm._v("x")]),_c('td',{staticClass:"text-center"},[_vm._v("x")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"thParamP"},[_vm._v("SMS")]),_c('td',{staticClass:"BgColorOc"},[_vm._v("x")]),_c('td',{staticClass:"text-center"},[_vm._v("x")]),_c('td',{staticClass:"text-center"},[_vm._v("x")]),_c('td',{staticClass:"text-center"},[_vm._v("x")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"thParamP"},[_vm._v("Comptabilite")]),_c('td',{staticClass:"BgColorOc"},[_vm._v("x")]),_c('td',{staticClass:"text-center"},[_vm._v("x")]),_c('td',{staticClass:"text-center"},[_vm._v("x")]),_c('td',{staticClass:"text-center"},[_vm._v("x")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"thParamP"},[_vm._v("Marketing")]),_c('td',{staticClass:"BgColorOc"},[_vm._v("x")]),_c('td',{staticClass:"text-center"},[_vm._v("x")]),_c('td',{staticClass:"text-center"},[_vm._v("x")]),_c('td',{staticClass:"text-center"},[_vm._v("x")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"thParamP"},[_vm._v("Email")]),_c('td',{staticClass:"BgColorOc"},[_vm._v("x")]),_c('td',{staticClass:"text-center"},[_vm._v("x")]),_c('td',{staticClass:"text-center"},[_vm._v("x")]),_c('td',{staticClass:"text-center"},[_vm._v("x")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"thParamP"},[_vm._v("SMS")]),_c('td',{staticClass:"BgColorOc"},[_vm._v("x")]),_c('td',{staticClass:"text-center"},[_vm._v("x")]),_c('td',{staticClass:"text-center"},[_vm._v("x")]),_c('td',{staticClass:"text-center"},[_vm._v("x")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"thParamP"},[_vm._v("Email")]),_c('td',{staticClass:"BgColorOc"},[_vm._v("x")]),_c('td',{staticClass:"text-center"},[_vm._v("x")]),_c('td',{staticClass:"text-center"},[_vm._v("x")]),_c('td',{staticClass:"text-center"},[_vm._v("x")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"impTable thParamPP"},[_vm._v("Prix du forfait par user")]),_c('td',{staticClass:"BgColorOc priTable"},[_vm._v("20 "),_c('span',{staticClass:"dolTable"},[_c('i',{staticClass:"fa-solid fa-dollar-sign"})])]),_c('td',{staticClass:"text-center priTable"},[_vm._v("30 "),_c('span',{staticClass:"dolTable"},[_c('i',{staticClass:"fa-solid fa-dollar-sign"})])]),_c('td',{staticClass:"text-center priTable"},[_vm._v("40 "),_c('span',{staticClass:"dolTable"},[_c('i',{staticClass:"fa-solid fa-dollar-sign"})])]),_c('td',{staticClass:"text-center priTable"},[_vm._v("50 "),_c('span',{staticClass:"dolTable"},[_c('i',{staticClass:"fa-solid fa-dollar-sign"})])])])
}]

export { render, staticRenderFns }