<template>
    <div class="pageStructure">
        <div class="container">
            <div class="allSection">
                <section class="sectionOffre mt-15" id="addFree" >
                    <h1 class="text-center mt-6">{{ $t('message.sloganPH1') }}</h1>
                    <div class="d-flex justify-content-center">
                        <p class="text-center descripH1 mt-5">{{ $t('message.description') }}</p>
                    </div>
                    <div class="d-flex mt-3">
                        <v-spacer></v-spacer>
                        <div class="mr-1" style="width: 225px; padding-top: 5px;">
                            <v-text-field :rules="rules" v-model="emailFreeTrial"  class="vTextFieldCustom " :placeholder="$t('message.emailPlaceholder')" outlined dense></v-text-field>
                        </div>
                        <div class="ml-1">
                            <v-btn :disabled="!validMail" :href="'https://application.betool.fr/register?email='+emailFreeTrial"  outlined color="primary">{{ $t('message.tryFree') }}</v-btn>
                            <div class="noteBtn">
                                <p class="d-inline-block mr-2">📢</p>{{ $t('message.noCreditCard') }}
                            </div>
                        </div>
                        <v-spacer></v-spacer>
                    </div>
                </section>
                <section id="addFree" class="sectionDuo mt-8 pb-cus-150">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="pddCrd position-relative v-card v-card--flat v-sheet theme--light">
                                <div class="position-absolute w-100 h-100" style="background-color: rgba(41, 115, 223, 0.08); inset: 0px; z-index: 9;"></div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="v-card__title crdTitle" style="white-space: unset;">{{ $t('message.crdTitle1') }}</div>
                                        <div class="v-card__text crdTxt">{{ $t('message.crdTxt1') }}</div>
                                        <div class="v-card__actions crdAction">
                                            <v-btn class="btnTxtAction" text @click="navigateToRefresh('noCode')">{{ $t('message.crdAction1') }}</v-btn>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="v-image v-responsive theme--light" style="height: 100%;">
                                            <div class="v-responsive__sizer" style="padding-bottom: 83.8298%;"></div>
                                            <div class="v-image__image v-image__image--cover"
                                                 style="background-image: url(https://www.objectif-crm.com/assets/images/construir.gif); background-position: center center;"></div>
                                            <div class="v-responsive__content" style="width: 940px;"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 ">
                            <div class="pddCrd position-relative v-card v-card--flat v-sheet theme--light">
                                <div class="position-absolute w-100 h-100" style="background-color: rgba(220, 255, 252, 0.47); inset: 0px; z-index: 9;"></div>
                                <div class="row">
                                    <div class="col-6" >
                                        <div class="v-card__title  crdTitle"  style="white-space: unset;">{{ $t('message.crdTitle2') }}</div>
                                        <div class="v-card__text crdTxt">{{ $t('message.crdTxt2') }}</div>
                                        <div class="v-card__actions crdAction">
                                            <v-btn class="btnTxtAction" text href="https://application.betool.fr/register?acces=freetrial">
                                                {{ $t('message.crdAction2') }}
                                            </v-btn>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="v-image v-responsive theme--light" style="height: 100%;">
                                            <div class="v-responsive__sizer" style="padding-bottom: 83.8298%;"></div>
                                            <div class="v-image__image v-image__image--cover"
                                                 style="background-image: url(https://www.objectif-crm.com/assets/images/demoo.png); background-position: center center;"></div>
                                            <div class="v-responsive__content" style="width: 940px;"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <allInOne/>
                <why-choose/>
                <section class="sectionIntegration mt-12 pb-cus-150">
                    <div class="row">
                        <div class="h-100 col" style="margin-top: auto; margin-bottom: auto;">
                            <div class="d-flex justify-content-center align-items-center">
                                <div class="mt-5 v-card v-card--flat v-sheet theme--light">
                                    <h4>{{ $t('message.integrationSectionTitle') }}</h4>
                                    <p class="descripH1 mb-0">{{ $t('message.integrationSectionText') }}</p>
                                    <div class="my-3"><i>{{ $t('message.integrationSectionPlugins') }}</i></div>
                                    <div class="v-card__actions infoSpecial VCA p-0">
                                        <v-btn style="margin-left: -8px;" text @click="navigateToRefresh('integrations')">
                                            {{ $t('message.integrationSectionButton') }} <i class="ml-2 fa-solid fa-right-long"></i>
                                        </v-btn>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="v-image v-responsive imgIntegre mt-5 theme--light">
                                <div class="v-responsive__sizer" style="padding-bottom: 80.4745%;"></div>
                                <div class="v-image__image v-image__image--cover"
                                     style="background-image: url(https://i.ibb.co/mHPDZ6K/Captureewrewrew.png); background-position: center center;"></div>
                                <div class="v-responsive__content" style="width: 548px;"></div>
                            </div>
                        </div>
                    </div>
                </section>
                <section-pub/>
                <table-compare/>
                <testimonial-vue/>
                <chiffre-comp/>
                <suppot-assist/>
                <pubBleu/>
            </div>
        </div>
    </div>
</template>

<script>
import pubBleu from "@/components/micro/pubBleu.vue";
import allInOne from "@/components/micro/allInOne.vue";
import WhyChoose from "@/components/micro/whyChoose.vue";
import TableCompare from "@/components/micro/tableCompare.vue";
import SuppotAssist from "@/components/micro/suppotAssist.vue";
import SectionPub from "@/components/micro/sectionPub.vue";
import TestimonialVue from "@/components/micro/testimonialVue.vue";
import ChiffreComp from "@/components/micro/chiffreComp.vue";

export default {
    name: 'HelloWorld',
    components: {
        ChiffreComp,
        TestimonialVue,
        SectionPub,
        SuppotAssist,
        TableCompare,
        WhyChoose,
        pubBleu,
        allInOne
    },
    data: () => ({
        valid: true,
        emailFreeTrial:'',
        rules: [
            v => !!v || 'Email is required',
            v => /.+@.+\..+/.test(v) || 'Email must be valid',
        ],
        ecosystem: [
            {
                text: 'vuetify-loader',
                href: 'https://github.com/vuetifyjs/vuetify-loader',
            },
            {
                text: 'github',
                href: 'https://github.com/vuetifyjs/vuetify',
            },
            {
                text: 'awesome-vuetify',
                href: 'https://github.com/vuetifyjs/awesome-vuetify',
            },
        ],
        importantLinks: [
            {
                text: 'Documentation',
                href: 'https://vuetifyjs.com',
            },
            {
                text: 'Chat',
                href: 'https://community.vuetifyjs.com',
            },
            {
                text: 'Made with Vuetify',
                href: 'https://madewithvuejs.com/vuetify',
            },
            {
                text: 'Twitter',
                href: 'https://twitter.com/vuetifyjs',
            },
            {
                text: 'Articles',
                href: 'https://medium.com/vuetify',
            },
        ],
        whatsNext: [
            {
                text: 'Explore components',
                href: 'https://vuetifyjs.com/components/api-explorer',
            },
            {
                text: 'Select a layout',
                href: 'https://vuetifyjs.com/getting-started/pre-made-layouts',
            },
            {
                text: 'Frequently Asked Questions',
                href: 'https://vuetifyjs.com/getting-started/frequently-asked-questions',
            },
        ],
        modalLead: false,
    }),
    methods: {

        navigateTo(routeName) {
            if (this.$route.name !== routeName) {
                this.$router.push({name: routeName});
            }
        },
        navigateToRefresh(routeName) {
            if (this.$route.name !== routeName) {
                //  this.$router.push({name: routeName});
                window.location = routeName;
            }
        },

    },
    computed: {
        validMail() {
            return !!this.emailFreeTrial && /.+@.+\..+/.test(this.emailFreeTrial);
        }
    }
}
</script>
