<template>
    <div id="contact" class="pageStructure">
        <div class="allSection">
            <section class="sectionGauche pt-9 pb-15" style="background: linear-gradient(rgba(29, 131, 211, 0.38) 0px, rgba(29, 131, 211, 0));">
                <div class="container">
                    <div class="my-7">
                        <h4 class="text-center w-100"><i class="fas fa-user-headset titleIcon mr-2"></i>{{ $t('message.contact_title') }}</h4>
                    </div>
                </div>
            </section>
            <section class="sectionGauche mt-9 pb-15">
                <div class="container">
                    <div id="assist" class="row">
                        <div class="col-12">
                            <div class="assistanceCubev" style="height: 126px;">
                                <div class="d-flex h-100 align-items-center">
                                    <div style="width: 214px;">
                                        <i class="fa-solid fa-phone-office"></i>
                                        <h5>{{ $t('message.commercial_service') }}</h5>
                                    </div>
                                    <div class="d-flex justify-content-end w-100">
                                        <div>
                                            <a href="tel:+33755549045" class="v-btn v-btn--text theme--light v-size--default">
                  <span class="v-btn__content">
                    <i class="far fa-phone" style="font-size: 19px;"></i> {{ $t('message.by_phone') }}
                  </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="assistanceCubev">
                                <i class="fas fa-user-headset"></i>
                                <h5>{{ $t('message.technical_support') }}</h5>
                                <p class="pSupport">{{ $t('message.technical_support_description') }}</p>
                                <a href="mailto:contact@betool.fr" class="v-btn v-btn--text theme--light v-size--default">
            <span class="v-btn__content">
              <i class="fad fa-at" style="font-size: 19px;"></i> {{ $t('message.by_email') }}
            </span>
                                </a>
                                <a href="tel:+33755549045" class="v-btn v-btn--text theme--light v-size--default">
            <span class="v-btn__content">
              <i class="far fa-phone" style="font-size: 19px;"></i> {{ $t('message.by_phone') }}
            </span>
                                </a>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="assistanceCubev">
                                <i class="fad fa-people-carry"></i>
                                <h5>{{ $t('message.support_assistance') }}</h5>
                                <p class="pSupport">{{ $t('message.support_assistance_description') }}</p>
                                <a href="mailto:contact@betool.fr" class="v-btn v-btn--text theme--light v-size--default">
            <span class="v-btn__content">
              <i class="fad fa-at" style="font-size: 19px;"></i> {{ $t('message.by_email') }}
            </span>
                                </a>
                                <a href="tel:+33755549045" class="v-btn v-btn--text theme--light v-size--default">
            <span class="v-btn__content">
              <i class="far fa-phone" style="font-size: 19px;"></i> {{ $t('message.by_phone') }}
            </span>
                                </a>
                                <a class="v-btn v-btn--text theme--light v-size--default" @click="navigateToRefresh('support')">
            <span class="v-btn__content">
              <i class="fa-light fa-ticket-simple" style="font-size: 19px;"></i> {{ $t('message.open_ticket') }}
            </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <support-stage/>
            <section-pub/>
        </div>
        <modal-support :modal-support.sync="modalOpen"/>
    </div>
</template>

<script>

import axios from 'axios';
import SectionPub from "@/components/micro/sectionPub.vue";
import SupportStage from "@/components/micro/supportStage.vue";
import ModalSupport from "@/components/modal/modalSupport.vue";

export default {
    name: "contactApp",
    components: {ModalSupport, SupportStage, SectionPub},

    data: () => ({
        valid: true,
        modalOpen: false,
        form: {
            companyName: '',
            helpRequest: '',
            phoneNumber: '',
            email: '',
        },
        nameRules: [
            v => !!v || 'Le nom de l\'entreprise est requis',
        ],
        requestRules: [
            v => !!v || 'Le sujet de la demande est requis',
        ],
        phoneRules: [
            v => !!v || 'Le numéro de téléphone est requis',
        ],
        emailRules: [
            v => !!v || 'L\'email est requis',
            v => /.+@.+/.test(v) || 'L\'email doit être valide',
        ],

    }),
    methods: {
        navigateTo(routeName) {
            if (this.$route.name !== routeName) {
                this.$router.push({name: routeName});
            }
        },
        navigateToRefresh(routeName) {
            if (this.$route.name !== routeName) {
                //  this.$router.push({name: routeName});
                window.location = routeName;
            }
        },
        submit() {
            if (this.$refs.form.validate()) {

                axios.post('https://www.objectif-crm.com/supportTech/indexSupport.php', this.form)
                    .then(() => {
                        this.$refs.form.reset();
                        this.modalSupport = false;
                        // ferme le modal
                        // gérer la réponse du serveur si nécessaire
                    })
                    .catch(error => {
                        console.error(error);
                        // gérer les erreurs
                    });
            }
        },
    },
}
</script>

<style scoped>

</style>