<template>
    <section>
        <h4 class="text-center mb-5">{{ $t('message.titleTesti') }}</h4>
        <v-carousel cycle height="500" hide-delimiter-background show-arrows-on-hover class="">
            <v-carousel-item v-for="(testimonialVue, index) in testimonials" :key="index">
                <div class="h-100 d-flex align-items-center justify-content-center">
                    <div>
                        <v-card flat class="mx-auto" max-width="500">
                            <v-card-text>
                                <div class="text-center personImg">
                                    <v-img class="imgTesti" style="width: 106px;" :src="testimonialVue.image" contain></v-img>
                                </div>
                                <div class="text-center companyName">{{ testimonialVue.company }}</div>
                                <div class="text-center personName">{{ testimonialVue.name }}</div>
                                <div class="text-center messageTesti">{{ testimonialVue.message }}</div>
                                <div class="text-center messageTesti">{{ testimonialVue.etoiles }} <i class="fa-solid fa-star-sharp" style="color: #fcda58"></i></div>
                            </v-card-text>
                        </v-card>
                    </div>

                </div>

            </v-carousel-item>

        </v-carousel>
        <div class="w-100 text-center"><div class="d-inline-block"><strong>Score - 4.6 </strong><i class="fa-solid fa-star-sharp" style="color: #fcda58"></i><i class="fa-solid fa-star-sharp" style="color: #fcda58"></i><i class="fa-solid fa-star-sharp" style="color: #fcda58"></i><i class="fa-solid fa-star-sharp" style="color: #fcda58"></i><i class="fa-solid fa-star-half-stroke"  style="color: #fcda58"></i></div></div>
    </section>
</template>

<script>
export default {
    name: "testimonialVue",
    data() {
        return {
            activeSlide: 0,
            testimonials: [
                {
                    name: 'Roger A.',
                    etoiles: '5',
                    company: 'PSE Multiservices.',
                    message: 'Ce CRM me permet de gérer mes différentes activités à un prix compétitif. Tout est modifiable et adaptable en fonction des besoins. Très satisfait!',
                    image: 'https://objectif-crm.com/assets/images/pse_multiservices.png',
                    // image: 'https://www.objectif-crm.com/assets/images/port1.png'
                },
                {
                    name: 'Stephane S.',
                    etoiles: '4.2',
                    company: 'UFEN Groupe',
                    message: 'Super outil, plutôt ludique dans l\'ensemble. Très content, rien à dire.',
                    image: 'http://ufen.fr/wp-content/uploads/2020/02/logo-V2.png'
                },
                {
                    name: 'Jordan U.',
                    etoiles: '5',
                    company: 'Lead Service',
                    message: 'Très content, vraiment. Tout est fonctionnel avec un service support de qualité',
                    image: 'https://www.objectif-crm.com/assets/images/port1.png'
                },
                {
                    name: 'Chloé L.',
                    etoiles: '4.5',
                    company: 'OrForma',
                    message: 'Outil très pratique et personnalisable en fonction des besoins de la société. Service très réactif, je recommande',
                    image: 'https://orforma-academie.fr/wp-content/uploads/2022/05/cropped-online_couerse_center-1.png'
                },

                {
                    name: 'Mendel G.',
                    etoiles: '4',
                    company: 'YOLLO Solution',
                    message: 'Facile d\'utilisation, autonome. L\'équipe est très disponible et sympathique',
                    image: 'https://objectif-crm.com/assets/images/yollo.webp'
                },

            ]
        };
    }
}
</script>

<style scoped>

</style>