<template>
    <div>
        <section class="sectionPubBleu mt-8 pb-8">
            <div class="actionReaction">
                <div class="row">
                    <div class="col-12 mt-3 d-block d-md-none px-0 v-card v-card--flat v-sheet theme--light" style="background-color: transparent;">
                        <div class="imgPubBas">
                            <div class="v-image v-responsive d-inline-block imgPubUnit theme--light">
                                <div class="v-responsive__sizer" style="padding-bottom: 83.2812%;"></div>
                                <div class="v-image__image v-image__image--cover" style="background-image: url(https://i.ibb.co/XpV4kY0/topEner.png); background-position: center center;"></div>
                                <div class="v-responsive__content" style="width: 640px;"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 px-0 v-card v-card--flat v-sheet theme--light" style="background-color: transparent;">
                        <div class="v-card__text pl-1 mt-4" style="color: white;">{{ $t('message.beReady') }}</div>
                        <h4 style="color: white;">{{ $t('message.getAccessNow') }}</h4>
                        <div class="v-card__actions mt-11">

                            <v-btn outlined color="white" href="https://application.betool.fr/register">
                                {{ $t('message.startTrial') }}
                            </v-btn>
                            <v-btn light color="primary" @click="modalOpen = true">
                                {{ $t('message.requestCallback') }}
                            </v-btn>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 d-none d-md-block px-0 v-card v-card--flat v-sheet theme--light" style="background-color: transparent;">
                        <div class="imgPubBas">
                            <div class="v-image v-responsive d-inline-block imgPubUnit theme--light">
                                <div class="v-responsive__sizer" style="padding-bottom: 83.2812%;"></div>
                                <div class="v-image__image v-image__image--cover" style="background-image: url(https://i.ibb.co/XpV4kY0/topEner.png); background-position: center center;"></div>
                                <div class="v-responsive__content" style="width: 640px;"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <modal-lead :modal-lead.sync="modalOpen"/>
    </div>

</template>

<script>
import ModalLead from "@/components/modal/modalLead.vue";

export default {
    name: "pubBleu",
    components: {ModalLead},
    data() {
        return {
            valid: true,
            modalOpen: false,
        };
    },
}
</script>

<style scoped>

</style>