<template>
    <div>
        <section id="CGU" class="container" style="padding-top: 120px">
            <div>
                <h1>{{ $t('message.TermsOfUse') }}</h1>
                <p class="text-center mb-15">{{ $t('message.EffectiveDate') }}</p>
                <p>{{ $t('message.TermsDescription0') }}</p>
                <p>{{ $t('message.TermsDescription1') }}</p>
                <p>{{ $t('message.TermsDescription2') }}</p>
                <p>{{ $t('message.TermsDescription3') }}</p>
                <p>{{ $t('message.TermsDescription4')}}</p>
            </div>

            <div>
                <h2>{{ $t('message.IntroductionTitle') }}</h2>
                <p>{{ $t('message.IntroductionDescription') }}</p>
                <p>{{ $t('message.IntroductionTerms') }}</p>
                <p>{{ $t('message.IntroductionUsage') }}</p>
                <p>{{ $t('message.IntroductionAcceptance') }}</p>
            </div>
            <div>
                <h2>{{ $t('message.DefinitionsTitle') }}</h2>
                <ul>
                    <li>{{ $t('message.Fournisseur') }} : {{ $t('message.FournisseurDescription') }}</li>
                    <li>{{ $t('message.Client') }} : {{ $t('message.ClientDescription') }}</li>
                    <li>{{ $t('message.Utilisateur') }} : {{ $t('message.UtilisateurDescription') }}</li>
                    <li>{{ $t('message.Site') }} : {{ $t('message.SiteDescription') }}</li>
                    <li>{{ $t('message.Services') }} : {{ $t('message.ServicesDescription') }}</li>
                    <li>{{ $t('message.LicenceUtilisateur') }} : {{ $t('message.LicenceUtilisateurDescription') }}</li>
                    <li>{{ $t('message.LicenceApplication') }} : {{ $t('message.LicenceApplicationDescription') }}</li>
                </ul>
            </div>
            <div>
                <h2>{{ $t('message.objectTitle') }}</h2>
                <p>{{ $t('message.objectDescription1') }}</p>
                <p>{{ $t('message.objectDescription2') }}</p>
                <p>{{ $t('message.objectDescription3') }}</p>
                <p>{{ $t('message.objectDescription4') }}</p>
            </div>
            <div>
                <h2>{{ $t('message.conditionsTitle') }}</h2>
                <p>{{ $t('message.conditionsDescription1') }}</p>
                <p>{{ $t('message.conditionsDescription2') }}</p>
                <p>{{ $t('message.conditionsDescription3') }}</p>
                <p>{{ $t('message.conditionsDescription4') }}</p>
                <p>{{ $t('message.conditionsDescription5') }}</p>
            </div>
            <div>
                <h2>{{ $t('message.accessTitle') }}</h2>
                <p>{{ $t('message.accessDescription1') }}</p>
                <ul>
<!--                    <li>{{ $t('message.accessService1') }}</li>-->
                    <li>{{ $t('message.accessService2') }}</li>
                    <li>{{ $t('message.accessService3') }}</li>
<!--                    <li>{{ $t('message.accessService4') }}</li>-->
<!--                    <li>{{ $t('message.accessService5') }}</li>-->
                </ul>
                <p>{{ $t('message.accessDescription2') }}</p>
                <p>{{ $t('message.accessDescription3') }}</p>
                <p>{{ $t('message.accessDescription4') }}</p>
                <p>{{ $t('message.accessDescription5') }}</p>
<!--                <p>{{ $t('message.accessDescription6') }}</p>-->
<!--                <p>{{ $t('message.accessDescription7') }}</p>-->
<!--                <p>{{ $t('message.accessDescription8') }}</p>-->
            </div>
            <div>
                <h2>{{ $t('message.intellectualPropertyTitle') }}</h2>
                <p>{{ $t('message.intellectualPropertyDescription1') }}</p>
                <p>{{ $t('message.intellectualPropertyDescription2') }}</p>
                <p>{{ $t('message.intellectualPropertyDescription3') }}</p>
                <p>{{ $t('message.intellectualPropertyDescription4') }}</p>
            </div>
            <div>
                <h2>{{ $t('message.privacyTitle') }}</h2>
                <p>{{ $t('message.privacyDescription1') }}</p>
                <ul>
                    <li>1. <strong>{{ $t('message.privacyCollectDataTitle') }}</strong> {{ $t('message.privacyCollectDataDescription') }}</li>
                    <li>2. <strong>{{ $t('message.privacyUseDataTitle') }}</strong> {{ $t('message.privacyUseDataDescription') }}</li>
                    <li>3. <strong>{{ $t('message.privacyDataStorageTitle') }}</strong> {{ $t('message.privacyDataStorageDescription') }}</li>
                    <li>4. <strong>{{ $t('message.privacyUserRightsTitle') }}</strong> {{ $t('message.privacyUserRightsDescription') }}</li>
                    <li>5. <strong>{{ $t('message.privacyDataRetentionTitle') }}</strong> {{ $t('message.privacyDataRetentionDescription') }}</li>
                </ul>
                <p>{{ $t('message.privacyPolicyLink') }}</p>
            </div>
            <div>
                <h2>{{ $t('message.userPublicationTitle') }}</h2>
                <p>{{ $t('message.userPublicationDescription1') }}</p>
                <ul>
                    <li>{{ $t('message.userPublicationTemplate') }}</li>
                    <li>{{ $t('message.userPublicationTextContent') }}</li>
                    <li>{{ $t('message.userPublicationPhotoVideo') }}</li>
                </ul>
                <p>{{ $t('message.userPublicationDescription2') }}</p>
                <p>{{ $t('message.userPublicationDescription3') }}</p>
                <p>{{ $t('message.userPublicationDescription4') }}</p>
                <p>{{ $t('message.userPublicationDescription5') }}</p>
                <p>{{ $t('message.userPublicationDescription6') }}</p>
                <p>{{ $t('message.userPublicationDescription7') }}</p>
<!--                <p>{{ $t('message.userPublicationDescription8') }}</p>-->
<!--                <p>{{ $t('message.userPublicationDescription9') }}</p>-->
            </div>
            <div>
                <h2>{{ $t('message.responsibilityTitle') }}</h2>
                <p>{{ $t('message.responsibilityDescription1') }}</p>
                <p>{{ $t('message.responsibilityDescription2') }}</p>
                <p>{{ $t('message.responsibilityDescription3') }}</p>
<!--                <p>{{ $t('message.responsibilityDescription4') }}</p>-->
            </div>
            <div>
                <h2>{{ $t('message.paymentTermsTitle') }}</h2>
                <p>{{ $t('message.paymentTermsDescription1') }}</p>
                <ul>
                    <li>{{ $t('message.paymentTermsItem1') }}</li>
                    <li>{{ $t('message.paymentTermsItem2') }}</li>
                    <li>{{ $t('message.paymentTermsItem3') }}</li>
                </ul>
                <p>{{ $t('message.paymentTermsDescription2') }}</p>
                <p>{{ $t('message.paymentTermsDescription3') }}</p>
                <p>{{ $t('message.paymentTermsDescription4') }}</p>
            </div>
            <div>
                <h2>{{ $t('message.terminationSuspensionTitle') }}</h2>
                <ul>
                    <li>1. <strong>{{ $t('message.userTerminationTitle') }}</strong> {{ $t('message.userTerminationDescription') }}</li>
                    <li>2. <strong>{{ $t('message.providerTerminationTitle') }}</strong> {{ $t('message.providerTerminationDescription') }}</li>
                    <li>3. <strong>{{ $t('message.terminationConsequencesTitle') }}</strong> {{ $t('message.terminationConsequencesDescription') }}</li>
                    <li>4. <strong>{{ $t('message.dataArchivingTitle') }}</strong> {{ $t('message.dataArchivingDescription') }}</li>
                </ul>
            </div>
            <div>
                <h2>{{ $t('message.lawJurisdictionTitle') }}</h2>
                <p>{{ $t('message.lawApplicableDescription') }}</p>
                <p>{{ $t('message.disputeResolutionDescription') }}</p>
                <p>{{ $t('message.userFinalDisclaimerDescription') }}</p>
                <p>{{ $t('message.independenceDisclaimerDescription') }}</p>
                <p>{{ $t('message.waiverDescription') }}</p>
                <p>{{ $t('message.articleTitlesDisclaimerDescription') }}</p>
            </div>
            <div>
                <h2>{{ $t('message.hyperlinkTitle') }}</h2>
                <p>{{ $t('message.hyperlinkDescription') }}</p>
            </div>
            <div>
                <h2>{{ $t('message.cookiesTitle') }}</h2>
                <p>{{ $t('message.cookiesDescription1') }}</p>
                <p>{{ $t('message.cookiesDescription2') }}</p>
                <p>{{ $t('message.cookiesDescription3') }}</p>
                <p>{{ $t('message.cookiesDescription4') }}</p>
                <p>{{ $t('message.cookiesDescription5') }}</p>
                <p>{{ $t('message.cookiesDescription6') }}</p>
                <p>{{ $t('message.cookiesDescription7') }}</p>
            </div>
            <hr>
        </section>
    </div>
</template>

<script>
export default {
    name: "CGU"
}
</script>

<style scoped>

</style>