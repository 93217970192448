export default {
    message: {
        //fin
        construct: 'En construction -> 30/06',
        sloganPH1: 'Le logiciel de travail noCode qui s\'adapte à votre entreprise',
        applicativeServices: 'Services applicatifs',
        function: 'Fonction',
        manageCommunicateAutomate: 'Gérez, communiquez, automatisez vos procédures grâce à nos services',
        betoolServices: "Les services de beTool sont les canaux d'exécution fonctionnelle qui permettent une meilleure gestion de l'harmonie du travail et de la gestion.",
        concreteExamples: 'Exemples concrets:',
        dynamicForm: 'Formulaire dynamique',
        productCatalogue: 'Catalogue produit',
        calendar: 'Calendrier',
        accounting: 'Comptabilité',
        learnMore: 'En savoir plus',
        appsPlugins: 'Applications et plugins',
        connectivity: 'Connectivité',
        connectActivate: 'Connectez et activez des fonctionnalités grâce à des applications et plugins en tout genre.',
        appsProposed: 'Les applications proposées facilitent la gestion des relations avec des applications ou des systèmes externes, favorisant ainsi la centralisation des données et les fonctionnalités.',
        collaboratorsManagement: 'Gestion des collaborateurs',
        optimization: 'Optimisation',
        optimizeTeams: 'Optimiser vos équipes et leur production',
        managementRights: 'La gestion des collaborateurs en matière de droits et de permissions est cruciale pour une productivité optimale et une gestion précise des rôles.',
        taskManagement: 'Gestion des tâches',
        projectManagement: 'Gestion de projet',
        automatedWorkProcesses: 'Processus de travail automatisés',
        createYours: 'Créez le vôtre',
        dynamism: 'Dynamisme',
        createDatabase: 'Créez vos bases de données grâce à une administration dynamique et noCode',
        dynamicCreationTool: 'Avec l\'outil de création dynamique, la création de modèles de données sur mesure vous permet de collaborer et d\'éditer des documents en toute simplicité.',
        crm: 'CRM',
        erp: 'ERP',
        kanban: 'Kanban',
        ticketing: 'Ticketing',
        applicationServices: 'SERVICES APPLICATIFS',
        services: 'Services',
        allInOne: 'Tout en un',
        servicesDescription: 'Nos services permettent de simplifier la gestion de vos données en proposant des structures complexes préconçues, adaptées à différents secteurs d\'activité. Ils vous aident à créer des bases de données performantes et à maximiser l\'utilisation de l\'outil grâce à des formations sur mesure. En somme, nos services sont essentiels pour gérer, communiquer et automatiser vos procédures.',
        appAndPlugins: 'APPLICATIONS ET PLUGINS',
        integration: 'Intégrations',
        effectiveCommunication: 'Intégrez des moyens de communication efficaces',
        integrationDescription: 'Personnalisez facilement votre CRM afin qu\'il travaille pour vous, sans avoir besoin de faire appel à un développeur. Modifiez les étapes de signature de contrat, ajoutez autant de colonnes que vous le souhaitez, gérez plusieurs pipelines à la fois, et bien plus encore.',
        moreThan100Plugins: 'Plus de 100 plugins',
        discoverIntegrations: 'Découvrer les intégrations et plugins',
        collaboratorManagement: 'Gestion des collaborateurs',
        collaboration: 'Collaboration',
        collaborateToSucceed: 'Collaborez pour réussir et innover',
        collaborationDescription: 'Une bonne collaboration permet de centraliser toutes les informations relatives à un projet, telles que les tâches, les délais, les commentaires, les fichiers, les messages, etc. Cela permet à chaque utilisateur de l\'équipe d\'accéder rapidement et facilement à toutes les informations dont il a besoin pour accomplir son travail. Elle permet également de structurer l\'équipe de manière à ce que chaque utilisateur ait un rôle clair et des responsabilités définies. Cela permet d\'optimiser la collaboration et de réduire les erreurs de traitement.',
        customizableToMatch: 'Personnalisable pour correspondre à votre cycle de vente',
        customizationDescription: 'Grâce à notre plateforme noCode, vous pouvez personnaliser facilement vos processus et créer des scénarios d\'automatisation sur mesure, sans recourir à un développeur. Vous pouvez modifier les étapes, ajouter autant de données que vous le souhaitez et gérer plusieurs collaborateurs sur plusieurs pipelines simultanément. Tout cela vous permet d\'être autonome et d\'utiliser notre plateforme sans nécessiter l\'intervention d\'un développeur.',
        discoverProcessData: 'Découvrez process data',
        home: "Accueil",
        applicationsPlugins: "Applications et Plugins",
        features: "Fonctionnalités",
        automations: "Les Automatisations",
        integrations: "Les Intégrations",
        views: "Les Vues",
        electronicSignature: "La Signature Électronique",
        client: "Client",
        login: "Connexion",
        termsOfUse : "CGU",
        support: "Support",
        contact: "Contact",
        underConstruction: "Site en construction / Prévu pour 30/06/23",
        connectActivateFeatures: 'Connectez et activez des fonctionnalités grâce à des applications et plug-in\'s en tout genre.',
        api: 'API',
        applications: 'Applications',
        synchronization: 'Synchronisation',
        createDatabasesDynamicNOCODE: 'Créez vos bases de données grâce à une administration dynamique et noCode.',
        tickets: 'Tickets',
        freeTrial:'Essai gratuit',
        signup: 'Inscription',

        tasks: 'Tâches',
        hello: 'Bonjour',
        tools: 'Outils',
        toolbox: 'Boîte à outils',
        appServices: 'Services applicatifs',
        email: 'Email',
        documents: 'Documents',
        sms: 'SMS',
        optimizeTeamsProduction: 'Optimisez vos équipes et leur production',
        roles: 'Rôles',
        permissions: 'Permissions',
        letAutomationsWork: 'Laissez les automatisations travailler pour vous et concentrez-vous sur l\'essentiel',
        simplifyWorkflow: 'Simplifiez votre flux de travail grâce aux intégrations',
        maximizeProductivityEfficiency: 'Maximisez votre productivité et votre efficacité grâce aux vues bien conçues',
        saveTimeEnvironment: 'Gagnez du temps pour vous et pour votre environnement, plus facile et plus sûr',
        pricing: 'Prix',
        contactUs: 'Nous contacter',
        maximizeProductivity: 'Maximisez votre productivité et votre efficacité grâce aux vues bien conçues',
        company: 'Entreprise',
        aboutUs: 'À propos de nous',
        whyBeTool: 'Pourquoi beTool ?',
        whatIsNocode: 'Qu\'est-ce que le noCode ?',
        supportAide: 'Aides Support',
        technicalSupport: 'Technique',
        salesSupport: 'Commercial',
        productTools: 'Produits outils',
        legal: 'Légal',
        dmca: 'DMCA',
        CGU: 'Conditions générales d\'utilisation',
        privacyPolicy: 'Politique de confidentialité',
        noCreditCard: 'Aucune carte de crédit requise',
        tryFree: 'Essayer gratuitement',
        allRightsReserved: 'Tous droits réservés.',
        // ic1

        description: 'Optimisez la gestion des projets professionnels, tels que la gestion collaborative, la gestion des prospects et des relations clients de votre entreprise, grâce à une solution ludique, 100% dynamique et sans code "noCode".',
        emailPlaceholder: 'Email',
        crdTitle1: 'Moduler mon environnement de travail',
        crdTxt1: 'Une solution noCode pleine de ressources.',
        crdAction1: "Qu'est ce que le noCode ?",
        crdTitle2: 'Essai gratuit sans engagement',
        crdTxt2: 'On vous propose 15 jours d’essai',
        crdAction2: "J'essaie",
        sectionTitle: 'Tout-en-un',
        card1Title: 'Gérez votre équipe de travail',
        card1Text: 'Modulez et améliorez la collaboration de vos équipes et obtenez une visibilité complète sur les processus de production à l\'aide de fonctionnalités avancées adaptées à vos besoins.',
        card2Title: 'Créez des procédures de gestion',
        card2Text: 'Créez facilement des objets de gestion grâce à des widgets fonctionnels et applicatifs en drag & drop, tout en mesurant les permissions des intervenants.',
        card3Title: 'Profitez de services et d\'applications',
        card3Text: 'L’interface intuitive propose des services tel que: Email, SMS, Produits, Calendrier et plus encore.',
        card3Link1: 'Services ?',
        card3Link2: 'Intégrations ?',
        wyBetool: 'Pourquoi choisir beTool ?',
        point1Title: 'Une solution noCode',
        point1Text: 'Modulable facilement en drag & drop',
        point2Title: '100% autonome',
        point2Text: 'Un paramétrage simple et guidé',
        point3Title: 'Plus de 30 thèmes templates',
        point3Text: 'Thèmes adaptés à votre métier / travail',
        point4Title: 'Option de partage',
        point4Text: 'Partagez vos objets de travail facilement',
        buttonText: 'Commencer l\'essai gratuit',
        integrationSectionTitle: 'Grâce aux applications et plugins, restez connecté à vos API\'s préférés',
        integrationSectionText: 'Concluez des affaires et accélérez vos projets sans quitter votre l\'application. Améliorez la synchronisation, l\'efficacité et la productivité de votre équipe en vous connectant à des prestataires intégrés à la solution.',
        integrationSectionPlugins: 'Plus de 40 plugins',
        integrationSectionButton: 'Découvrer les intégrations de beTool',
        sectionPubTitle: 'Votre logiciel de travail sur mesure',
        sectionPubText: 'Essai gratuit 15 jours - Aucune carte de crédit requise',
        sectionPubButton: 'J\'y vais',
        sectionPricingTitle: 'Obtenir plus au meilleur prix',
        sectionPricingTableHeader: 'Ce graphique représente une liste partielle des fonctionnalités disponibles dans signNow, DocuSign, Adobe Sign, et HelloSign.',
        featureAccounting: 'Gérez la comptabilité de votre/vos entreprise(s)',
        featureMarketing: 'Marketing',
        featureEmail: 'Email',
        featureSms: 'SMS',
        packagePricePerUser: 'Prix du forfait par user',
        startFreeTrial: 'Commencez l\'essai gratuit',
        title: 'Support et assistances',
        apis: "API's",
        apiDescription: "Une documentation API est disponible pour tous les besoins d'import, d'export ou de synchronisation",
        tutorials: 'Tutoriels',
        tutorialsDescription: "Apprenez tous les astuces et méthodes de maîtrise de l’outil via des tutoriaux implantés un peu partout ou dans l’espace Tuto",
        assistance: 'Assistances',
        assistanceDescription: "Notre équipe d'assistants techniques et commerciaux se tient à votre disposition pour vous aider dans vos démarches",
        beReady: 'SOYEZ PRÊT À EN AVOIR PLUS',
        getAccessNow: 'Obtenez dès à présent vos accès !',
        startTrial: "Demarrer l'essai",
        requestCallback: 'Demande de rappel',

// Page automation
        automation: 'Les automatisations',
        advantages: 'Les avantages',
        time_saving: 'Gain de temps',
        time_saving_detail: 'L\'automatisation permet de gagner du temps en éliminant les tâches manuelles et répétitives, telles que la collecte et la saisie de données, l\'envoi de rappels ou la mise à jour des statuts. Cela permet aux utilisateurs de l\'équipe de se concentrer sur des tâches à plus forte valeur ajoutée.',
        error_reduction: 'Réduction des erreurs',
        error_reduction_detail: 'Les tâches automatisées sont moins sujettes aux erreurs humaines, ce qui permet de réduire les risques d\'erreur et d\'améliorer la qualité des résultats.',
        productivity_increase: 'Augmentation de la productivité',
        productivity_increase_detail: 'L\'automatisation permet de libérer du temps pour des tâches plus importantes, ce qui permet d\'augmenter la productivité de l\'équipe.',
        process_optimization: 'Optimisation des processus',
        process_optimization_detail: 'L\'automatisation peut également aider à optimiser les processus en identifiant les goulots d\'étranglement et les inefficacités. En optimisant les processus, l\'équipe peut réduire les coûts et améliorer la qualité des résultats.',
        collaboration_improvement: 'Amélioration de la collaboration',
        collaboration_improvement_detail: 'L\'automatisation peut faciliter la collaboration en éliminant les tâches manuelles et en permettant aux membres de l\'équipe de se concentrer sur la collaboration et la communication.',
        cost_reduction: 'Réduction des coûts',
        cost_reduction_detail: 'L\'automatisation peut réduire les coûts en éliminant les tâches manuelles et en optimisant les processus. Cela permet également de réduire les risques d\'erreur et de retard, ce qui peut réduire les coûts globaux du projet.',
        email_templates: 'Envover des emails via des templates',
        create_records: 'Créer des enregistrements vers d\'autres processus',
        create_commissions: 'Créer des commissions grâce à des règles prévues',
        post_data: 'Faire un POST de données maitrisées vers un système INT/EXT',

        // about_us
        about_us: 'À propos de nous',
        introduction_title: 'Introduction',
        introduction_text: "Notre entreprise est dédiée à fournir aux entrepreneurs un outil de gestion d'entreprise révolutionnaire. Notre solution est complètement modulable et ne nécessite aucune connaissance en développement informatique noCode. Nous sommes passionnés par l'idée de proposer une administration fluide et ludique, dans l'air du temps, pour que les entrepreneurs puissent gérer tous leurs processus à partir d'un seul outil. Nous croyons en la force de la technologie pour aider les entreprises à prospérer et nous sommes fiers de proposer une solution scalable et modulable, avec une grande maîtrise des données.",
        history_title: 'Histoire',
        history_text: "<strong>beTool</strong> a été créée en 2018 avec un objectif modeste : créer un petit outil pour un secteur spécifique. Au fil du temps, grâce à nos clients, nous avons compris que le monde du digital grandissait et nécessitait de la modularité, de l'efficacité, de la connectivité et bien d'autres atouts pour répondre aux besoins des entrepreneurs. C'est ainsi que, en 2020, nous avons recommencé notre projet 'page blanche' en créant une architecture capable de répondre à une grande partie des attentes de nos clients, dans le but de leur proposer tout ce dont ils ont besoin. Nous sommes fiers de notre parcours et nous continuerons à écouter nos clients et à innover pour leur offrir les meilleurs outils de gestion d'entreprise.",
        team_title: 'Team',
        administration_title: 'Administration',
        developers_title: 'Developpeurs',
        values: 'Valeurs',
        innovationCreativity: 'Innovation et créativité',
        innovationCreativityDescription: 'Nous sommes passionnés par l\'innovation et la créativité, et nous travaillons chaque jour pour améliorer nos produits et services. Nous sommes constamment à l\'écoute des besoins de nos clients et nous cherchons toujours de nouvelles solutions pour répondre à leurs demandes.',
        transparencyIntegrity: 'Transparence et intégrité',
        transparencyIntegrityDescription: 'Nous sommes engagés à maintenir des relations transparentes et honnêtes avec nos clients, nos partenaires et nos employés. Nous sommes fiers de notre intégrité et nous sommes toujours ouverts aux commentaires et aux critiques constructives de nos clients pour nous améliorer.',
        connectivityDescription: 'Nous visons l\'excellence dans tout ce que nous faisons, de la conception de nos produits à la qualité de notre service client. Nous sommes déterminés à offrir une expérience exceptionnelle à nos clients à tous les niveaux.',
        excellence: 'Excellence',
        excellenceDescription: 'Nous sommes convaincus que la connectivité est essentielle pour permettre à nos clients de rester compétitifs sur un marché en constante évolution. Nous offrons des solutions qui permettent à nos clients de connecter leurs différents processus, outils et systèmes afin d\'optimiser leur fonctionnement.',
        sustainability: 'Durabilité',
        sustainabilityDescription: 'Nous sommes conscients de l\'impact de nos activités sur l\'environnement et nous sommes engagés à minimiser notre empreinte écologique. Nous cherchons constamment à trouver des solutions durables pour nos produits et services, tout en contribuant à la réussite de nos clients.',
        produits_services: 'Produits ou services',
        produit_betooll: 'Notre produit : beTool',
        produit_betooll_description: 'Nous sommes fiers de présenter notre produit, beTool, un outil de gestion d\'entreprise tout-en-un et modulable et NoCode. Avec beTool, les entrepreneurs peuvent gérer facilement les processus de leur entreprise à partir d\'un seul outil. Nous offrons une administration fluide et ludique, qui est toujours à jour avec les dernières tendances du marché, pour que nos clients bénéficient d\'une expérience utilisateur optimale.',
        flexibilite: 'Flexibilité',
        flexibilite_description: 'Nous sommes conscients que chaque entreprise est unique, c\'est pourquoi nous offrons des solutions flexibles qui peuvent être personnalisées pour répondre aux besoins spécifiques de chaque entreprise. Avec beTool, nos clients peuvent choisir les modules qu\'ils souhaitent utiliser, pour une solution sur mesure qui répond à leurs besoins.',
        connectivite: 'Connectivité',
        connectivite_description: 'Nous sommes convaincus que la connectivité est essentielle pour le succès des entreprises aujourd\'hui. C\'est pourquoi nous avons développé beTool pour qu\'il se connecte facilement avec d\'autres applications et outils d\'entreprise. Nous sommes déterminés à offrir une expérience utilisateur optimale à nos clients grâce à une connectivité maximale.',
        scalabilite: 'Scalabilité',
        scalabilite_description: 'Nous comprenons que les besoins des entreprises peuvent évoluer avec le temps, c\'est pourquoi nous offrons une solution évolutive et modulable. Nos clients peuvent ajouter ou supprimer des modules en fonction de l\'évolution de leurs besoins, sans avoir à changer de plateforme.',
        support_commercial_technique: 'Support commercial et technique',
        service_client_qualite: 'Service client de qualité',
        service_client_qualite_description: 'Nous sommes fiers d\'offrir un service client exceptionnel à nos clients. Notre équipe de support est disponible pour répondre aux questions et aider les clients à tout moment. Nous sommes déterminés à offrir une expérience client exceptionnelle à tous nos clients.',
        service_commercial: 'Service commercial',
        service_commercial_description: 'Notre équipe commerciale est là pour aider les clients à trouver les solutions beTool les mieux adaptées à leurs besoins. Nous comprenons que chaque entreprise a des besoins uniques et nous sommes déterminés à trouver la solution beTool qui répond le mieux à ces besoins.',
        consultation_gratuite: 'Consultation gratuite',
        consultation_gratuite_description: 'Nous offrons une consultation gratuite pour aider les entreprises à comprendre comment beTool peut les aider à atteindre leurs objectifs commerciaux. Nos consultants sont disponibles pour discuter des besoins de l\'entreprise et proposer des solutions beTool qui peuvent aider à résoudre les problèmes et améliorer l\'efficacité.',
        formation_support: 'Formation et support',
        formation_support_description: 'Nous offrons une formation approfondie à tous les utilisateurs beTool pour garantir qu\'ils utilisent la plateforme de manière optimale. Notre équipe de support est également disponible pour répondre à toutes les questions et résoudre les problèmes. Nous sommes déterminés à aider les entreprises à maximiser leur utilisation de beTool et à en tirer le meilleur parti possible.',
        partenariats_commerciaux: 'Partenariats commerciaux',
        partenariats_commerciaux_description: 'Nous sommes ouverts à établir des partenariats avec d\'autres entreprises pour offrir des solutions beTool qui répondent aux besoins de leurs clients. Si vous êtes intéressé par un partenariat commercial, n\'hésitez pas à nous contacter pour discuter de la manière dont nous pouvons travailler ensemble pour offrir des solutions de gestion d\'entreprise optimales.',

// noCode
        nocode_title: 'Le noCode, c\'est quoi ? et c\'est pour qui ?',
        nocode_description: 'La révolution du noCode est en train de transformer la manière dont les entreprises abordent le développement d\'applications et la gestion de leur infrastructure informatique. Cette approche democratise l\'innovation et permet aux entreprises de créer des solutions sur mesure pour répondre à leurs besoins spécifiques, tout en libérant les développeurs pour travailler sur des projets plus complexes et à forte valeur ajoutée.',
        intro_title: 'Introduction au noCode',
        intro_description1: 'Une plateforme de construction de logiciel noCode est un outil puissant pour les entreprises qui souhaitent développer rapidement des applications professionnelles sans avoir besoin de connaissances en programmation. Cette plateforme permet aux utilisateurs de créer des logiciels de travail et de gestion de projet en utilisant uniquement des éléments visuels, tels que des formulaires, des tableaux et des processus de travail prédéfinis. Cela signifie que les équipes peuvent développer des applications sur mesure pour répondre à leurs besoins uniques, sans avoir à dépendre d\'un développeur.',
        intro_description2: 'Avec une plateforme noCode, les utilisateurs peuvent construire des applications en quelques heures, plutôt que des semaines ou des mois, ce qui leur permet de gagner du temps et de l\'argent. De plus, les applications développées à l\'aide de cette plateforme sont souvent plus flexibles et adaptables que les applications construites à partir de zéro. Les utilisateurs peuvent ajouter et supprimer des fonctionnalités, modifier des processus de travail et personnaliser les interfaces utilisateur en quelques clics, sans avoir à changer le code source de l\'application.',
        advantages_title: 'Les avantages',
        limit_skills: 'Pas de limites de compétences :',
        cost_reduction2: 'Coût réduit :',
        flexibility: 'Flexibilité :',
        scalability: 'Évolutivité :',
        market_speed: 'Rapidité de mise sur le marché :',
        no_maintenance: 'Pas de maintenance :',
        reasons_title: 'Les raisons de passer au nocode',
        limit_skills_description: 'Le NoCode permet à des personnes sans formation en codage de créer des applications et des sites web. Cela signifie que les entreprises peuvent créer des outils internes sans avoir à embaucher des développeurs, ou les personnes individuelles peuvent lancer des projets sans avoir à investir dans des compétences techniques coûteuses.',
        time_saving_point1: 'Les outils NoCode sont souvent dotés de fonctionnalités prêtes à l\'emploi, qui permettent aux utilisateurs de créer rapidement des applications et des sites web sans avoir à tout développer à partir de zéro.',
        time_saving_point2: 'Les utilisateurs peuvent expérimenter rapidement des idées et des concepts, ce qui peut accélérer le processus d\'innovation.',
        time_saving_point3: 'Les outils NoCode permettent également une livraison plus rapide de projets, car il y a moins de dépendance sur des équipes de développement tiers.',
        cost_reduction_point1: 'Les outils NoCode sont souvent abordables ou même gratuits, ce qui peut réduire considérablement les coûts de développement pour les entreprises et les individus.',
        cost_reduction_point2: 'Les entreprises n\'ont pas besoin d\'embaucher des développeurs pour créer des outils internes, ce qui peut réduire les coûts de développement à long terme.',
        flexibility_point1: 'Les outils NoCode sont souvent modulaires et faciles à adapter, ce qui permet aux utilisateurs de créer des solutions personnalisées pour répondre à leurs besoins spécifiques.',
        flexibility_point2: 'Les outils NoCode peuvent être utilisés pour créer une variété de solutions, y compris des applications mobiles, des sites web, des chatbots, des formulaires en ligne et bien plus encore.',
        flexibility_point3: 'Les outils NoCode permettent aux utilisateurs de modifier facilement leurs solutions en temps réel, sans avoir besoin de l\'aide d\'un développeur tiers.',
        scalability_point1: 'Les applications et les sites web créés avec des outils NoCode peuvent être facilement mis à l\'échelle pour répondre aux besoins d\'une entreprise en croissance.',
        scalability_point2: 'Les utilisateurs peuvent ajouter des fonctionnalités supplémentaires à leur solution NoCode pour répondre aux besoins en constante évolution de leur entreprise ou de leur projet.',
        integrations_description: 'Les outils de noCode sont souvent conçus pour se connecter facilement à d\'autres applications et services, permettant ainsi une intégration fluide dans les flux de travail existants. Les intégrations permettent aux entreprises de rationaliser leurs processus, d\'augmenter leur efficacité et de réduire les erreurs humaines.',
        automation_description: 'Les outils de noCode permettent de réaliser des automatisations de processus complexes, sans nécessiter de connaissances en programmation. Cela permet aux entreprises de gagner du temps et de l\'argent en automatisant des tâches répétitives et chronophages. Les automatisations peuvent être utilisées pour des processus internes tels que la gestion des ressources humaines, ou pour des tâches externes telles que l\'envoi d\'emails personnalisés à des clients.',
        market_speed_point1: 'Les outils NoCode sont souvent dotés de fonctionnalités prêtes à l\'emploi, qui permettent aux utilisateurs de créer rapidement des applications et des sites web sans avoir à tout développer à partir de zéro.',
        market_speed_point2: 'Les utilisateurs peuvent expérimenter rapidement des idées et des concepts, ce qui peut accélérer le processus d\'innovation.',
        market_speed_point3: 'Les outils NoCode permettent également une livraison plus rapide de projets, car il y a moins de dépendance sur des équipes de développement tiers.',
        no_maintenance_description: 'L\'un des avantages clés du noCode est qu\'il élimine le besoin de maintenance continue. Les outils de noCode sont souvent gérés par des fournisseurs tiers, ce qui signifie qu\'ils prennent en charge les mises à jour, les correctifs de sécurité et la maintenance du logiciel. Cela permet aux entreprises de se concentrer sur leur activité principale plutôt que sur la gestion de leur infrastructure informatique.',
        target_audience_title: 'En resumé le nocode est pour quel public ?',
        target_audience_description: 'Le noCode est destiné à tous ceux qui souhaitent créer des applications et automatiser leurs processus sans avoir à écrire de code, qu\'ils soient des entrepreneurs, des professionnels de l\'informatique, des employés de bureau, des travailleurs indépendants ou des étudiants. Les outils de noCode sont conçus pour être faciles à utiliser et à comprendre, même pour les personnes sans connaissances techniques, ce qui les rend accessibles à un large public. Le noCode offre ainsi la possibilité à chacun de devenir créatif et de créer des solutions innovantes pour répondre à ses besoins spécifiques, sans avoir à dépendre d\'une équipe de développeurs.',
        custom_work_software_title: 'Votre logiciel de travail sur mesure',
        free_trial_description: 'Essai gratuit 15 jours - Aucune carte de crédit requise',
        get_started: 'J\'y vais',

        // contact
        contact_title: 'Contact',
        commercial_service: 'Service commercial',
        by_phone: 'Par téléphone',
        technical_support: 'Support technique',
        technical_support_description: 'Le Support technique est un service offert pour aider les clients à résoudre des problèmes techniques liés à un produit ou un service.',
        by_email: 'Par email',
        support_assistance: 'Support assistance',
        support_assistance_description: 'Le support assistance est un service destiné à aider les clients à obtenir des informations sur les produits ou services, ainsi qu\'à les accompagner dans la conception ou le développement de ces produits ou services.',
        open_ticket: 'Ouvrir un ticket',

        support_standard: 'Support Standard',
        support_standard_description: 'Nous mettons votre autonomie au centre de nos préoccupations',
        discover: 'Découvrir',
        support_premium: 'Support Premium',
        support_premium_description: 'Nous mettons tout en œuvre pour vous offrir une expérience optimale',
        support_business: 'Support Business',
        support_business_description: 'Nous nous engageons à faciliter vos déclaration et suivi d\'incident',
        support_enterprise: 'Support Entreprise',
        support_enterprise_description: 'Nous nous engageons à être toujours au rendez-vous de votre satisfaction',
        custom_work_software: 'Votre logiciel de travail sur mesure',
        free_trial_15_days: 'Essai gratuit 15 jours - Aucune carte de crédit requise',
        go: 'J\'y vais',

        // support
        how_can_we_help: 'Bonjour, comment pouvons-nous vous aider?',
        api_documentation: 'Documentation API',
        use_api_configure_services: 'Utilisez l\'API pour configurer les services.',
        video_tutorials: 'Tutoriel vidéo',
        train_become_autonomous_platform: 'Formez-vous et devenez autonome sur la plateforme.',
        support_ticket: 'Ticket de support',
        report_track_incident_consult_history: 'Déclarez et suivez un incident et consultez l\'historique.',
        frequently_asked_questions: 'Questions les plus fréquentes',
        how_does_platform_work: 'Comment fonctionne votre plateforme ?',
        platform_works_description: 'Notre plateforme est conçue pour être simple et intuitive à utiliser. Vous pouvez commencer par choisir un modèle prédéfini, puis personnaliser les éléments en fonction de vos besoins. Vous pouvez également ajouter des fonctionnalités en utilisant des blocs de construction pré-conçus ou en créant vos propres composants.',
        do_i_need_to_code_to_use_platform: 'Est-ce que je dois savoir coder pour utiliser votre plateforme ?',
        no_coding_needed_description: 'Non, vous n\'avez pas besoin de connaître la programmation pour utiliser notre plateforme noCode. Notre plateforme est conçue pour être accessible aux utilisateurs de tous niveaux de compétence technique. Si vous avez des questions ou si vous avez besoin d\'aide, notre équipe de support est là pour vous aider.',
        what_are_the_pricing_plans: 'Quels sont les tarifs de votre plateforme ?',
        pricing_plans_description: 'Nous offrons une variété de plans tarifaires pour répondre aux besoins de nos clients. Nos tarifs commencent à partir de 8 euros par mois/licence pour les petites entreprises et les particuliers, et varient en fonction du nombre de projets et des fonctionnalités que vous utilisez. Consultez notre rubrique Prix pour en savoir plus sur nos plans tarifaires et pour choisir celui qui convient le mieux à vos besoins.',
        how_can_i_contact_support_team: 'Comment puis-je contacter votre équipe de support si j\'ai des questions ?',
        contact_support_description: 'Si vous avez des questions sur le fonctionnement de notre plateforme, vous pouvez contacter notre équipe de support par téléphone, par email ou par chat en direct. Nous sommes là pour vous aider à tout moment et nous nous engageons à résoudre rapidement tous les problèmes que vous pourriez rencontrer.',
        how_can_i_integrate_third_party_services: 'Comment puis-je intégrer des services tiers à mon application ?',
        integration_with_third_party_services_description: 'Notre plateforme est conçue pour s\'intégrer facilement à une variété de services tiers. Nous offrons une gamme d\'intégrations pré-conçues, y compris avec des services populaires tels que Stripe, Zapier, Aircall et bien d\'autres. Si vous avez besoin d\'une intégration personnalisée, notre équipe de support peut vous aider à mettre en place une intégration sur mesure.',
        how_can_i_ensure_security_of_my_application: 'Comment puis-je assurer la sécurité de mon application ?',
        application_security_description: 'Notre plateforme offre une variété de fonctionnalités de sécurité pour protéger votre application et vos données. Nous utilisons des protocoles de sécurité avancés tels que HTTPS, SSL et OAuth pour protéger les informations sensibles. Nous effectuons également des sauvegardes régulières de vos données pour garantir leur sécurité en cas de catastrophe.',
        how_can_i_get_feature_updates_for_my_platform: 'Comment puis-je obtenir des mises à jour de fonctionnalités pour ma plateforme ?',
        feature_updates_description: 'Nous offrons régulièrement des mises à jour de fonctionnalités pour notre plateforme afin d\'améliorer constamment l\'expérience utilisateur et d\'ajouter de nouvelles fonctionnalités. Vous pouvez recevoir des notifications de mises à jour par email ou vous pouvez consulter notre site web pour voir les dernières mises à jour. Si vous avez des suggestions pour de nouvelles fonctionnalités, n\'hésitez pas à nous les transmettre.',

        // services
        getYourAccess: 'Obtenez dès à présent vos accès !',
        servicess: 'Services',

        //
        documentsTitle: 'Documents',
        documentsDescription: 'Service Document - Édition, stockage et personnalisation de vos documents',
        documentsDescriptionPlus: "" +
            " <p>Notre service Document révolutionne la façon dont vous gérez vos documents. Il ne s'agit pas seulement d'un outil d'édition et de stockage. C'est une plateforme conçue pour vous aider à créer des documents sur mesure grâce à l'utilisation de balises, et ce dans plusieurs formats de documents, tels que .docs, .pdf et .xlsx.</p>\n" +
            "  <h2>Édition et stockage des documents</h2>\n" +
            "  <p>Avec notre service document, éditez et stockez vos documents en toute simplicité. Que vous souhaitiez créer un nouveau document ou stocker un document existant, notre service est là pour vous aider.</p>\n" +
            "  <h2>Création de templates de documents</h2>\n" +
            "  <p>Notre service vous permet de créer des templates de documents. Que vous ayez besoin d'un modèle pour un contrat, une facture ou un rapport, nos outils d'édition de templates vous permettront de créer des documents professionnels avec facilité.</p>\n" +
            "  <h2>Utilisation de balises</h2>\n" +
            "  <p>En intégrant des balises dans vos templates, vous pouvez créer des documents à remplir avec les données prélevées. Que vous souhaitiez intégrer le nom d'un client, une date ou toute autre information, nos balises rendent la personnalisation des documents simple et efficace.</p>\n" +
            "  <h2>Support de plusieurs formats de documents</h2>\n" +
            "  <p>Notre service Document supporte plusieurs formats de documents, y compris .docs, .pdf et .xlsx. Quel que soit le type de document que vous souhaitez créer ou stocker, notre service peut répondre à vos besoins.</p>\n" +
            "  <h2>Le service document</h2>\n" +
            "  <p>Le service Document est bien plus qu'un simple outil d'édition et de stockage de documents. C'est votre partenaire pour créer des documents sur mesure, personnalisés et professionnels. Découvrez une nouvelle manière de gérer vos documents avec notre service.</p>\n",

        //
        smsTitle: 'SMS',
        smsDescription: 'Service SMS - Communication rapide et efficace',
        smsDescriptionPlus: "" +
            "<p>Notre service SMS transforme la façon dont vous gérez vos communications par SMS. Au-delà de l'envoi de SMS, c'est une plateforme conçue pour automatiser vos envois, créer des templates personnalisés grâce à l'utilisation de balises, et consulter les historiques d'envoi.</p>\n" +
            "  <h2>Envoyer des SMS via des fournisseurs externes</h2>\n" +
            "  <p>Avec notre service SMS, vous pouvez facilement envoyer des SMS via des fournisseurs externes, comme AllMySms. Que vous souhaitiez envoyer un message à un client ou à une équipe entière, notre service est là pour vous aider.</p>\n" +
            "  <h2>Création de templates de SMS</h2>\n" +
            "  <p>Notre service vous permet de créer des templates de SMS. Que vous ayez besoin d'un message pour un rappel de rendez-vous ou une annonce spéciale, nos outils de création de templates rendront la rédaction de vos messages plus facile et plus rapide.</p>\n" +
            "  <h2>Utilisation de balises</h2>\n" +
            "  <p>En intégrant des balises dans vos templates, vous pouvez personnaliser vos SMS. Que vous souhaitiez insérer le nom d'un client, une date ou toute autre information, nos balises rendent la personnalisation des SMS simple et efficace.</p>\n" +
            "  <h2>Automatisation des envois</h2>\n" +
            "  <p>Avec notre service SMS, vous pouvez automatiser vos envois. Que vous souhaitiez envoyer des rappels réguliers ou des messages en fonction de critères spécifiques, nous facilitons l'automatisation pour vous faire gagner du temps.</p>\n" +
            "  <h2>Consultation des historiques d'envoi</h2>\n" +
            "  <p>Grâce à notre service SMS, accédez facilement à vos historiques d'envoi. Ainsi, vous pouvez toujours suivre vos communications et maintenir un registre de tous vos messages.</p>\n" +
            "  <h2>Le Service SMS</h2>\n" +
            "  <p>Le service SMS est bien plus qu'un simple outil d'envoi de messages. C'est votre partenaire pour des communications rapides, efficaces et personnalisées. Découvrez une nouvelle façon de communiquer grâce à notre service.</p>\n",


        //
        calendarTitle: 'Calendrier',
        calendarDescription: 'Un calendrier plein de surprises',
        calendarDescriptionPlus: "" +
            "<p>Notre service Calendrier vous permet de gérer facilement vos événements et rendez-vous. Que ce soit pour des réunions, des rendez-vous clients ou des événements d'équipe, vous disposez d'un outil centralisé pour organiser et planifier vos activités.</p>\n" +
            "  <h2>Fonctionnalités avancées de planification</h2>\n" +
            "  <p>Notre service calendrier offre une multitude de fonctionnalités avancées pour optimiser vos planifications. Vous pouvez bénéficier de vues personnalisables, de rappels et de notifications, de la gestion des disponibilités, ainsi que de la possibilité de partager des calendriers avec d'autres utilisateurs.</p>\n" +
            "  <h2>Intégration avec le service MAP</h2>\n" +
            "  <p>Le service calendrier est étroitement lié au service MAP, ce qui vous permet d'afficher les distances entre les rendez-vous et d'optimiser vos déplacements. Vous pouvez visualiser les itinéraires entre les différents points de rendez-vous, ce qui vous aide à prendre des décisions éclairées et à maximiser votre efficacité.</p>\n" +
            "  <h2>Synchronisation avec d'autres agendas</h2>\n" +
            "  <p>Notre service calendrier offre également la possibilité de synchroniser vos événements avec d'autres agendas, tels que Google Agenda. Cela vous permet de centraliser vos activités et de faciliter la gestion de votre emploi du temps.</p>\n" +
            "  <h2>Le service calendrier</h2>\n" +
            "  <p>Le service calendrier est bien plus qu'un simple outil de gestion du temps. C'est votre partenaire pour une planification avancée, une gestion des rendez-vous optimisée et une synchronisation pratique avec d'autres agendas. Découvrez comment notre service Calendrier peut améliorer votre gestion du temps et de vos activités.</p>\n",


        //
        accountingTitle: 'Comptabilité',
        accountingDescription: 'Service comptabilité - gestion facilitée des devis, factures et avoirs',
        accountingDescriptionPlus: "" +
            "<p>Notre service comptabilité vous offre une solution complète pour gérer efficacement vos devis, factures et avoirs. Avec notre service, vous pouvez éditer facilement des documents professionnels, choisir parmi une sélection de templates visuels ou créer vos propres modèles personnalisés. Nous mettons à votre disposition une application et des widgets pour faciliter l'accès aux informations pertinentes. De plus, notre service vous permet de connecter vos moyens de paiement tels que Stripe pour simplifier la gestion des paiements.</p>\n" +
            "  <h2>Édition de devis, factures et avoirs</h2>\n" +
            "  <p>Avec notre service comptabilité, vous pouvez éditer vos devis, factures et avoirs de manière rapide et efficace. Que vous ayez besoin de créer un devis pour un client, de générer une facture pour une vente ou de produire un avoir pour un remboursement, notre service facilite toutes ces opérations.</p>\n" +
            "  <h2>Templates visuels ou création de modèles personnalisés</h2>\n" +
            "  <p>Notre service vous offre la flexibilité de choisir parmi une sélection de templates visuels pour vos devis, factures et avoirs. Vous pouvez également créer vos propres modèles personnalisés pour répondre aux besoins spécifiques de votre entreprise. Avec notre éditeur intuitif, la création de modèles est simple et vous permet de personnaliser les informations et l'apparence de vos documents.</p>\n" +
            "  <h2>Application et widgets pour une facilité d'accès</h2>\n" +
            "  <p>Nous mettons à votre disposition une application dédiée et des widgets pour un accès facile et rapide aux informations de comptabilité. Que vous soyez au bureau, en déplacement ou sur votre appareil mobile, vous pouvez accéder à vos devis, factures, avoirs et informations de paiement en un seul endroit.</p>\n" +
            "  <h2>Connexion de moyens de paiement tels que Stripe</h2>\n" +
            "  <p>Notre service comptabilité vous permet de connecter vos moyens de paiement, tels que Stripe. Cela simplifie la gestion des paiements, vous permettant d'envoyer des factures électroniques avec des liens de paiement intégrés et de suivre les transactions de paiement en temps réel.</p>\n" +
            "  <h2>Le service comptabilité</h2>\n" +
            "  <p>Le service comptabilité est bien plus qu'un simple outil de gestion des documents financiers. C'est votre partenaire pour une gestion facilitée des devis, factures et avoirs, avec une application et des widgets pour un accès facile aux informations essentielles. Découvrez comment notre service Comptabilité peut simplifier votre comptabilité et optimiser votre processus financier.</p>\n",

        //
        formTitle: 'Formulaire',
        formDescription: 'Service formulaire - Facilitateur d\'expériences et de connexions',
        formDescriptionPlus: "" +
            " <p>Avec notre service formulaire, repensez la façon dont vous gérez vos procédures et l'expérience client. Plus qu'un simple créateur de formulaires, c'est une plateforme innovante conçue pour vous aider à simplifier les liaisons entre les données et leur architecture.</p>\n" +
            "  <h2>Création de formulaires faciles à intégrer</h2>\n" +
            "  <p>Notre service vous permet de créer des formulaires à intégrer facilement dans vos procédures. Que vous souhaitiez recueillir des informations client ou structurer un processus interne, notre système de formulaire facilite la création et l'intégration de formulaires.</p>\n" +
            "  <h2>Divers types de format</h2>\n" +
            "  <p>Nous proposons plusieurs types de format pour les formulaires, vous offrant ainsi la flexibilité nécessaire pour répondre à vos besoins spécifiques. Qu'il s'agisse de textes, de chiffres, de dates ou d'options à choix multiples, notre service prend en charge une variété de formats.</p>\n" +
            "  <h2>Gestion des fonctions et des formules</h2>\n" +
            "  <p>Les formulaires ne sont pas seulement un moyen de recueillir des informations. Ils peuvent aussi être une puissante plateforme de calcul et de logique. Avec notre service, vous pouvez gérer des fonctions et des formules dans vos formulaires, améliorant ainsi leur fonctionnalité et leur utilité.</p>\n" +
            "  <h2>Partage de templates sur notre espace partagé</h2>\n" +
            "  <p>Vous avez créé un formulaire impressionnant ? Partagez-le avec d'autres sur notre espace de templates partagés. Notre service formulaire favorise le partage des meilleures pratiques et l'inspiration collaborative.</p>\n" +
            "  <h2>Le service formulaire</h2>\n" +
            "  <p>Le service formulaire est bien plus qu'un simple créateur de formulaires. C'est votre partenaire pour faciliter l'expérience client et la gestion des données. Expérimentez une gestion de formulaire plus intelligente avec notre service.</p>\n",

        //
        productsTitle: 'Produits',
        productsDescription: 'Gestion de vos catalogues produits',
        productsDescriptionPlus: "  " +
            "<p>Avec notre service produits, repensez la façon dont vous gérez vos catalogues de produits. Il ne s'agit pas seulement d'un outil de gestion de produits, c'est une plateforme complète conçue pour vous aider à maximiser l'efficacité et la flexibilité de votre catalogue.</p>\n" +
            " <h2>Gestion des catégories de produits</h2>\n" +
            " <p>Nous savons que la clarté et l'organisation sont essentielles lorsqu'il s'agit de gérer un catalogue de produits. C'est pourquoi notre service vous permet de structurer vos produits en différentes catégories, facilitant ainsi la navigation et la découverte pour vos clients.</p>\n" +
            " <h2>Paramétrage avancé des produits</h2>\n" +
            " <p>Notre service offre des options de paramétrage avancé pour chaque produit. Qu'il s'agisse de gérer un barème de prix, d'appliquer des données dynamiques, de configurer les taxes, d'ajouter des photos ou même d'associer un document PDF, notre système vous offre la flexibilité nécessaire pour configurer vos produits de manière précise et détaillée.</p>\n" +
            " <h2>Le service produits</h2>\n" +
            " <p>Le service produits est bien plus qu'un simple outil de gestion de catalogue. C'est votre partenaire pour rendre la gestion de vos produits plus simple, plus efficace et plus flexible. Expérimentez la gestion de produits à un niveau supérieur avec notre service.</p>\n",


        //
        emailDescription: 'Service email - Communication simplifiée et optimisée',
        emailDescriptionPlus:
            '  <p>Avec notre service email, réinventez la façon dont vous gérez votre communication par email. Il ne s\'agit pas simplement d\'un outil d\'envoi et de réception d\'emails. C\'est une plateforme conçue pour maximiser votre productivité et améliorer l\'efficacité de vos échanges.</p>\n' +
            '  <h2>Envoyez et recevez des emails avec facilité</h2>\n' +
            '  <p>Notre service permet l\'envoi et la réception d\'emails en toute simplicité. Que vous ayez besoin d\'envoyer une information rapide ou de gérer une conversation complexe, notre service email est là pour vous aider.</p>\n' +
            '  <h2>Créez des templates sur mesure</h2>\n' +
            '  <p>Bénéficiez de la flexibilité d\'un éditeur de texte et d\'un éditeur drag and drop pour concevoir des templates d\'emails personnalisés. Que vous souhaitiez créer un bulletin d\'informations attrayant ou un email de suivi professionnel, nos outils de création de templates vous permettront de le faire avec facilité.</p>\n' +
            '  <h2>Automatisation des envois</h2>\n' +
            '  <p>Dites adieu à la tâche laborieuse d\'envoi manuel d\'emails. Avec notre service email, vous pouvez automatiser vos envois. Qu\'il s\'agisse de newsletters régulières ou de réponses automatisées, nous facilitons l\'automatisation pour que vous puissiez consacrer plus de temps à ce qui compte vraiment.</p>\n' +
            '  <h2>Consultez les historiques dans tous les contextes</h2>\n' +
            '  <p>L\'historique de vos communications est essentiel pour maintenir la continuité et l\'efficacité de vos échanges. Avec notre service email, accédez facilement à vos historiques d\'emails dans tous les contextes, garantissant une trace de chaque conversation.</p>\n' +
            '  <h2>Le service email</h2>\n' +
            '  <p>Le service email est bien plus qu\'un simple outil de messagerie. C\'est votre partenaire de communication, conçu pour rendre vos échanges plus simples, plus efficaces et plus significatifs. Expérimentez l\'évolution de la communication par email avec notre service.</p>\n',

        //
        leadsDescription:'Service leads - Gestion optimisée des prospects',
        leadsDescriptionPlus:
            '  <p>Notre service leads transforme la manière dont vous gérez vos prospects. Au-delà d\'un simple outil de gestion, notre système est une solution complète conçue pour vous aider à maximiser vos efforts de conversion.</p>\n' +
            '  <h2>Espaces aménagés pour la gestion des leads</h2>\n' +
            '  <p>Nous savons que chaque interaction avec un prospect est une opportunité à ne pas manquer. C\'est pourquoi notre service leads vous permet de créer des espaces aménagés spécifiquement pour la gestion des leads. Ces espaces sont conçus pour vous aider à suivre chaque prospect de manière efficace, à gérer les interactions, à planifier les suivis et à finalement convertir les prospects en clients.</p>\n' +
            '  <h2>Gestion de multiples espaces de leads</h2>\n' +
            '  <p>Que vous dirigiez une entreprise à multiples facettes ou que vous travailliez avec différentes campagnes de marketing, la possibilité de gérer plusieurs espaces de leads est essentielle. Notre système vous offre cette flexibilité. Vous pouvez configurer et gérer autant d\'espaces de leads que nécessaire, chacun étant adapté à une activité ou une campagne spécifique. Cela vous permet de suivre et de gérer vos prospects de manière organisée et ciblée.</p>\n' +
            '  <h2>Expérimentez l\'évolution de la gestion des prospects</h2>\n' +
            '  <p>Avec notre service Leads, la gestion des prospects n\'est plus une tâche fastidieuse. C\'est un processus optimisé qui vous aide à atteindre vos objectifs de conversion plus rapidement et plus efficacement. Expérimentez l\'évolution de la gestion des prospects avec notre service.</p>',

        //
        esignDescription:'Service signature électronique - Simplifiez vos processus de signature',
        esignDescriptionPlus:'' +
            ' <p>Avec notre service signature électronique, transformez la façon dont vous gérez vos processus de signature. Au-delà d\'un simple outil d\'envoi de signatures, c\'est une solution complète conçue pour vous aider à optimiser et à sécuriser vos transactions.</p>\n' +
            '  <h2>Envoi de signatures par email ou SMS</h2>\n' +
            '  <p>Notre service permet l\'envoi de demandes de signature par email ou SMS. Quel que soit le canal que vos clients préfèrent, notre système offre la flexibilité nécessaire pour atteindre vos destinataires de manière efficace et pratique.</p>\n' +
            '  <h2>Suivi des événements de signature</h2>\n' +
            '  <p>Une fois que vous avez envoyé une demande de signature, notre service vous donne un aperçu des événements associés à cette demande. Vous pouvez suivre quels documents ont été signés et lesquels ont été refusés, vous donnant ainsi une visibilité complète sur l\'état de vos transactions.</p>\n' +
            '  <h2>Intégration avec diverses applications</h2>\n' +
            '  <p>Selon le plugin que vous utilisez, notre service signature électronique peut être intégré avec diverses applications, offrant ainsi un niveau supplémentaire de flexibilité et d\'efficacité dans la gestion de vos processus de signature.</p>\n' +
            '  <h2>Le service signature électronique</h2>\n' +
            '  <p>Le service signature électronique est plus qu\'un simple outil d\'envoi de signature. C\'est votre partenaire pour simplifier et sécuriser vos processus de signature. Découvrez comment notre service peut vous aider à optimiser vos transactions.</p>\n',

        //
        mapDescription:'Service MAP - Gestion de localisation et planification optimisée',
        mapDescriptionPlus:"" +
            " <p>Avec notre service MAP, réinventez la manière dont vous gérez la localisation et la planification de vos ressources. Au-delà d'un simple outil de visualisation sur carte, c'est une solution robuste qui facilite la gestion des objets ou des enregistrements géolocalisés.</p>\n" +
            "  <h2>Gestion des objets ou enregistrements sur carte</h2>\n" +
            "  <p>Notre service vous permet de gérer facilement les objets ou enregistrements sur une vue carte. Que vous ayez besoin de suivre un colis ou de gérer une flotte de véhicules, notre service MAP rend la localisation plus facile et plus intuitive.</p>\n" +
            "  <h2>Calcul de distances</h2>\n" +
            "  <p>Besoin de savoir la distance entre deux points sur la carte ? Notre service MAP peut calculer cela pour vous. Que vous planifiiez une tournée de livraison ou que vous traciez un itinéraire pour un road trip, notre système de calcul de distance facilite la planification.</p>\n" +
            "  <h2>Amélioration de la planification</h2>\n" +
            "  <p>En intégrant le service MAP à votre calendrier, vous pouvez améliorer vos planifications. Que vous coordonniez les tournées d'une équipe sur le terrain ou que vous planifiiez votre propre itinéraire, notre service MAP peut aider à optimiser vos trajets et à maximiser votre efficacité.</p>\n" +
            "  <h2>Le service MAP</h2>\n" +
            "  <p>Le service MAP est bien plus qu'un simple outil de cartographie. C'est votre partenaire pour une gestion de localisation plus intelligente et une planification optimisée. Découvrez comment notre service peut améliorer vos opérations de localisation et de planification.</p>\n",

        //
        telephonyDescription:'Service téléphonie IP - Connectivité et accès aux appels simplifiés',
        telephonyDescriptionPlus:"" +
            " <p>Avec notre service de téléphonie IP, transformez votre manière de gérer vos communications téléphoniques. Il ne s'agit pas simplement d'un service de téléphonie Internet. C'est une plateforme intégrée conçue pour connecter votre fournisseur de téléphonie IP et améliorer votre accessibilité.</p>\n" +
            "  <h2>Connexion avec votre fournisseur de téléphonie IP</h2>\n" +
            "  <p>Notre service vous permet de connecter facilement votre fournisseur de téléphonie IP via des plugins. Cela facilite la gestion de vos communications téléphoniques, qu'il s'agisse d'un appel important avec un client ou d'une conférence téléphonique avec votre équipe.</p>\n" +
            "  <h2>Historiques d'appels accessibles partout</h2>\n" +
            "  <p>Avec notre service, vous pouvez accéder à vos historiques d'appels où que vous soyez. Que vous travailliez à distance ou que vous soyez en déplacement, vous pouvez consulter votre historique d'appels, garantissant ainsi une continuité dans vos communications.</p>\n" +
            "  <h2>Click to call</h2>\n" +
            "  <p>Simplifiez la façon dont vous passez des appels avec notre fonction click to call. Au lieu de composer manuellement un numéro, vous pouvez simplement cliquer sur un contact pour l'appeler. C'est un gain de temps et un moyen de minimiser les erreurs de numérotation.</p>\n" +
            "  <h2>Téléchargement des enregistrements audio des appels</h2>\n" +
            "  <p>Notre service de téléphonie IP permet également le téléchargement des enregistrements audio des appels. Que vous souhaitiez réécouter une conversation importante ou conserver une trace d'un appel, vous pouvez facilement télécharger l'enregistrement audio.</p>\n" +
            "  <h2>Le service téléphonie IP</h2>\n" +
            "  <p>Le service de téléphonie IP est plus qu'un simple service de téléphonie Internet. C'est votre allié pour une gestion des appels plus fluide et plus accessible. Découvrez l'évolution des communications téléphoniques avec notre service.</p>\n",
        //
        contactCompanyDescription:'Service contact et compagnie - Centralisation des informations et optimisation des vues',
        contactCompanyDescriptionPlus:"" +
            "<p>Avec notre service contact et compagnie, révolutionnez la manière dont vous gérez et visualisez vos données. Ce service n'est pas simplement un outil de gestion des contacts ou des entreprises. C'est une plateforme conçue pour centraliser les informations autour d'une entité et optimiser les vues à 360 degrés.</p>\n" +
            "  <h2>Focus sur les procédures</h2>\n" +
            "  <p>Notre service vous permet d'avoir un focus sur des procédures telles qu'un contact ou une entreprise. Cela vous permet de centraliser toutes les informations pertinentes et d'avoir une vue complète et à jour de chaque entité.</p>\n" +
            "  <h2>Vue à 360 degrés</h2>\n" +
            "  <p>Grâce à notre service, vous pouvez bénéficier d'une vue à 360 degrés pour chaque entité. Que vous ayez besoin de visualiser l'ensemble des interactions avec un contact ou de comprendre la structure complète d'une entreprise, notre vue à 360 degrés vous offre une compréhension complète et détaillée.</p>\n" +
            "  <h2>Gestion des zones</h2>\n" +
            "  <p>Le service contact et compagnie vous permet également de séparer votre focus en zones, ce qui est particulièrement utile pour gérer plusieurs entreprises. Cela vous permet de gérer et d'organiser efficacement vos données, en veillant à ce que chaque entreprise ou contact reste clairement distinct et facilement accessible.</p>\n" +
            "  <h2>Le service contact et compagnie</h2>\n" +
            "  <p>Le service contact et compagnie est bien plus qu'un simple outil de gestion des contacts ou des entreprises. C'est votre partenaire pour une meilleure organisation et visualisation de vos données. Découvrez une nouvelle manière de gérer vos informations avec notre service.</p>\n",


// pricing
        EnsembleProductivite: "Ensemble, augmentons votre productivité",
        CommencezGratuitement: "Commencez gratuitement",
        PeriodeEssai: "Période d'essai de 15 jours - Aucune carte de crédit requise",
        annual: 'Annuel',
        monthly: 'Mensuel',
        productivity: 'Ensemble, faisons grimper votre productivité',
        freeStart: 'Commencez gratuitement',
        trialPeriod: 'Période d\'essai de 15 jours - Aucune carte de crédit requise',
        solo: 'Gratuit',
        price: '0€',
        freeLifetime: 'Gratuit à vie',
        individualPlanIncludes: 'Le forfait Gratuit comprend :',
        collaborativeWorkspace: 'Espace de travail collaboratif',
        freeAPIIntegration: 'Intégration d\'API\'s gratuite',
        limitedTimeHistory: 'Historique limité dans le temps (7 jours)',
        powerfulFormulas: 'Formules et automatisations puissantes',

        basic: 'Basic',
        perUserMonth: 'utilisateur / mois',
        includesIndividualPlus: 'Inclut le forfait Solo, plus :',
        priorityCustomerService: 'Service client prioritaire',
        fileStorage: '5 Go de stockage de fichiers',
        limitedTimeHistory30: 'Historique limité dans le temps (30 jours)',
        unlimitedItems: 'Éléments illimités',
        basicPermissionManagement: 'Gestion des permissions basique',
        recommended: 'Recommandé',

        standard: 'Plus',
        includesBasicPlus: 'Inclut le forfait Gratuit, plus :',
        calendarView: 'Vue de calendrier',
        unlimitedAutomations: 'Automatisations illimitées',
        limitedTimeHistory7: 'Historique limité dans le temps (90 jours)',
        VIPServiceManagement: 'Service management VIP',
        permissionManagementAllLevels: 'Gestion des permissions à tous les niveaux',

        pro: 'Pro',
        includesStandardPlus: 'Inclut le forfait Standard, plus :',
        documentVisualizationSAS: 'SAS de visualisation des documents (via un QR code)',
        fileStorage15: '15 Go de stockage de fichiers',
        premiumSupport: 'Assistance premium',
        createDashboard: 'Création un tableau de bord qui combine jusqu\'à 50 tableaux',
        enterprise: 'Entreprise',
        custom: 'Sur mesure',
        includesProPlus: 'Inclut le forfait Pro, plus :',
        unlimitedPlugins: 'Plugins illimités',
        unlimitedFileStorage: 'Stockage de fichiers illimité',
        unlimitedSetupIntegration: 'Intégrations illimitées',
        unlimitedTimeHistory: 'Historique illimité dans le temps',
        onDemandDevelopment: 'Développement à la demande',
        note: 'À noter',
        someAppsNote: 'Certaines applications ou plugins proposent des forfaits indépendants. Pour plus d\'informations sur les tarifs de ces applications, cliquez',
        here: 'ici',
        customWorkSoftware: 'Votre logiciel de travail sur mesure',
        freeTrialNote: 'Essai gratuit 15 jours - Aucune carte de crédit requise',
        goThere: "J'y vais",

        // views

        advantages_of_kanban: 'Les avantages de la gestion sur une vue Kanban',
        task_visualization: 'La visualisation des tâches',
        task_visualization_description: 'La vue Kanban permet de visualiser facilement toutes les tâches d\'un projet en un coup d\'œil. Les tâches sont représentées sous forme de cartes que l\'on peut déplacer d\'une colonne à l\'autre pour indiquer leur état d\'avancement. Cette visualisation claire et concise permet de mieux comprendre le projet dans son ensemble et de mieux coordonner les efforts de l\'équipe.',
        productivity_optimization: 'L\'optimisation de la productivité',
        productivity_optimization_description: 'La vue Kanban permet d\'optimiser la productivité en identifiant rapidement les tâches qui sont bloquées ou qui nécessitent une attention particulière. Les membres de l\'équipe peuvent ainsi réagir rapidement pour résoudre les problèmes et maintenir un rythme de travail soutenu. De plus, en réduisant le temps nécessaire pour suivre l\'avancement des tâches, les membres de l\'équipe peuvent se concentrer davantage sur la réalisation des tâches elles-mêmes.',
        theFlexibility: 'La flexibilité',
        flexibility_description: 'La vue Kanban permet de changer facilement l\'ordre des tâches en fonction de leur priorité ou de leur urgence. Les cartes peuvent être facilement déplacées d\'une colonne à l\'autre, ce qui permet de s\'adapter rapidement aux changements de situation. Cette flexibilité est particulièrement importante dans un environnement de travail dynamique où les priorités peuvent changer rapidement.',
        drag_and_drop: 'Glisser et déposer',
        customizable_card: 'Carte personnalisable',
        datatable: 'Table de données',
        advantages_datatable: 'Voici les avantages de la gestion via une vue DataTable :',
        advantage_1: 'Tri et filtrage efficaces :',
        advantage_1_desc: 'La vue DataTable permet de trier et de filtrer les données de manière efficace, ce qui permet aux utilisateurs de trouver rapidement les informations dont ils ont besoin. De plus, la vue DataTable permet d\'appliquer plusieurs filtres et de les combiner pour obtenir des résultats encore plus précis.',
        advantage_2: 'Pagination des données :',
        advantage_2_desc: 'La vue DataTable peut également paginer les données, ce qui permet de diviser les résultats en plusieurs pages et de ne charger que les données nécessaires à chaque fois. Cela réduit le temps de chargement et améliore les performances de l\'application.',
        advantage_3: 'Personnalisation de l\'apparence :',
        advantage_3_desc: 'La vue DataTable permet de personnaliser facilement l\'apparence des données, ce qui peut être très utile pour les entreprises souhaitant adapter leur interface utilisateur à leur marque ou à leur style de travail spécifique. Les utilisateurs peuvent personnaliser la couleur, la police, la taille et la disposition des données en fonction de leurs préférences.',
        feature_1: 'Glisser et déposer',
        feature_2: 'Carte personnalisable',
        advantages_calendar: 'Voici les avantages de la gestion via une vue Calendrier :',
        advantage_cal1: 'Visualisation globale :',
        advantage_cal1_desc: 'La vue calendrier permet une visualisation globale des tâches, rendez-vous ou événements à venir. Cela permet aux utilisateurs de planifier leur temps plus efficacement en ayant une vue d\'ensemble de ce qui doit être accompli.',
        advantage_cal2: 'Planification facile :',
        advantage_cal2_desc: 'Avec la vue calendrier, les utilisateurs peuvent facilement planifier des tâches, rendez-vous ou événements en les faisant glisser-déposer sur la date et l\'heure appropriées. Cela permet de gagner du temps et d\'augmenter la productivité.',
        advantage_cal3: 'Partage et collaboration :',
        advantage_cal3_desc: 'La vue calendrier peut également permettre le partage de plannings ou d\'agendas avec d\'autres membres de l\'équipe ou des partenaires externes. Cela facilite la collaboration et la coordination des tâches et des projets.',
        imageURL: 'https://www.objectif-crm.com/assets/images/vueCalendrier.PNG',
        be_ready: 'SOYEZ PRÊT À EN AVOIR PLUS',
        get_your_access: 'Obtenez dès à présent vos accès !',
        start_trial: 'Démarrer l\'essai',
        request_callback: 'Demande de rappel',

        // integration
        ACtitle: 'La téléphonie d\'entreprise connectée à vos outils',
        integrationBenefits: 'Intégrer Aircall peut avoir de nombreux avantages pour votre entreprise. Voici quelques raisons pour lesquelles vous devriez envisager d\'intégrer Aircall :',
        customerExperience: '<strong>Amélioration de l\'expérience client :</strong> En intégrant Aircall, vous pouvez offrir une expérience client plus personnalisée et plus efficace. Lorsqu\'un client appelle, vous pouvez facilement accéder à son historique de commandes, ses préférences et ses interactions antérieures avec votre entreprise, ce qui vous permet de fournir un service plus adapté à ses besoins.',
        taskAutomation: '<strong>Automatisation des tâches :</strong> L\'intégration d\'Aircall peut vous aider à automatiser certaines tâches, telles que la création de nouveaux contacts et la mise à jour des dossiers des clients. Cela peut vous faire gagner du temps et réduire les erreurs de saisie de données.',
        performanceTracking: '<strong>Suivi des performances :</strong> En intégrant Aircall, vous pouvez suivre les performances de votre équipe de vente et de service clientèle en temps réel. Vous pouvez suivre des métriques clés telles que le nombre d\'appels, la durée des appels, le taux de réponse et le taux de conversion pour améliorer continuellement les performances de votre équipe.',
        enhancedCollaboration: '<strong>Collaboration améliorée :</strong> L\'intégration d\'Aircall peut faciliter la collaboration entre les membres de votre équipe en centralisant toutes les informations client en un seul endroit. Les membres de l\'équipe peuvent facilement accéder aux informations client mises à jour et communiquer entre eux pour offrir une expérience client cohérente.',
        summary: 'En somme, l\'intégration d\'Aircall peut aider votre entreprise à offrir une expérience client améliorée, automatiser les tâches, suivre les performances et améliorer la collaboration entre les membres de l\'équipe.',

        RHtitle:'Atteignez et engagez vos clients comme jamais auparavant',
        integrationBenefits2: 'Intégrer Ringover peut offrir plusieurs avantages pour votre entreprise, notamment :',
        costReduction: '<strong>Réduction des coûts :</strong> En intégrant Ringover, vous pouvez réduire les coûts liés aux appels téléphoniques. Ringover offre des tarifs compétitifs pour les appels internationaux, ce qui peut être avantageux si vous avez des clients dans différents pays. De plus, l\'intégration peut réduire les coûts liés aux déplacements en offrant des fonctionnalités de conférence téléphonique et de visioconférence.',
        customerPersonalization: '<strong>Personnalisation de l\'expérience client :</strong> En intégrant Ringover, vous pouvez personnaliser l\'expérience client en fonction des préférences de chaque client. Vous pouvez, par exemple, identifier les clients VIP et leur offrir un service plus personnalisé ou encore utiliser des scripts d\'appel pour répondre aux besoins spécifiques de chaque client.',
        productivityImprovement: '<strong>Amélioration de la productivité :</strong> L\'intégration de Ringover peut améliorer la productivité de votre équipe en leur offrant un accès facile et rapide à toutes les informations clients. Vos équipes peuvent ainsi passer moins de temps à chercher des informations et plus de temps à se concentrer sur les tâches à forte valeur ajoutée, telles que la vente et la résolution de problèmes.',
        summary2: 'En somme, l\'intégration de Ringover peut vous aider à améliorer l\'expérience client, automatiser les tâches, suivre les performances et améliorer la collaboration entre les membres de votre équipe. De plus, elle peut réduire les coûts liés aux appels téléphoniques, personnaliser l\'expérience client et améliorer la productivité de votre équipe. En fin de compte, l\'intégration de Ringover peut vous aider à offrir une expérience client exceptionnelle et à améliorer l\'efficacité de votre entreprise.',

        MAPtitle:'Tout sur la carte',
        integrationBenefits3: 'Intégrer Google Maps peut offrir plusieurs avantages pour votre entreprise, notamment :',
        customerLocalization: '<strong>Localisation des clients :</strong> En intégrant Google Maps, vous pouvez facilement visualiser l\'emplacement de vos clients et de vos prospects sur une carte. Cela peut vous aider à planifier des visites en personne ou à prendre des décisions plus éclairées sur la meilleure façon de gérer les interactions avec vos clients.',
        travelOptimization: '<strong>Optimisation des déplacements :</strong> L\'intégration de Google Maps peut également vous aider à optimiser les déplacements de vos équipes de vente ou de service clientèle. Vous pouvez utiliser les itinéraires générés par Google Maps pour planifier les visites de vos représentants en fonction de leur emplacement et de celui de vos clients, ce qui peut réduire les temps de déplacement et améliorer la productivité de votre équipe.',
        dataAccuracy: '<strong>Amélioration de la précision des données :</strong> En intégrant Google Maps, vous pouvez améliorer la précision des données client en ajoutant des informations de localisation. Cela peut aider à mieux segmenter votre base de clients et à personnaliser les interactions en fonction de leur emplacement géographique.',
        dataVisualization: '<strong>Visualisation des données :</strong> L\'intégration de Google Maps peut également vous aider à visualiser vos données clients sur une carte. Cela peut vous permettre de mieux comprendre les tendances géographiques de votre entreprise, comme les zones à forte concentration de clients ou les zones où vous avez besoin de développer votre présence.',
        customerExperience3: '<strong>Amélioration de l\'expérience client :</strong> En intégrant Google Maps, vous pouvez offrir une expérience client améliorée en fournissant des informations géographiques pertinentes. Vous pouvez, par exemple, fournir des indications routières à vos clients lors de la planification de rendez-vous ou encore leur offrir des recommandations de points d\'intérêt à proximité de leur emplacement.',
        summary3: 'En somme, l\'intégration de Google Maps peut vous aider à localiser les clients, optimiser les déplacements, améliorer la précision des données, visualiser les données et offrir une expérience client améliorée. Cette intégration peut ainsi aider votre entreprise à mieux comprendre les tendances géographiques, à optimiser les interactions avec les clients et à offrir une expérience client plus personnalisée et pertinente.',

        SPtitle:'Une infrastructure financière adaptée à Internet',
        integrationBenefits4: 'Intégrer Stripe peut offrir plusieurs avantages pour votre entreprise, notamment :',
        paymentFacilitation: '<strong>Facilitation des paiements :</strong> En intégrant Stripe, vous pouvez facilement accepter les paiements de vos clients en ligne. Cela peut faciliter les transactions et améliorer l\'expérience client, en permettant par exemple aux clients de payer directement depuis leur facture ou leur compte client.',
        paymentAutomation: '<strong>Automatisation des processus de paiement :</strong> L\'intégration de Stripe peut également vous aider à automatiser les processus de paiement. Vous pouvez par exemple mettre en place des rappels automatiques pour les paiements en retard ou automatiser la facturation récurrente.',
        paymentSecurity: '<strong>Sécurité des paiements :</strong> Stripe est connu pour sa sécurité de paiement de haut niveau. En intégrant Stripe, vous pouvez rassurer vos clients en leur offrant une méthode de paiement sécurisée.',
        paymentTracking: '<strong>Suivi des paiements :</strong> L\'intégration de Stripe peut également vous aider à suivre les paiements et à visualiser les données de vente en temps réel. Cela peut vous permettre de mieux comprendre les tendances de vente et d\'adapter votre stratégie en conséquence.',
        toolIntegration: '<strong>Intégration avec d\'autres outils :</strong> Stripe peut s\'intégrer facilement avec d\'autres outils et applications, tels que des plateformes de commerce électronique ou des outils de marketing. En intégrant Stripe, vous pouvez ainsi centraliser les données de vente et simplifier les processus de paiement à travers différentes plateformes.',
        summary4: 'En somme, l\'intégration de Stripe sur l\'outil peut faciliter les paiements, automatiser les processus de paiement, assurer la sécurité des paiements, suivre les paiements en temps réel et intégrer Stripe avec d\'autres outils. Cette intégration peut ainsi aider votre entreprise à simplifier les transactions financières, améliorer l\'expérience client et optimiser les processus de vente.',

        GDtitle:'Stockage des documents',
        integrationBenefits5: 'Intégrer Google Drive peut offrir plusieurs avantages pour votre entreprise, notamment :',
        centralizedStorage: '<strong>Stockage centralisé :</strong> En intégrant Google Drive, vous pouvez centraliser tous les documents et fichiers liés à vos clients ou à vos projets au même endroit. Cela peut faciliter la collaboration et la gestion de projets pour votre équipe.',
        quickAccess: '<strong>Accès rapide aux documents :</strong> L\'intégration de Google Drive peut également vous permettre d\'accéder rapidement aux documents et fichiers pertinents directement depuis votre CRM. Vous n\'aurez plus besoin de chercher des documents dans différents dossiers ou applications.',
        realTimeCollaboration: '<strong>Collaboration en temps réel :</strong> Google Drive permet une collaboration en temps réel sur des documents et fichiers. En intégrant Google Drive, vous pouvez ainsi faciliter la collaboration entre membres de l\'équipe ou avec des clients.',
        dataBackupSecurity: '<strong>Sauvegarde et sécurité des données :</strong> Google Drive offre un stockage sécurisé et une sauvegarde des données en temps réel. En intégrant Google Drive, vous pouvez ainsi vous assurer que les données liées à vos clients ou projets sont bien sauvegardées et sécurisées.',
        toolIntegration5: '<strong>Intégration avec d\'autres outils :</strong> Google Drive peut s\'intégrer facilement avec d\'autres outils et applications, tels que des outils de gestion de projet ou des outils de messagerie. En intégrant Google Drive, vous pouvez ainsi centraliser les données liées à vos clients et projets et simplifier les processus de collaboration à travers différentes plateformes.',
        summary5: 'En somme, l\'intégration de Google Drive peut faciliter le stockage et la gestion de fichiers, offrir un accès rapide aux documents pertinents, faciliter la collaboration en temps réel, assurer la sécurité des données et simplifier les processus de collaboration à travers différentes plateformes. Cette intégration peut ainsi aider votre entreprise à améliorer l\'efficacité et la productivité de votre équipe, ainsi que la satisfaction de vos clients.',

        YStitle:'Signature électronique',
        integrationBenefits6: 'Intégrer Yousign peut offrir plusieurs avantages pour votre entreprise, notamment :',
        simplifiedContractManagement: '<strong>Simplification de la gestion de contrats :</strong> En intégrant Yousign, vous pouvez simplifier la gestion des contrats et des documents liés à vos clients ou à vos projets. Vous pouvez ainsi créer, signer et suivre des contrats directement depuis l\'outil, sans avoir à passer par des processus manuels et chronophages.',
        reducedSigningDelays: '<strong>Réduction des délais de signature :</strong> Yousign offre une solution de signature électronique rapide et sécurisée, ce qui peut permettre de réduire les délais de signature des contrats. En intégrant Yousign, vous pouvez ainsi accélérer les processus de signature et améliorer l\'efficacité de votre entreprise.',
        improvedProductivity: '<strong>Amélioration de la productivité :</strong> En simplifiant la gestion des contrats et en réduisant les délais de signature, l\'intégration de Yousign peut aider à améliorer la productivité de votre entreprise et de votre équipe.',
        documentSecurity: '<strong>Sécurisation des documents :</strong> Yousign offre une solution de signature électronique sécurisée, qui permet de garantir l\'authenticité et l\'intégrité des documents signés. En intégrant Yousign, vous pouvez ainsi assurer la sécurité des documents liés à vos clients et projets, tout en facilitant leur gestion.',
        toolIntegration6: '<strong>Intégration avec d\'autres outils :</strong> Yousign peut s\'intégrer facilement avec d\'autres outils et applications, tels que des outils de gestion de projet ou des outils de messagerie. En intégrant Yousign, vous pouvez ainsi centraliser les données liées à vos clients et projets et simplifier les processus de collaboration à travers différentes plateformes.',
        summary6: 'En somme, l\'intégration de Yousign peut simplifier la gestion des contrats, réduire les délais de signature, améliorer la productivité de votre entreprise et assurer la sécurité des documents liés à vos clients et projets. Cette intégration peut ainsi aider votre entreprise à améliorer l\'efficacité de votre équipe, ainsi que la satisfaction de vos clients.',

        AMStitle:'Envoi de SMS',
        integrationBenefits7: 'Intégrer AllMySms peut offrir plusieurs avantages pour votre entreprise, notamment :',
        improvedCommunication: '<strong>Amélioration de la communication avec les clients :</strong> En intégrant AllMySms, vous pouvez communiquer avec vos clients en utilisant un canal de communication efficace et rapide. Vous pouvez ainsi envoyer des SMS en masse pour des promotions, des notifications ou des rappels, directement depuis votre CRM.',
        increasedOpenRate: '<strong>Augmentation du taux d\'ouverture :</strong> Les SMS ont un taux d\'ouverture élevé, ce qui peut permettre d\'augmenter la visibilité de votre entreprise et de vos offres. En intégrant AllMySms, vous pouvez ainsi augmenter le taux d\'ouverture de vos messages et améliorer l\'efficacité de vos campagnes de communication.',
        automatedSending: '<strong>Automatisation des envois :</strong> AllMySms offre des fonctionnalités d\'automatisation des envois, qui permettent de planifier et d\'envoyer des SMS en fonction de scénarios pré-définis. En intégrant AllMySms , vous pouvez ainsi automatiser les envois de SMS pour des tâches telles que les confirmations de rendez-vous, les rappels de paiement ou les anniversaires de clients.',
        messagePersonalization: '<strong>Personnalisation des messages :</strong> AllMySms permet de personnaliser les messages envoyés en utilisant des variables telles que le nom du client ou le montant d\'une transaction. En intégrant AllMySms , vous pouvez ainsi personnaliser les messages en fonction des données stockées dans votre CRM, pour offrir une expérience client plus personnalisée.',
        dataCentralization: '<strong>Centralisation des données :</strong> En intégrant AllMySms, vous pouvez centraliser les données liées à la communication avec vos clients, pour faciliter la gestion des interactions et des réponses. Vous pouvez ainsi avoir une vue d\'ensemble des communications avec vos clients, directement depuis votre CRM.',
        summary7: 'En somme, l\'intégration de AllMySms peut améliorer la communication avec vos clients, augmenter le taux d\'ouverture de vos messages, automatiser les envois de SMS, personnaliser les messages et centraliser les données liées à la communication. Cette intégration peut ainsi aider votre entreprise à améliorer l\'efficacité de votre communication avec vos clients et à offrir une expérience client plus personnalisée.',

        DStitle:'Signature électronique',
        integrationBenefits8: 'Intégrer DocuSign peut offrir plusieurs avantages pour votre entreprise, notamment :',
        electronicSignature8: '<strong>Signature électronique :</strong> Avec DocuSign, vous pouvez obtenir des signatures électroniques pour des documents directement depuis votre CRM. Cela peut permettre de réduire les délais de traitement des documents et d\'améliorer l\'efficacité de votre entreprise.',
        documentCentralization: '<strong>Centralisation des documents :</strong> En intégrant DocuSign à votre organisation, vous pouvez centraliser les documents liés aux transactions avec vos clients. Cela peut faciliter la gestion des documents et la traçabilité des échanges avec les clients.',
        processAutomation: '<strong>Automatisation des processus :</strong> DocuSign permet d\'automatiser les processus liés à la signature de documents, ce qui peut permettre de gagner du temps et d\'améliorer l\'efficacité de votre entreprise. En intégrant DocuSign, vous pouvez ainsi automatiser les processus de signature et de gestion de documents, pour offrir une expérience client plus rapide et efficace.',
        integrationWithOtherApps: '<strong>Intégration avec d\'autres applications :</strong> DocuSign peut être intégré avec d\'autres applications telles que Salesforce, Microsoft Dynamics et SharePoint. En intégrant DocuSign, vous pouvez ainsi faciliter la gestion des processus liés à la signature de documents et améliorer l\'interopérabilité de vos applications.',
        dataSecurity: '<strong>Sécurité des données :</strong> DocuSign offre des fonctionnalités de sécurité avancées pour protéger les données sensibles des clients et des entreprises. En intégrant DocuSign, vous pouvez ainsi assurer la sécurité des données liées aux transactions avec les clients.',
        summary8: 'En somme, l\'intégration de DocuSign peut permettre d\'obtenir des signatures électroniques, centraliser les documents, automatiser les processus, faciliter l\'intégration avec d\'autres applications et assurer la sécurité des données. Cette intégration peut ainsi aider votre entreprise à améliorer l\'efficacité de vos processus de signature de documents, à offrir une expérience client plus rapide et efficace, et à assurer la sécurité des données.',

        customSoftware: 'Votre logiciel de travail sur mesure',
        getStarted: 'J\'y vais',

        // Esign

        theFuture: "L'avenir",
        electronicSignatures: "La signature électronique : l'avenir des transactions commerciales",
        electronicSignatureDescription1: "La signature électronique est un outil puissant pour les entreprises cherchant à améliorer leurs processus de signature de documents.",
        electronicSignatureDescription2: "De plus en plus d'entreprises adoptent la signature électronique pour ces raisons, et il est devenu clair que c'est l'avenir des transactions commerciales. Nous sommes convaincus que la signature électronique est un outil essentiel pour aider les entreprises à rester compétitives sur le marché.",
        powerAndPerformance: "Puissance et performances...",
        electronicSignaturePower: "La signature électronique est un outil puissant pour les entreprises cherchant à améliorer leurs processus de signature de documents. En effet, la signature électronique permet de réduire les délais de traitement des documents, d'augmenter la sécurité des données et de faciliter la gestion des transactions commerciales.",
        electronicSignatureFuture: "De plus en plus d'entreprises adoptent la signature électronique pour ces raisons, et il est devenu clair que c'est l'avenir des transactions commerciales. En tant que fournisseur, nous sommes convaincus que la signature électronique est un outil essentiel pour aider les entreprises à rester compétitives sur le marché.",
        Avantages: "Avantages",
        LesAvantagesSignatureElectronique: "Les avantages de l'intégration de la signature électronique",
        IntegrerSignatureElectroniqueDescription1: "Intégrer la signature électronique à votre outil de travail peut offrir plusieurs avantages pour votre entreprise, notamment :",
        AvantageReductionDelais: "Réduction des délais de traitement des documents",
        AvantageAugmentationSecurite: "Augmentation de la sécurité des données",
        AvantageFacilitationGestion: "Facilitation de la gestion des transactions commerciales",
        AvantageAmeliorationExperience: "Amélioration de l'expérience client",
        AvantageOptimisationProcessus: "Optimisation des processus de signature de documents",
        IntegrerSignatureElectroniqueDescription2: "En intégrant la signature électronique à votre outil de travail, vous pouvez améliorer l'efficacité de vos processus de signature de documents, offrir une expérience client plus rapide et efficace, et assurer la sécurité des données liées aux transactions commerciales.",
        ContactezNousEnSavoirPlus: "Contactez-nous pour en savoir plus",
        ContactezNousDescription: "Si vous souhaitez en savoir plus sur notre solution de signature électronique, n'hésitez pas à nous contacter. Nous serons ravis de répondre à toutes vos questions et de vous aider à améliorer vos processus grâce à la signature électronique.",
        DemandeRappel: "Demande de rappel",
        SignatureElectronique: "La signature électronique",


        titleTesti:'Témoignages',

        Support : "Support",
        SupportStandard: "Support standard",
        SupportStandardDescription: "Le support standard est notre niveau d'assistance de base, inclus pour tous les utilisateurs. Notre équipe dédiée est disponible pour répondre à vos questions techniques par email et via notre centre d'aide en ligne 24/7. Vous aurez également accès à une vaste bibliothèque de ressources d'auto-assistance, comprenant des guides, des tutoriels vidéo et une FAQ détaillée.",
        SupportPremium: "Support premium",
        SupportPremiumDescription: "Le support premium va encore plus loin que le support standard. En plus de tous les avantages du support standard, vous bénéficierez en tant qu'utilisateur premium d'une réponse prioritaire à vos demandes d'assistance, garantissant ainsi une réponse rapide de notre équipe de support. Vous aurez également accès à des sessions de formation en direct et à une assistance par chat en temps réel.",
        SupportBusiness: "Support silver",
        SupportBusinessDescription: "Le support silver est conçu pour les organisations de taille moyenne qui ont besoin d'une assistance plus approfondie. En plus de tous les avantages du support premium, vous bénéficierez en tant qu'utilisateur silver, d'un gestionnaire de compte dédié qui comprendra vos besoins spécifiques et travaillera avec vous pour optimiser l'utilisation de notre plateforme dans votre organisation. De plus, vous aurez accès à des formations personnalisées pour votre équipe et à une assistance téléphonique pendant les heures ouvrables.",
        SupportEnterprise: "Support gold",
        SupportEnterpriseDescription: "Le support gold est notre niveau de support le plus complet, conçu pour les grandes organisations qui ont besoin d'une assistance spécialisée. En plus de tous les avantages du support silver, vous bénéficierez en tant qu'utilisateur gold d'un temps de réponse garanti de deux heures pour les demandes d'assistance urgentes. De plus, vous aurez accès à une assistance téléphonique 24/7, à des audits de santé de l'application et à des sessions de planification stratégique avec notre équipe de direction. Votre réussite est notre priorité, et nous ferons tout notre possible pour vous aider à atteindre vos objectifs.",
        SupportRequest: "Demander du support",
        CreateTicket: "Créer un ticket",

        //CGU
        TermsOfUse: "Conditions générales d'utilisation",
        EffectiveDate: "En vigueur au 01/01/2023",
        TermsDescription0: "Les présentes conditions générales d'utilisation régissent l'utilisation de betool, la propriété intellectuelle de Technician Soft Solution, et commercialisé par Soft Group Technologies. Technician Soft Solution est le propriétaire exclusif de tous les droits de propriété intellectuelle relatifs à betool. SASU Soft Group Technologies SIREN 921 865 358 , une société de droit français, est responsable de la commercialisation de betool. L'utilisation de betool est soumise à l'acceptation de ces conditions, qui incluent les modalités de licence accordées par Technician Soft Solution et les conditions de vente et de service fournies par Soft Group Technologies.",
        TermsDescription1: "Les présentes conditions générales d'utilisation (dites « CGU ») ont pour objet l'encadrement juridique des modalités de mise à disposition du site et des services par https://www.betool.fr et de définir les conditions d’accès et d’utilisation des services par « l'Utilisateur ».",
        TermsDescription2: "Les présentes CGU sont accessibles sur le site à la rubrique «CGU».",
        TermsDescription3: "Toute inscription ou utilisation du site implique l'acceptation sans aucune réserve ni restriction des présentes CGU par l’utilisateur. Lors de l'inscription sur le site via le formulaire d’inscription, chaque utilisateur accepte expressément les présentes CGU en cochant la case précédant le texte suivant : « Je reconnais avoir lu et compris les CGU et je les accepte ». En cas de non-acceptation des CGU stipulées dans le présent contrat, l'Utilisateur se doit de renoncer à l'accès des services proposés par le site. www.betool.fr se réserve le droit de modifier unilatéralement et à tout moment le contenu des présentes CGU.",
        TermsDescription4: "Le site https://www.betool.fr se réserve le droit de modifier unilatéralement et à tout moment le contenu des présentes CGU.",

        IntroductionTitle: "1. Introduction",
        IntroductionDescription: "Bienvenue sur notre plateforme SaaS de type WorkSpace, Work system, Work OS, CRM, ERP, Gestion de projet et Ticketing solution. En tant que fournisseur de logiciel, nous proposons des services de licences utilisateurs, de licences d'applications, ainsi que des service d'accompagnement au paramétrage d'éléments divers tels que l'architecture, les documents, les templates, les API, etc. Notre solution est entièrement noCode, permettant à nos utilisateurs de créer des applications personnalisées sans avoir à écrire une seule ligne de code.",
        IntroductionTerms: "Les présentes conditions générales d'utilisation (dites « CGU ») ont pour objet de définir les modalités d'utilisation de notre Plateforme et des services que nous proposons.",
        IntroductionUsage: "En accédant et en utilisant notre plateforme et nos services, vous acceptez sans réserve les présentes CGU.",
        IntroductionAcceptance: "Veuillez les lire attentivement avant de continuer à utiliser notre plateforme et nos services.",

        DefinitionsTitle: "2. Définitions",
        Fournisseur: "Fournisseur",
        FournisseurDescription: "désigne notre société, fournisseur de logiciel SaaS de type WorkSpace, Work system, Work OS, CRM, ERP, Gestion de projet et Ticketing solution en full noCode, proposant des services de type licences utilisateurs, licences d'applications et des services d'accompagnement au paramétrage d'éléments divers (architecture, documents, Templates, API etc..).",
        Client: "Client",
        ClientDescription: "désigne toute personne physique ou morale qui utilise les services proposés par notre société, que ce soit en tant qu'utilisateur final ou en tant qu'administrateur autorisé par l'utilisateur final.",
        Utilisateur: "Utilisateur",
        UtilisateurDescription: "désigne toute personne physique ou morale qui utilise le site et/ou les services proposés par notre société.",
        Site: "Site",
        SiteDescription: "désigne le site web https://betool.fr et ses sous-domaines, ainsi que toute application mobile ou autre plateforme utilisée pour accéder aux services proposés par notre société.",
        Services: "Services",
        ServicesDescription: "désigne l'ensemble des services proposés par notre société, tels que les licences d'utilisation de notre logiciel, les services d'accompagnement pour le paramétrage et la personnalisation de nos solutions, ainsi que tout autre service proposé par notre société.",
        LicenceUtilisateur: "Licence utilisateur",
        LicenceUtilisateurDescription: "désigne l'autorisation d'utiliser notre logiciel et ses fonctionnalités, accordée par notre société à l'Utilisateur moyennant le paiement d'un abonnement ou d'une redevance.",
        LicenceApplication: "Licence d'application",
        LicenceApplicationDescription: "désigne l'autorisation d'utiliser une application développée par notre société pour être intégrée dans le logiciel de l'Utilisateur, accordée par notre société moyennant le paiement d'un abonnement ou d'une redevance.",

        objectTitle: "3. Objet des CGU",
        objectDescription1: "Les présentes Conditions Générales d'Utilisation (CGU) ont pour objet de définir les modalités de mise à disposition du site internet et des services proposés par www.betool.fr, ainsi que les conditions d'accès et d'utilisation de ces services par les utilisateurs. Ces CGU régissent l'ensemble des relations entre la société et les utilisateurs de son site, que ceux-ci soient simples visiteurs ou abonnés.",
        objectDescription2: "L'utilisation du site et de ses services implique l'acceptation sans réserve des présentes CGU par l'utilisateur. Si l'utilisateur n'accepte pas les termes des CGU, il est invité à quitter immédiatement le site et à ne pas utiliser les services proposés.",
        objectDescription3: "La société se réserve le droit de modifier unilatéralement et à tout moment le contenu des présentes CGU. Il est donc recommandé à l'utilisateur de consulter régulièrement la dernière version des CGU disponible sur le site.",
        objectDescription4: "Les CGU ne créent aucun contrat de société, de mandat, de franchise ou de relation de travail entre la société et l'utilisateur.",


        conditionsTitle: "4. Conditions d'utilisation",
        conditionsDescription1: "L'Utilisateur s'engage à utiliser les services proposés par le Fournisseur en toute bonne foi et conformément aux présentes CGU, à la réglementation en vigueur et aux usages.",
        conditionsDescription2: "L'accès aux services proposés par le Fournisseur est réservé aux personnes majeures juridiquement capables de souscrire des contrats en droit français.",
        conditionsDescription3: "L'Utilisateur est seul responsable de l'utilisation des services proposés par le Fournisseur et des données qu'il enregistre, transmet ou reçoit dans le cadre de ces services. L'Utilisateur s'engage à ne pas porter atteinte à l'ordre public, aux bonnes mœurs, aux droits de tiers ou à la sécurité du Fournisseur.",
        conditionsDescription4: "L'Utilisateur s'engage à fournir des informations exactes, complètes et à jour lors de son inscription aux services proposés par le Fournisseur. Il s'engage à informer le Fournisseur de toute modification de ces informations dans les plus brefs délais.",
        conditionsDescription5: "Le Fournisseur se réserve le droit de suspendre ou de résilier l'accès aux services proposés en cas de non-respect des présentes CGU ou en cas de violation de la réglementation en vigueur.",

        accessTitle: "5. Accès au site",
        accessDescription1: "Le site https://betool.fr permet à l'Utilisateur un accès aux services suivants :",
        accessService1: "Fournisseur de logiciel/progiciel/plateforme SaaS",
        accessService2: "Achat ou location de licences utilisateurs",
        accessService3: "Achat ou location de licences d'applications proposées sur la plateforme",
        accessService4: "Catégorie du produit (Work système, Work OS, CRM, ERP, Gestion de projet et Ticketing solution)",
        accessService5: "Type de produit : noCode.",
        accessDescription2: "Le site est accessible gratuitement en tout lieu à tout Utilisateur ayant un accès à Internet. Tous les frais supportés par l'Utilisateur pour accéder au service (matériel informatique, logiciels, connexion Internet, etc.) sont à sa charge.",
        accessDescription3: "L’Utilisateur non membre n'a pas accès aux services réservés. Pour cela, il doit s’inscrire en remplissant le formulaire. En acceptant de s’inscrire aux services réservés, l’Utilisateur membre s’engage à fournir des informations sincères et exactes concernant son état civil et ses coordonnées, notamment son adresse email. Pour accéder aux services, l’Utilisateur doit ensuite s'identifier à l'aide de son identifiant et de son mot de passe qui lui seront communiqués après son inscription. Tout Utilisateur membre régulièrement inscrit pourra également solliciter sa désinscription en se rendant à la page dédiée sur son espace personnel. Celle-ci sera effective dans un délai raisonnable.",
        accessDescription4: "Tout événement dû à un cas de force majeure ayant pour conséquence un dysfonctionnement du site ou serveur et sous réserve de toute interruption ou modification en cas de maintenance, n'engage pas la responsabilité du fournisseur. Dans ces cas, l’Utilisateur accepte ainsi de ne pas tenir rigueur à l’éditeur de toute interruption ou suspension de service, même sans préavis.",
        accessDescription5: "L'Utilisateur a la possibilité de contacter le site par messagerie électronique à l’adresse email du founisseur : contact@betool.fr.",


        intellectualPropertyTitle: "6. Propriété intellectuelle",
        intellectualPropertyDescription1: "Les marques, logos, signes ainsi que tous les contenus du site (textes, images, sons…) font l'objet d'une protection par le Code de la propriété intellectuelle et plus particulièrement par le droit d'auteur.",
        intellectualPropertyDescription2: "L'Utilisateur doit solliciter l'autorisation préalable du site pour toute reproduction, publication, copie des différents contenus. Il s'engage à une utilisation des contenus du site dans un cadre strictement privé, toute utilisation à des fins commerciales et publicitaires est strictement interdite.",
        intellectualPropertyDescription3: "Toute représentation totale ou partielle de ce site par quelque procédé que ce soit, sans l’autorisation expresse de l’exploitant du site Internet constituerait une contrefaçon sanctionnée par l’article L 335-2 et suivants du Code de la propriété intellectuelle.",
        intellectualPropertyDescription4: "Il est rappelé conformément à l’article L122-5 du Code de la propriété intellectuelle que l’Utilisateur qui reproduit, copie ou publie le contenu protégé doit citer l’auteur et sa source.",


        privacyTitle: "7. Confidentialité et protection des données",
        privacyDescription1: "Nous attachons une grande importance à la confidentialité et à la protection des données de nos clients. Dans le cadre de notre activité de fournisseur de services de gestion de données, nous collectons et traitons des données à caractère personnel de nos clients.",
        privacyCollectDataTitle: "Collecte des données",
        privacyCollectDataDescription: "Nous prenons très au sérieux la confidentialité et la protection de vos données personnelles. Nous ne collectons que les informations nécessaires à la fourniture de nos services, et nous ne partageons jamais ces informations avec des tiers sans votre consentement préalable. Lorsque vous utilisez nos services, nous pouvons collecter des informations telles que votre nom, votre adresse email, votre adresse IP, votre localisation, etc. Ces informations sont utilisées pour personnaliser et améliorer votre expérience utilisateur, ainsi que pour répondre à vos demandes et besoins.",
        privacyUseDataTitle: "Utilisation des données",
        privacyUseDataDescription: "Nous nous engageons à utiliser vos données personnelles uniquement dans le cadre de la fourniture de nos services, et à ne pas les utiliser à des fins commerciales ou publicitaires sans votre consentement préalable. Nous ne vendons pas vos données à des tiers et nous ne les partageons qu'avec les prestataires de services tiers qui nous aident à fournir nos services (par exemple, des fournisseurs de stockage cloud). Nous pouvons également utiliser vos données pour vous envoyer des communications liées à nos services, telles que des mises à jour, des promotions, etc.",
        privacyDataStorageTitle: "Stockage et sécurité des données",
        privacyDataStorageDescription: "Nous prenons des mesures de sécurité raisonnables pour protéger vos données personnelles contre tout accès, utilisation, altération ou divulgation non autorisé. Nous utilisons des technologies de cryptage, des pare-feux, des mots de passe et des contrôles d'accès pour protéger vos données personnelles. Nous stockons vos données dans des centres de données sécurisés et surveillons en permanence notre infrastructure pour détecter toute activité suspecte.",
        privacyUserRightsTitle: "Droits des utilisateurs",
        privacyUserRightsDescription: "Conformément aux lois applicables sur la protection des données, vous avez le droit de demander l'accès, la rectification, la suppression et la portabilité de vos données personnelles. Vous pouvez également demander la limitation du traitement de vos données personnelles ou vous opposer à leur traitement à des fins de marketing direct. Nous nous engageons à répondre rapidement et efficacement à toutes les demandes de nos utilisateurs concernant leurs données personnelles.",
        privacyDataRetentionTitle: "Durée de conservation des données",
        privacyDataRetentionDescription: "Nous ne conservons vos données personnelles que pendant la durée nécessaire à la fourniture de nos services ou à des fins légales ou réglementaires. Nous détruisons vos données personnelles de manière sécurisée lorsque nous n'en avons plus besoin ou lorsque vous nous demandez de le faire.",
        privacyPolicyLink: "Vous pouvez consulter la page dédiée à la politique de confidentialité.",

        userPublicationTitle: "8. Publication par l’Utilisateur",
        userPublicationDescription1: "Le site permet aux utilisateurs de publier les contenus suivants :",
        userPublicationTemplate: "template",
        userPublicationTextContent: "contenu textuel",
        userPublicationPhotoVideo: "photo, image ou vidéo",
        userPublicationDescription2: "Dans ses publications, l'utilisateur' s’engage à respecter les règles de la nétiquette (règles de bonne conduite de l’internet) et les règles de droit en vigueur.",
        userPublicationDescription3: "Le site peut exercer une modération sur les publications et se réserve le droit de refuser leur mise en ligne, sans avoir à s’en justifier auprès de l'utilisateur.",
        userPublicationDescription4: "L'utilisateur reste titulaire de l’intégralité de ses droits de propriété intellectuelle. Mais en publiant une publication sur le site, il cède à la société éditrice le droit non exclusif et gratuit de représenter, reproduire, adapter, modifier, diffuser et distribuer sa publication, directement ou par un tiers autorisé, dans le monde entier, sur tout support (numérique ou physique), pour la durée de la propriété intellectuelle. L'utilisateur cède notamment le droit d'utiliser sa publication sur internet et sur les réseaux de téléphonie mobile.",
        userPublicationDescription5: "La société éditrice s'engage à faire figurer le nom du utilisateur à proximité de chaque utilisation de sa publication.",
        userPublicationDescription6: "Tout contenu mis en ligne par l'Utilisateur est de sa seule responsabilité. L'Utilisateur s'engage à ne pas mettre en ligne de contenus pouvant porter atteinte aux intérêts de tierces personnes. Tout recours en justice engagé par un tiers lésé contre le site sera pris en charge par l'Utilisateur.",
        userPublicationDescription7: "Le contenu de l'Utilisateur peut être à tout moment et pour n'importe quelle raison supprimé ou modifié par le site, sans préavis.",

        responsibilityTitle: "9. Responsabilité",
        responsibilityDescription1: "Les sources des informations diffusées sur le site www.betool.fr sont réputées fiables mais le site ne garantit pas qu’il soit exempt de défauts, d’erreurs ou d’omissions. Les informations communiquées sont présentées à titre indicatif et sans valeur contractuelle. Malgré des mises à jour régulières, le site www.betool.fr ne peut être tenu responsable de la modification des dispositions administratives et juridiques survenant après la publication. De même, le site ne peut être tenu responsable de l’utilisation et de l’interprétation de l’information contenue dans ce site.",
        responsibilityDescription2: "L'Utilisateur s'assure de garder son mot de passe secret. Toute divulgation du mot de passe, quelle que soit sa forme, est interdite. Il assume les risques liés à l'utilisation de son identifiant et mot de passe. Le site décline toute responsabilité dans ce cas de figure.",
        responsibilityDescription3: "Le site www.betool.fr ne peut être tenu pour responsable d’éventuels virus qui pourraient infecter l’ordinateur ou tout matériel informatique de l’Internaute, suite à une utilisation, à l’accès, ou au téléchargement provenant de ce site. La responsabilité du site ne peut être engagée en cas de force majeure ou du fait imprévisible et insurmontable d'un tiers.",

        paymentTermsTitle: "10. Modalités de paiement",
        paymentTermsDescription1: "Le paiement de nos services s'effectue selon les modalités suivantes :",
        paymentTermsItem1: "Pour les services souscrits à l'achat de licences utilisateurs, le paiement se fait par avance et le montant est fixé en fonction du nombre de licences souscrites. Le paiement peut être effectué par virement bancaire, carte bancaire ou tout autre moyen de paiement accepté par notre société.",
        paymentTermsItem2: "Pour les services souscrits à la location de licences utilisateurs, le paiement s'effectue mensuellement ou annuellement, selon les modalités définies dans le contrat de location. Le paiement peut être effectué par virement bancaire, carte bancaire ou tout autre moyen de paiement accepté par notre société.",
        paymentTermsItem3: "Les services d'accompagnement au paramétrage sont facturés à l'heure, selon les tarifs en vigueur. Le paiement peut être effectué par virement bancaire, carte bancaire ou tout autre moyen de paiement accepté par notre société.",
        paymentTermsDescription2: "Tous les paiements doivent être effectués dans les délais impartis. En cas de retard de paiement, notre société se réserve le droit de suspendre ou résilier le service en question, sans préjudice des autres droits et recours qui pourraient être exercés. Tout paiement effectué après la date limite entraînera automatiquement une majoration de 10 % des sommes dues, sans préjudice des autres intérêts de retard.",
        paymentTermsDescription3: "En cas de non-paiement, notre société se réserve le droit d'engager toute action en justice qui serait nécessaire pour recouvrer les sommes dues.",
        paymentTermsDescription4: "Tous les frais bancaires liés aux paiements sont à la charge du client.",

        terminationSuspensionTitle: "11. Résiliation et suspension",
        userTerminationTitle: "Résiliation à l'initiative de l'utilisateur",
        userTerminationDescription: "L'utilisateur peut résilier son contrat d'utilisation à tout moment en envoyant une demande écrite de résiliation par courrier recommandé à l'adresse email du fournisseur : contact@betool.fr. La résiliation prendra effet à la fin de la période d'abonnement en cours, sauf en cas de violation des conditions générales d'utilisation, auquel cas le fournisseur se réserve le droit de mettre fin immédiatement au contrat d'utilisation sans préavis ni indemnité.",
        providerTerminationTitle: "Résiliation à l'initiative du fournisseur",
        providerTerminationDescription: "Le fournisseur se réserve le droit de résilier le contrat d'utilisation en cas de non-respect des conditions générales d'utilisation par l'utilisateur. Le cas échéant, la résiliation prendra effet immédiatement sans préavis ni indemnité. Le fournisseur peut également résilier le contrat d'utilisation à tout moment, moyennant un préavis de 30 jours, sans avoir à justifier sa décision. Le cas échéant, le fournisseur remboursera à l'utilisateur le montant correspondant à la période d'abonnement restant à courir.",
        terminationConsequencesTitle: "Conséquences de la résiliation",
        terminationConsequencesDescription: "En cas de résiliation du contrat d'utilisation, quelle qu'en soit la raison, l'utilisateur s'engage à cesser immédiatement toute utilisation du service et à restituer au fournisseur l'ensemble des documents, données et informations qui lui ont été confiés dans le cadre de l'utilisation du service. Le fournisseur ne sera pas tenu responsable des dommages directs ou indirects causés à l'utilisateur ou à des tiers du fait de la résiliation du contrat d'utilisation.",
        dataArchivingTitle: "Archivage des données",
        dataArchivingDescription: "Suite à la résiliation du contrat d'utilisation, le fournisseur s'engage à conserver les données de l'utilisateur pendant une période de 1 an à compter de la date de résiliation. Passé ce délai, le fournisseur pourra procéder à la suppression définitive de ces données, sauf si l'utilisateur en demande expressément la conservation pour une durée supplémentaire. Dans tous les cas, le fournisseur garantit la confidentialité et la sécurité des données stockées.",

        lawJurisdictionTitle: "12. Loi applicable et juridiction compétente",
        lawApplicableDescription: "Les présentes CGU sont soumises à la loi française.",
        disputeResolutionDescription: "En cas de litige survenant entre les parties à l'occasion de l'exécution des présentes, les parties conviennent de tenter de régler le différend à l'amiable. À défaut d'accord amiable dans un délai de trente (30) jours à compter de la notification du litige par l'une des parties à l'autre, le différend sera soumis aux tribunaux compétents de Paris, nonobstant pluralité de défendeurs ou appel en garantie.",
        userFinalDisclaimerDescription: "Il est rappelé que la société éditrice n'est qu'un prestataire technique pour le compte de l'utilisateur final. La société éditrice ne pourra être tenue pour responsable de tout litige survenant entre l'utilisateur final et un tiers. Tout litige devra être résolu directement entre l'utilisateur final et le tiers concerné, sans impliquer la responsabilité de la société éditrice.",
        independenceDisclaimerDescription: "Les parties reconnaissent expressément que les présentes CGU ne créent aucune communauté de droit ni aucun mandat, ni aucune société entre les parties et que chacune conserve sa pleine et entière indépendance.",
        waiverDescription: "Toute renonciation à exercer un droit prévu par les présentes CGU ne pourra être interprétée comme une renonciation à exercer tout autre droit ou à la même disposition à un autre moment.",
        articleTitlesDisclaimerDescription: "Les titres des articles des présentes CGU ont été inclus pour plus de commodité, mais n'affecteront pas la signification ou l'interprétation des présentes CGU.",

        hyperlinkTitle: "13. Liens hypertextes",
        hyperlinkDescription: "Des liens hypertextes peuvent être présents sur le site. L’Utilisateur est informé qu’en cliquant sur ces liens, il sortira du site https://betool.fr. Ce dernier n’a pas de contrôle sur les pages web sur lesquelles aboutissent ces liens et ne saurait, en aucun cas, être responsable de leur contenu.",


        cookiesTitle: "14. Cookies",
        cookiesDescription1: "L’Utilisateur est informé que lors de ses visites sur le site, un cookie peut s’installer automatiquement sur son logiciel de navigation.",
        cookiesDescription2: "Les cookies sont de petits fichiers stockés temporairement sur le disque dur de l’ordinateur de l’Utilisateur par votre navigateur et qui sont nécessaires à l’utilisation du site https://betool.fr. Les cookies ne contiennent pas d’information personnelle et ne peuvent pas être utilisés pour identifier quelqu’un. Un cookie contient un identifiant unique, généré aléatoirement et donc anonyme. Certains cookies expirent à la fin de la visite de l’Utilisateur, d’autres restent.",
        cookiesDescription3: "L’information contenue dans les cookies est utilisée pour améliorer le site https://betool.fr.",
        cookiesDescription4: "En naviguant sur le site, l’Utilisateur les accepte.",
        cookiesDescription5: "L’Utilisateur doit toutefois donner son consentement quant à l’utilisation de certains cookies.",
        cookiesDescription6: "A défaut d’acceptation, l’Utilisateur est informé que certaines fonctionnalités ou pages risquent de lui être refusées.",
        cookiesDescription7: "L’Utilisateur pourra désactiver ces cookies par l’intermédiaire des paramètres figurant au sein de son logiciel de navigation.",

        // dmcaPolicy
        dmcaPolicyTitle: "POLITIQUE DE RETRAIT DMCA",
        dmcaPolicyEffectiveDate: "En vigueur au 01/01/2023",
        dmcaPolicyDescription1: "Notre société respecte le Digital Millennium Copyright Act (DMCA) et prend très au sérieux les plaintes pour violation de droits d'auteur. Si vous êtes propriétaire d'un droit d'auteur ou de toute autre propriété intellectuelle et que vous pensez que notre site web ou nos services enfreignent vos droits, nous vous encourageons à nous contacter immédiatement.",
        dmcaPolicyDescription2: "Pour soumettre une notification de violation de droits d'auteur, vous devez fournir une communication écrite qui inclut les informations suivantes :",
        dmcaPolicyDescription3: "Une signature électronique ou physique de la personne autorisée à agir au nom du propriétaire des droits d'auteur ou d'autres droits de propriété intellectuelle ;",
        dmcaPolicyDescription4: "Une description de l'œuvre protégée par des droits d'auteur ou d'autres droits de propriété intellectuelle que vous revendiquez avoir été violée ;",
        dmcaPolicyDescription5: "Une description de l'emplacement sur notre site web ou dans nos services où se trouve le contenu que vous réclamez en violation de droits d'auteur ou d'autres droits de propriété intellectuelle ;",
        dmcaPolicyDescription6: "Votre adresse, numéro de téléphone et adresse email ;",
        dmcaPolicyDescription7: "Une déclaration de votre part selon laquelle vous croyez de bonne foi que l'utilisation contestée n'est pas autorisée par le propriétaire des droits d'auteur ou ses agents, ou la loi ;",
        dmcaPolicyDescription8: "Une déclaration de votre part, sous peine de parjure, selon laquelle les informations ci-dessus dans votre notification sont exactes et que vous êtes le propriétaire des droits d'auteur ou de la propriété intellectuelle ou que vous êtes autorisé à agir au nom du propriétaire des droits d'auteur ou de la propriété intellectuelle.",
        dmcaPolicyDescription9: "Veuillez envoyer votre notification à notre agent désigné pour les notifications de violation de droits d'auteur à l'adresse suivante : contact@betool.fr.",
        dmcaPolicyDescription10: "Une fois que nous recevons une notification de violation de droits d'auteur conforme aux exigences ci-dessus, nous nous engageons à retirer ou à désactiver l'accès au contenu en question dans un délai raisonnable. Nous prendrons également des mesures pour informer l'utilisateur qui a téléchargé le contenu en question de la violation alléguée et lui donnerons la possibilité de soumettre une réponse conforme au DMCA. Si l'utilisateur soumet une réponse en bonne et due forme, nous pourrions être amenés à réactiver le contenu signalé.",
        dmcaPolicyDescription11: "Nous nous réservons le droit de supprimer tout contenu sans préavis, à notre seule discrétion, si nous déterminons qu'il enfreint les droits de propriété intellectuelle d'un tiers.",
        dmcaPolicyDescription12: "Nous encourageons tous nos utilisateurs à respecter les droits de propriété intellectuelle des autres. Si vous êtes un utilisateur de notre site web ou de nos services et que vous croyez qu'un utilisateur a violé vos droits de propriété intellectuelle, veuillez nous contacter immédiatement.",


        // POLITIQUE DE CONFIDENTIALITÉ
        privacyPolicyTitle: "POLITIQUE DE CONFIDENTIALITÉ",
        privacyPolicyEffectiveDate: "En vigueur au 01/01/2023",
        privacyPolicyDescription1: "betool.fr",
        privacyPolicyDescription0: "Technician Soft Solution",
        privacyPolicyDescription00 :"Type de site : Logiciel SaaS",
        privacyPolicyDescription2: "But de la politique de confidentialité",
        privacyPolicyDescription25: "Le but de cette politique de confidentialité est de définir les modalités de traitement des données personnelles, dans le cadre d'une relation commerciale de confiance, notamment entre le fournisseur du site www.betool.fr et les utilisateurs du site, dans les domaines suivants :",
        privacyPolicyDataCollected: "Les données personnelles recueillies",
        privacyPolicyDataUsage: "L’utilisation des données recueillies",
        privacyPolicyDataAccess: "L’accès aux données recueillies",
        privacyPolicyUserRights: "Les droits des utilisateurs du site",
        privacyPolicyCookiePolicy: "La politique de cookies du site",
        privacyPolicyDescription3: "Cette politique de confidentialité fonctionne parallèlement aux conditions générales d’utilisation de notre site.",

        applicableLaws: "Lois applicables",
        gdprCompliance: "Conformément au Règlement général sur la protection des données (RGPD), cette politique de confidentialité est conforme aux lois et règlement suivants : <br> - Règlement (UE) 2016/679 du Parlement Européen du 27 avril 2016<br> - Loi 78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés<br> - Loi 2018-493 du 2 juin 2018 relative à la protection des données personnelles. <br> Les données à caractère personnel doivent être :",
        legalBasisLawfulness1: "traitées de manière licite, loyale et transparente au regard de la personne concernée (licéité, loyauté, transparence) ;",
        legalBasisLawfulness2: "collectées pour des finalités déterminées, explicites et légitimes, et ne pas être traitées ultérieurement d'une manière incompatible avec ces finalités ; le traitement ultérieur à des fins archivistiques dans l'intérêt public, à des fins de recherche scientifique ou historique ou à des fins statistiques n'est pas considéré, conformément à l'article 89, paragraphe 1, comme incompatible avec les finalités initiales (limitation des finalités) ;",
        legalBasisLawfulness3: "adéquates, pertinentes et limitées à ce qui est nécessaire au regard des finalités pour lesquelles elles sont traitées (minimisation des données) ;",
        legalBasisLawfulness4: "exactes et, si nécessaire, tenues à jour ; toutes les mesures raisonnables doivent être prises pour que les données à caractère personnel qui sont inexactes, eu égard aux finalités pour lesquelles elles sont traitées, soient effacées ou rectifiées sans tarder (exactitude) ;",
        legalBasisLawfulness5: "conservées sous une forme permettant l'identification des personnes concernées pendant une durée n'excédant pas celle nécessaire au regard des finalités pour lesquelles elles sont traitées ; les données à caractère personnel peuvent être conservées pour des durées plus longues dans la mesure où elles seront traitées exclusivement à des fins archivistiques dans l'intérêt public, à des fins de recherche scientifique ou historique ou à des fins statistiques conformément à l'article 89, paragraphe 1, pour autant que soient mises en œuvre les mesures techniques et organisationnelles appropriées requises par le règlement afin de garantir les droits et libertés de la personne concernée (limitation de la conservation) ;",
        legalBasisLawfulness6: "traitées de façon à garantir une sécurité appropriée des données à caractère personnel, y compris la protection contre le traitement non autorisé ou illicite et contre la perte, la destruction ou les dégâts d'origine accidentelle, à l'aide de mesures techniques ou organisationnelles appropriées (intégrité et confidentialité).",
        californiaPrivacyCompliance: "Pour les résidents de l’État de Californie, cette politique de confidentialité vise à se conformer à la California Consumer Privacy Act (CCPA). S’il y a des incohérences entre ce document et la CCPA, la législation de l’État s’appliquera. Si nous constatons des incohérences, nous modifierons notre politique pour nous conformer à la loi pertinente.",

        consent: "Consentement",
        consent1: "Les utilisateurs conviennent qu’en utilisant notre site, ils consentent :",
        consentConditions: "aux conditions énoncées dans la présente politique de confidentialité et",
        consentDataCollection: "à la collecte, l’utilisation et la conservation des données énumérées dans la présente politique.",
        personalDataCollection: "Données personnelles que nous collectons",
        automaticallyCollectedData: "Données personnelles collectées automatiquement",
        automaticallyCollectedDataDescription: "Lorsque vous visitez et utilisez notre site, nous pouvons automatiquement recueillir et conserver les renseignements suivants :",
        ipAddress: "Adresse IP",
        location: "Lieu",
        hardwareSoftwareDetails: "Détails matériels et logiciels",
        linksClicked: "Liens sur lequel clique un utilisateur tout en utilisant le site",
        contentViewed: "Contenu consulté par l’utilisateur sur le site",
        browsingHistoryActions: "Historiques de navigation et actions des utilisateurs",
        nonAutomaticallyCollectedData: "Données recueillies de façon non automatique",
        nonAutomaticallyCollectedDataDescription: "Nous pouvons également collecter les données suivantes lorsque vous effectuez certaines fonctions sur notre site :",
        firstNameLastName: "Prénom et nom",
        age: "Âge",
        dateOfBirth: "Date de naissance",
        gender: "Sexe",
        phoneNumber: "Numéro de téléphone",
        address: "Domicile",
        paymentInformation: "Information de paiement",
        autofillData: "Données de remplissage automatique",
        dataCollectionMethods: "Ces données peuvent être recueillies au moyen des méthodes suivantes :",
        dataCollectionNote: "Veuillez noter que nous ne collectons que les données qui nous aident à atteindre l’objectif énoncé dans cette politique de confidentialité. Nous ne recueillerons pas de données supplémentaires sans vous en informer au préalable.",

        howWeUsePersonalData: "Comment nous utilisons les données personnelles",
        howWeUsePersonalData1: "Les données personnelles recueillies sur notre site seront utilisées uniquement aux fins précisées dans la présente politique ou indiquées sur les pages pertinentes de notre site. Nous n’utiliserons pas vos données au-delà de ce que nous divulguerons.",
        automaticallyCollectedDataUsage: "Les données que nous recueillons automatiquement sont utilisées aux fins suivantes :",
        improveUserExperience: "Améliorer l'expérience utilisateur",
        improveSolution: "Améliorer la solution",
        keepAccessActionLogs: "Tenir un registre des accès et des actions pour les historiques",
        certifyElectronicSignatures: "Certification des signatures électroniques",
        nonAutomaticallyCollectedDataUsage: "Les données que nous recueillons lorsque l’utilisateur exécute certaines fonctions peuvent être utilisées aux fins suivantes :",
        viaBrowser: "Via le navigateur",
        dataSharing: "Les tiers avec qui nous partageons les données personnelles",
        employees: "Employés",
        employeeDisclosure: "Nous pouvons divulguer à tout utilisateur de notre organisation les données utilisateur dont il a raisonnablement besoin pour réaliser les objectifs énoncés dans la présente politique.",
        thirdParties: "Tierces parties",
        thirdPartiesDataUsage: "Nous pouvons partager les données utilisateur avec les tiers suivants :",
        thirdPartiesDataAccess: "Les tiers ne seront pas en mesure d’accéder aux données des utilisateurs au-delà de ce qui est raisonnablement nécessaire pour atteindre l’objectif donné.",
        otherDisclosures: "Autres divulgations",
        lawRequirement: "si la loi l'exige",
        legalProceedings: "si elle est requise pour toute procédure judiciaire",
        protectLegalRights: "si nous devons prouver ou protéger nos droits légaux",
        businessSale: " si nous devons transmettre des informations aux acquéreurs potentiels de la société et à leurs conseils",
        hyperlinkDisclaimer: "Si vous suivez des hyperliens de notre site vers un autre site, veuillez noter que nous ne sommes pas responsables et n’avons pas de contrôle sur les politiques et pratiques de confidentialité de ces sites.",
        dataRetention: "Combien de temps nous stockons les données personnelles",
        dataRetentionNote: "Nous ne conservons pas les données des utilisateurs au-delà de ce qui est nécessaire pour atteindre les fins pour lesquelles elles sont recueillies.",

        howWeProtectPersonalData: "Comment nous protégeons vos données personnelles.",
        howWeProtectPersonalData1: "Afin d’assurer la protection de votre sécurité, nous utilisons le protocole de sécurité de transport des données pour transmettre des renseignements personnels dans notre système.<br><br>Toutes les données stockées dans notre système sont bien sécurisées et ne sont accessibles qu’à nos employés. Nos employés sont liés par des accords de confidentialité stricts et une violation de cet accord entraînerait le licenciement de l'employé.",
        transportLayerSecurity: "Nous utilisons le protocole de sécurité de la couche transport pour transmettre des renseignements personnels dans notre système.",
        dataStorageSecurity: "Toutes les données stockées dans notre système sont bien sécurisées et ne sont accessibles qu’à nos employés. Nos employés sont liés par des accords de confidentialité stricts et une violation de cet accord entraînerait le licenciement de l'employé.",
        employeeConfidentialityAgreements: "Nos employés sont liés par des accords de confidentialité stricts et une violation de cet accord entraînerait le licenciement de l'employé.",
        internetInsecurity: "Alors que nous prenons toutes les précautions raisonnables pour nous assurer que nos données utilisateur sont sécurisées et que les utilisateurs sont protégés, il reste toujours un risque de préjudice. L’Internet en sa totalité peut être, parfois, peu sûr et donc nous sommes incapables de garantir la sécurité des données des utilisateurs au-delà de ce qui est raisonnablement mis en oeuvre.",
        minors: "Mineurs",
        minorDataCollection: "Le RGPD précise que les personnes de moins de 15 ans sont considérées comme des mineurs aux fins de la collecte de données. Les mineurs doivent avoir le consentement d’un représentant légal pour que leurs données soient recueillies, traitées et utilisées.",
        userRights: "Vos droits en tant qu’utilisateur",
        gdprRights: "En vertu du RGPD, les utilisateurs ont les droits suivants en tant que personnes concernées :",
        rightOfAccess: "droit d’accès",
        rightOfRectification: "droit de rectification",
        rightOfErasure: "droit à l’effacement",
        rightOfRestriction: "droit à la restriction de traitement",
        rightOfDataPortability: "droit à la portabilité des données",
        rightOfObjection: "droit d'objection",
        gdprRightsDetails: "Vous trouverez de plus amples informations sur ces droits au chapitre 3 (art 12-23) du RGPD.",
        modifyDeleteContestData: "Comment modifier, supprimer ou contester les données recueillies",
        privacyAgent: "Si vous souhaitez que vos renseignements soient supprimés ou modifiés d’une façon ou d’une autre, veuillez communiquer avec notre agent de protection de la vie privée ici :",

        cookiePolicy: "Politique sur les cookies",
        cookieExplanation: "Un cookie est un petit fichier, stocké sur le disque dur d’un utilisateur par le site Web. Son but est de recueillir des données relatives aux habitudes de navigation de l’utilisateur.",
        functionalCookies: "Cookies fonctionnels : ",
        functionalCookiesDescription: "Nous les utilisons pour mémoriser toutes les sélections que vous faites sur notre site afin qu’elles soient sauvegardées pour vos prochaines visites.",
        analyticalCookies: "Cookies analytiques : ",
        analyticalCookiesDescription: "Cela nous permet d’améliorer la conception et la fonctionnalité de notre site en recueillant des données sur le contenu auquel vous accédez et vous portez attention en utilisant notre site.",
        targetingCookies: "Cookies de ciblage : ",
        targetingCookiesDescription: "Ces cookies collectent des données sur la façon dont vous utilisez le site et vos préférences. Cela nous permet de rendre les informations que vous voyez sur notre site plus ciblées pour vous.",
        cookieSettings: "Vous pouvez choisir d’être averti chaque fois qu’un cookie est transmis. Vous pouvez également choisir de désactiver les cookies entièrement dans votre navigateur Internet, mais cela peut diminuer la qualité de votre expérience d’utilisation.",
        thirdPartyCookies: "Cookies tiers",
        thirdPartyCookies1: "Nous pouvons utiliser des cookies tiers sur notre site pour atteindre les objectifs suivants :",
        enhanceSecurity: "Améliorer la sécurité",
        modifications: "Modifications",
        modificationsDescription: "Cette politique de confidentialité peut être modifiée à l’occasion afin de maintenir la conformité avec la loi et de tenir compte de tout changement à notre processus de collecte de données. Nous recommandons à nos utilisateurs de vérifier notre politique régulièrement pour s’assurer qu’ils soient informés de toute mise à jour. Au besoin, nous pouvons informer les utilisateurs par courriel des changements apportés à cette politique.",
        contactDescription: "Si vous avez des questions à nous poser, n’hésitez pas à communiquer avec nous en utilisant ce qui suit :",


        supportRequ: "Demande de support",
        leadRequ: "Demande d'informations :",
        companyName: "Nom de l'entreprise :",
        problemDescription: "Description de votre demande de support :",
        problemDescriptionL: "Description de votre besoin :",
        phoneContact: "Téléphone :",
        emailContact: "Email :",

        companyNameRequired: "Le nom de l'entreprise est requis",
        requestSubjectRequired: "Le sujet de la demande est requis",
        phoneNumberRequired: "Le numéro de téléphone est requis",
        emailRequired: "L'email est requis",
        emailInvalid: "L'email doit être valide",
        apiDocumentation: "Documentation API"


}
}
