import { render, staticRenderFns } from "./suppotAssist.vue?vue&type=template&id=23d6f182&scoped=true"
import script from "./suppotAssist.vue?vue&type=script&lang=js"
export * from "./suppotAssist.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23d6f182",
  null
  
)

export default component.exports