<template>
    <section class="sectionSecur mt-8 pb-15">
        <h4 class="text-center">{{ $t('message.title') }}</h4>
        <div class="row mt-8">
            <div class="col-12 col-md-4">
                <div class="supportBox">
                    <h5 class="text-center">
                        <i class="fa-regular fa-display-code"></i>{{ $t('message.apis') }}
                    </h5>
                    <div class="v-card__text text-center">
                        {{ $t('message.apiDescription') }}
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="supportBox">
                    <h5 class="text-center">
                        <i class="fa-solid fa-clapperboard-play"></i>{{ $t('message.tutorials') }}
                    </h5>
                    <div class="v-card__text text-center">
                        {{ $t('message.tutorialsDescription') }}
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="supportBox">
                    <h5 class="text-center">
                        <i class="fa-solid fa-headset"></i>{{ $t('message.assistance') }}
                    </h5>
                    <div class="v-card__text text-center">
                        {{ $t('message.assistanceDescription') }}
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "suppotAssist"
}
</script>

<style scoped>

</style>