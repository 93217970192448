<template>
    <div class="pageStructure">
        <div class="container">
            <div>
<!--            tout    traduit-->
                <section class="mt-10">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="cardProducts v-card v-card--flat v-sheet theme--light">
                                <div class="d-flex pt-4" style="padding: 0 14px;">
                                    <div>
                                        <v-img class="imgSelectionTI" src="https://objectif-crm.com/assets/images/servicesNew.png" height="47" width="47" contain></v-img>
                                    </div>
                                    <div class="titleVCard" style="max-width: 84%;">
                                        <div class="ocStrong ellipsisClass">{{ $t('message.applicativeServices') }}</div>
                                        <div>{{ $t('message.function') }}</div>
                                    </div>
                                </div>
                                <div class="descCardProduct">
                                    <div class="v-card__subtitle subtitleVCard">{{ $t('message.manageCommunicateAutomate') }}</div>
                                    <div class="v-card__subtitle subtitleVCard">{{ $t('message.betoolServices') }}</div>
                                </div>
                                <hr role="separator" aria-orientation="horizontal"
                                    class="diviProduct v-divider theme--light"
                                    style="height: 4px; max-height: 4px; background-color: rgb(255, 102, 196); margin: 4px 12px;">
                                <div class="v-card__subtitle subtitleVCard">{{ $t('message.concreteExamples') }}</div>
                                <div class="v-card__text">
                                    <ul class="ulVCard">
                                        <li>{{ $t('message.dynamicForm') }}</li>
                                        <li>{{ $t('message.productCatalogue') }}</li>
                                        <li>{{ $t('message.calendar') }}</li>
                                        <li>{{ $t('message.accounting') }}</li>
                                    </ul>
                                </div>
                                <div class="v-card__actions"><a href="#services" class="v-btn v-btn--text theme--light v-size--default"><span class="v-btn__content">{{ $t('message.learnMore') }} <i class="fa-solid fa-sort-down ml-2 pb-2"></i></span></a></div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="cardProducts v-card v-card--flat v-sheet theme--light">
                                <div class="d-flex pt-4" style="padding: 0px 14px;">
                                    <div>
                                        <v-img class="imgSelectionTI" src="https://objectif-crm.com/assets/images/appPlug.png" height="47" width="47" contain></v-img>
                                    </div>
                                    <div class="titleVCard" style="max-width: 84%;">
                                        <div class="ocStrong ellipsisClass">{{ $t('message.appsPlugins') }}</div>
                                        <div>{{ $t('message.connectivity') }}</div>
                                    </div>
                                </div>
                                <div class="descCardProduct">
                                    <div class="v-card__subtitle subtitleVCard">{{ $t('message.connectActivate') }}</div>
                                    <div class="v-card__subtitle subtitleVCard">{{ $t('message.appsProposed') }}</div>
                                </div>
                                <hr role="separator" aria-orientation="horizontal"
                                    class="diviProduct v-divider theme--light"
                                    style="height: 4px; max-height: 4px; background-color: rgb(203, 108, 230); margin: 4px 12px;">
                                <div class="v-card__subtitle subtitleVCard">{{ $t('message.concreteExamples') }}</div>
                                <div class="v-card__text">
                                    <ul class="ulVCard">
                                        <li>Google DRIVE</li>
                                        <li>DocuSign</li>
                                        <li>Aircall</li>
                                        <li>Stripe paiement</li>
                                    </ul>
                                </div>
                                <div class="v-card__actions"><a href="#application" class="v-btn v-btn--text theme--light v-size--default"><span class="v-btn__content">{{ $t('message.learnMore') }} <i class="fa-solid fa-sort-down ml-2 pb-2"></i></span></a></div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="cardProducts v-card v-card--flat v-sheet theme--light">
                                <div class="d-flex pt-4" style="padding: 0px 14px;">
                                    <div>
                                        <v-img
                                            class="imgSelectionTI"
                                            src="https://objectif-crm.com/assets/images/Utilisateurs.png"
                                            height="47"
                                            width="47"
                                            cover
                                        ></v-img>
                                    </div>
                                    <div class="titleVCard" style="max-width: 84%;">
                                        <div class="ocStrong ellipsisClass">{{ $t('message.collaboratorsManagement') }}</div>
                                        <div>{{ $t('message.optimization') }}</div>
                                    </div>
                                </div>
                                <div class="descCardProduct">
                                    <div class="v-card__subtitle subtitleVCard">{{ $t('message.optimizeTeams') }}</div>
                                    <br>
                                    <div class="v-card__subtitle subtitleVCard">{{ $t('message.managementRights') }}</div>
                                </div>
                                <hr role="separator" aria-orientation="horizontal" class="diviProduct v-divider theme--light" style="height: 4px; max-height: 4px; background-color: rgb(255, 102, 196); margin: 4px 12px;">
                                <div class="v-card__subtitle subtitleVCard">{{ $t('message.concreteExamples') }}</div>
                                <div class="v-card__text">
                                    <ul class="ulVCard">
                                        <li>{{ $t('message.taskManagement') }}</li>
                                        <li>{{ $t('message.projectManagement') }}</li>
                                        <li>{{ $t('message.automatedWorkProcesses') }}</li>
                                        <li>{{ $t('message.createYours') }}</li>
                                    </ul>
                                </div>
                                <div class="v-card__actions"><a href="#collaborateur" class="v-btn v-btn--text theme--light v-size--default"><span class="v-btn__content">{{ $t('message.learnMore') }} <i class="fa-solid fa-sort-down ml-2 pb-2"></i></span></a></div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="cardProducts v-card v-card--flat v-sheet theme--light">
                                <div class="d-flex pt-4" style="padding: 0px 14px;">
                                    <div>
                                        <v-img
                                            class="imgSelectionTI"
                                            src="https://objectif-crm.com/assets/images/projets.png"
                                            height="47"
                                            width="47"
                                            cover
                                        ></v-img>
                                    </div>
                                    <div class="titleVCard" style="max-width: 84%;">
                                        <div class="ocStrong ellipsisClass">{{ $t('message.projectManagement') }}</div>
                                        <div>{{ $t('message.dynamism') }}</div>
                                    </div>
                                </div>
                                <div class="descCardProduct">
                                    <div class="v-card__subtitle subtitleVCard">{{ $t('message.createDatabase') }}</div>
                                    <br>
                                    <div class="v-card__subtitle subtitleVCard">{{ $t('message.dynamicCreationTool') }}</div>
                                </div>
                                <hr role="separator" aria-orientation="horizontal"
                                    class="diviProduct v-divider theme--light"
                                    style="height: 4px; max-height: 4px; background-color: rgb(52, 82, 219); margin: 4px 12px;">
                                <div class="v-card__subtitle subtitleVCard">{{ $t('message.concreteExamples') }}</div>
                                <div class="v-card__text">
                                    <ul class="ulVCard">
                                        <li>{{ $t('message.crm') }}</li>
                                        <li>{{ $t('message.erp') }}</li>
                                        <li>{{ $t('message.kanban') }}</li>
                                        <li>{{ $t('message.ticketing') }}</li>
                                    </ul>
                                </div>
                                <div class="v-card__actions"><a href="#projets" class="v-btn v-btn--text theme--light v-size--default"><span class="v-btn__content">{{ $t('message.learnMore') }} <i class="fa-solid fa-sort-down ml-2 pb-2"></i></span></a></div>
                            </div>
                        </div>
                    </div>
                </section>

                <div id="services" class="crmDetails pt-16">
                    <div class="bck1d"></div>
                    <section class="presentComp mt-8">
                        <div class="d-flex pr-3 pl-4 pt-8">
                            <div>
                                <v-img
                                    class="imgSelectionTI"
                                    src="https://objectif-crm.com/assets/images/servicesNew.png"
                                    height="75"
                                    width="75"
                                    cover
                                ></v-img>
                            </div>
                            <div class="titleVCard">
                                <div class="ocStrong">{{ $t('message.applicationServices') }}</div>
                                <div>{{ $t('message.function') }}</div>
                            </div>

                        </div>
                    </section>
                    <section class="sectionCRM mt-3 pb-cus-150">
                        <div class="row" style="background-color: transparent !important;">
                            <div class="col-12 col-sm-6 h-100" style="margin-top: auto; margin-bottom: auto; background-color: transparent !important;">
                                <div class="d-flex justify-content-center align-items-center" style="background-color: transparent !important;">
                                    <div class="mt-5 v-card v-card--flat v-sheet theme--light" style="background-color: transparent !important;">
                                        <div class="v-card__text vCardTxt">{{ $t('message.services') }}</div>
                                        <h4>{{ $t('message.allInOne') }}</h4>
                                        <p class="descripH1 mb-0">
                                            {{ $t('message.servicesDescription') }}
                                        </p>
                                        <div class="v-card__actions infoSpecial VCA p-0 mt-2">
                                            <v-btn @click="navigateToRefresh('services')" text light large style="margin-left: -8px;">
                                                {{ $t('Services') }}
                                                <v-icon small class="ml-2">fa-solid fa-right-long</v-icon>
                                            </v-btn>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 d-flex align-items-center">
                                <div class="d-flex align-items-center justify-content-center w-100 h-100"><img src="https://objectif-crm.com/assets/images/serviceFade.gif" class="imageTextRight" style="max-width: 459px;"></div>
                            </div>
                        </div>
                    </section>
                </div>

                <hr role="separator" aria-orientation="horizontal" class="v-divider theme--light">

                <div id="application" class="crmDetails pt-16">
                    <section class="presentComp mt-8">
                        <div class="d-flex pr-3 pl-4 pt-8">
                            <div>
                                <v-img
                                    class="imgSelectionTI"
                                    height="75"
                                    width="75"
                                    cover
                                    src="https://objectif-crm.com/assets/images/Utilisateurs.png"
                                ></v-img>
                            </div>
                            <div class="titleVCard">
                                <div class="ocStrong">{{ $t('message.appAndPlugins') }}</div>
                                <div>{{ $t('message.connectivity') }}</div>
                            </div>
                        </div>
                    </section>
                    <section class="sectionMarketing mt-8 pb-cus-150">
                        <div class="row">
                            <div class="col-12 col-sm-6 d-flex align-items-center">
                                <div class="d-flex align-items-center justify-content-center w-100 h-100"><img
                                    src="https://objectif-crm.com/assets/images/plugin.gif"
                                    class="imageTextRight" style="max-width: 459px;"></div>
                            </div>
                            <div class="col-12 col-sm-6 h-100"
                                 style="margin-top: auto; margin-bottom: auto;">
                                <div class="d-flex justify-content-center align-items-center">
                                    <div class="mt-5 v-card v-card--flat v-sheet theme--light"
                                         style="background-color: transparent !important;">
                                        <div class="v-card__text vCardTxt">{{ $t('message.integration') }}</div>
                                        <h4>{{ $t('message.effectiveCommunication') }}</h4>
                                        <p class="descripH1 mb-0">{{ $t('message.integrationDescription') }}</p>
                                        <div class="my-3"><i>{{ $t('message.moreThan100Plugins') }}</i></div>
                                        <div class="v-card__actions infoSpecial VCA p-0">
                                            <v-btn @click="navigateToRefresh('integrations')" text light large style="margin-left: -8px;">
                                                {{ $t('message.discoverIntegrations') }}
                                                <v-icon small class="ml-2">fa-solid fa-right-long</v-icon>
                                            </v-btn>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <hr role="separator" aria-orientation="horizontal" class="v-divider theme--light">

                <div id="collaborateur" class="crmDetails pt-16">
                    <section class="presentComp mt-8">
                        <div class="d-flex pr-3 pl-4 pt-8">
                            <div>
                                <v-img
                                    class="imgSelectionTI"
                                    height="75"
                                    width="75"
                                    cover
                                    src="https://objectif-crm.com/assets/images/Utilisateurs.png"
                                ></v-img>
                            </div>
                            <div class="titleVCard">
                                <div class="ocStrong">{{ $t('message.collaboratorManagement') }}</div>
                                <div>{{ $t('message.optimization') }}</div>
                            </div>
                        </div>
                    </section>
                    <section class="sectionCRM mt-15 pb-cus-150">
                        <div class="row" style="background-color: transparent !important;">
                            <div class="col-12 col-sm-6 h-100"
                                 style="margin-top: auto; margin-bottom: auto; background-color: transparent !important;">
                                <div class="d-flex justify-content-center align-items-center"
                                     style="background-color: transparent !important;">
                                    <div class="mt-5 v-card v-card--flat v-sheet theme--light"
                                         style="background-color: transparent !important;">
                                        <div class="v-card__text vCardTxt">{{ $t('message.collaboration') }}</div>
                                        <h4>{{ $t('message.collaborateToSucceed') }}</h4>
                                        <p class="descripH1 mb-0">{{ $t('message.collaborationDescription') }}</p>
<!--                                        <div class="v-card__actions infoSpecial VCA p-0">-->
<!--                                            <button type="button" class="v-btn v-btn&#45;&#45;text theme&#45;&#45;light v-size&#45;&#45;default"-->
<!--                                                    style="margin-left: -8px;"><span class="v-btn__content">{{ $t('message.discoverTools') }}<i class="ml-2 fa-solid fa-right-long"></i></span>-->
<!--                                            </button>-->
<!--                                        </div>-->
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 d-flex align-items-center">
                                <div class="d-flex align-items-center justify-content-center w-100 h-100"><img
                                    src="https://objectif-crm.com/assets/images/colaboration.gif"
                                    class="imageTextRight" style="max-width: 459px;"></div>
                            </div>
                        </div>
                    </section>
                </div>

                <hr role="separator" aria-orientation="horizontal" class="v-divider theme--light">

                <div id="projets" class="crmDetails pt-16">
                    <section class="presentComp mt-8">
                        <div class="d-flex pr-3 pl-4 pt-8">
                            <div>
                                <v-img
                                    class="v-responsive imgSelectionTI theme--light"
                                    height="75"
                                    width="75"
                                    cover
                                    src="https://objectif-crm.com/assets/images/projets.png"
                                ></v-img>
                            </div>
                            <div class="titleVCard">
                                <div class="ocStrong">{{ $t('message.projectManagement') }}</div>
                                <div>{{ $t('message.dynamism') }}</div>
                            </div>
                        </div>
                    </section>
                    <section class="sectionIntegration mt-15 pb-cus-150">
                        <div class="row">
                            <div class="col-12 col-sm-6 d-flex align-items-center">
                                <div class="d-flex align-items-center justify-content-center w-100 h-100"><img
                                    src="https://www.objectif-crm.com/assets/images/project.gif"
                                    class="imageTextRight" style="max-width: 459px;"></div>
                            </div>
                            <div class="col-12 col-sm-6 h-100"
                                 style="margin-top: auto; margin-bottom: auto;">
                                <div class="d-flex justify-content-center align-items-center">
                                    <div class="mt-5 v-card v-card--flat v-sheet theme--light">
                                        <h4>{{ $t('message.customizableToMatch') }}</h4>
                                        <p class="descripH1 mb-0">{{ $t('message.customizationDescription') }}</p>
<!--                                        <div class="v-card__actions infoSpecial VCA p-0">-->
<!--                                            <button type="button" class="v-btn v-btn&#45;&#45;text theme&#45;&#45;light v-size&#45;&#45;default" style="margin-left: -8px;"><span class="v-btn__content">{{ $t('message.discoverProcessData') }}<i class="ml-2 fa-solid fa-right-long"></i></span></button>-->
<!--                                        </div>-->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <hr role="separator" aria-orientation="horizontal" class="v-divider theme--light">
            </div>
        </div>
        <div v-show="!loaded" style="height: 100vh; top: 0;right: 0;left: 0 ; bottom: 0 ;z-index: 999 ; background-color: white" class="position-fixed" >
            <div class="d-flex justify-content-center align-items-center h-100">
                <div class="d-inline-block" style="height: 85px; width: 85px">
                    <v-img class="iconSpinerTurn" height="78px" width="78px" cover src="https://objectif-crm.com/assets/images/abeil.gif"></v-img>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "toolsApp",
    data(){
        return{
            loaded:false,
        }
    },
    created() {
        setTimeout(() => {
            console.log();
            this.loaded=true
        },"1500")
    },
    methods: {
        navigateTo(routeName) {
            if (this.$route.name !== routeName) {
                this.$router.push({name: routeName});
            }
        },
        navigateToRefresh(routeName) {
            if (this.$route.name !== routeName) {
                //  this.$router.push({name: routeName});
                window.location = routeName;
            }
        },
    }
}
</script>

<style scoped>
.icon-spinner {
    animation: spin-animation 0.75s infinite;
    display: inline-block;
}
.iconSpinerTurn .v-image__image {
    height: 85px;
    width: 85px;
}
@keyframes spin-animation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}
</style>