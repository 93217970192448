<template>
    <div class="pageStructure">
        <div class="allSection">
            <section class="sectionGauche pt-9 pb-15"
                     style="background: linear-gradient(rgba(143, 1, 196, 0.36) 0px, rgba(143, 1, 196, 0.01));">
                <div class="container">
                    <div class="my-7">
                        <h4 class="text-center w-100">
                            <i class="far fa-eye" aria-hidden="true"></i> {{ $t('message.views') }}
                        </h4>
                        <p class="d-inline-block w-100 text-center" style="font-size: 24px;"></p>
                    </div>
                </div>
            </section>
            <section style="background-color: rgba(241, 241, 241, 0.14);">
                <p class="d-inline-block w-100 text-center" style="font-size: 24px;">{{ $t('message.kanban') }}</p>
                <div class="container">
                    <div class="compInfoplus">
                        <div class="pt-16 text-center">
                            <div style="border: 1px solid rgb(204, 204, 204); border-radius: 10px; padding: 8px; background-color: white; display: inline-block;">
                                <v-img class="v-responsive theme--light imgVue" cover src="https://www.objectif-crm.com/assets/images/KanvaVue.png"></v-img>
                            </div>
                            <div class="text-left mt-10">
                                <h5>{{ $t('message.advantages_of_kanban') }}</h5>
                                <ol>
                                    <li>
                                        <h6>{{ $t('message.task_visualization') }}</h6>
                                        <p>{{ $t('message.task_visualization_description') }}</p>
                                    </li>
                                    <li>
                                        <h6>{{ $t('message.productivity_optimization') }}</h6>
                                        <p>{{ $t('message.productivity_optimization_description') }}</p>
                                    </li>
                                    <li>
                                        <h6>{{ $t('message.theFlexibility') }}</h6>
                                        <p>{{ $t('message.flexibility_description') }}</p>
                                    </li>
                                </ol>
                            </div>
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <div class="d-flex cardColor">
                                        <img src="https://www.objectif-crm.com/assets/images/dragDrop.png" class="imgKanbani">
                                        <div class="ml-5 text-left">
                                            <h4 class="h4Cus">{{ $t('message.drag_and_drop') }}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="d-flex cardColor">
                                        <img src="https://www.objectif-crm.com/assets/images/custoMade.png" class="imgKanbani"/>
                                        <div class="ml-5 text-left">
                                            <h4 class="h4Cus">{{ $t('message.customizable_card') }}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr role="separator" aria-orientation="horizontal" class="v-divider theme--light">
            </section>
            <section>
                <div class="my-7">
                    <p class="d-inline-block w-100 text-center" style="font-size: 24px;">{{ $t('message.datatable') }}</p>
                </div>
            </section>
            <section style="background-color: rgba(241, 241, 241, 0.32);">
                <hr role="separator" aria-orientation="horizontal" class="v-divider theme--light">
                <div class="container">
                    <div class="row compInfoplus">
                        <div class="col-12 pt-16 text-center">
                            <div style="border: 1px solid rgb(204, 204, 204); border-radius: 10px; padding: 8px; background-color: white; display: inline-block;">
                                <v-img class="v-responsive theme--light imgVue" cover src="https://www.objectif-crm.com/assets/images/dataTable.PNG"></v-img>
                            </div>
                            <div class="text-left mt-10">
                                <h5>{{ $t('message.advantages_datatable') }}</h5>
                                <ol>
                                    <li>
                                        <h6>{{ $t('message.advantage_1') }}</h6>
                                        <p>{{ $t('message.advantage_1_desc') }}</p>
                                    </li>
                                    <li>
                                        <h6>{{ $t('message.advantage_2') }}</h6>
                                        <p>{{ $t('message.advantage_2_desc') }}</p>
                                    </li>
                                    <li>
                                        <h6>{{ $t('message.advantage_3') }}</h6>
                                        <p>{{ $t('message.advantage_3_desc') }}</p>
                                    </li>
                                </ol>
                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <div class="d-flex cardColor">
                                            <img src="https://www.objectif-crm.com/assets/images/dragDrop.png" class="imgKanbani">
                                            <div class="ml-5 text-left">
                                                <h4 class="h4Cus">{{ $t('message.feature_1') }}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <div class="d-flex cardColor">
                                            <img src="https://www.objectif-crm.com/assets/images/custoMade.png" class="imgKanbani">
                                            <div class="ml-5 text-left">
                                                <h4 class="h4Cus">{{ $t('message.feature_2') }}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr role="separator" aria-orientation="horizontal" class="v-divider theme--light">
            </section>
            <section>
                <div class="my-7">
                    <p class="d-inline-block w-100 text-center" style="font-size: 24px;">{{ $t('message.calendar') }}</p>
                </div>
            </section>
            <section style="background-color: rgba(241, 241, 241, 0.32);">
                <hr role="separator" aria-orientation="horizontal" class="v-divider theme--light">
                <div class="container">
                    <div class="row compInfoplus">
                        <div class="col-12 pt-16 text-center">
                            <div style="border: 1px solid rgb(204, 204, 204); border-radius: 10px; padding: 8px; background-color: white; display: inline-block;">
                                <v-img class="imgVue" cover :src="$t('message.imageURL')"></v-img>
                            </div>
                            <div class="text-left mt-10">
                                <h5>{{ $t('message.advantages_calendar') }}</h5>
                                <ol>
                                    <li>
                                        <h6>{{ $t('message.advantage_cal1') }}</h6>
                                        <p>{{ $t('message.advantage_cal1_desc') }}</p>
                                    </li>
                                    <li>
                                        <h6>{{ $t('message.advantage_cal2') }}</h6>
                                        <p>{{ $t('message.advantage_cal2_desc') }}</p>
                                    </li>
                                    <li>
                                        <h6>{{ $t('message.advantage_cal3') }}</h6>
                                        <p>{{ $t('message.advantage_cal3_desc') }}</p>
                                    </li>
                                </ol>
                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <div class="d-flex cardColor">
                                            <img src="https://www.objectif-crm.com/assets/images/dragDrop.png" class="imgKanbani">
                                            <div class="ml-5 text-left">
                                                <h4 class="h4Cus">{{ $t('message.feature_1') }}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <div class="d-flex cardColor">
                                            <img src="https://www.objectif-crm.com/assets/images/custoMade.png" class="imgKanbani">
                                            <div class="ml-5 text-left">
                                                <h4 class="h4Cus">{{ $t('message.feature_2') }}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr role="separator" aria-orientation="horizontal" class="v-divider theme--light">
            </section>
            <div class="container">
                <pub-bleu/>
            </div>
        </div>
    </div>

</template>

<script>
import PubBleu from "@/components/micro/pubBleu.vue";

export default {
    name: "vuesApp",
    components: {PubBleu}
}
</script>

<style scoped>

</style>