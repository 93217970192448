<template>
    <div class="pageStructure">
        <div class="container">
            <div class="allSection">
                <why-choose/>
                <allInOne/>
                <table-compare/>
                <pubBleu/>
            </div>
        </div>
    </div>
</template>

<script>
import pubBleu from "@/components/micro/pubBleu.vue";
import allInOne from "@/components/micro/allInOne.vue";
import WhyChoose from "@/components/micro/whyChoose.vue";
import TableCompare from "@/components/micro/tableCompare.vue";

export default {
    name: "beTool",
    components: {TableCompare, WhyChoose, allInOne, pubBleu}
}
</script>

<style scoped>

</style>