<template>
    <div class="d-none">
        <section class="sectionPricing mt-8 pb-cus-150">
            <h4 class="text-center m-0 w-100 mb-15" style="max-width: 100% !important;">Obtenir plus au meilleur prix</h4>
            <div class="tablePrice">
                <div class="v-data-table theme--light">
                    <div class="v-data-table__wrapper">
                        <table>
                            <thead>
                            <tr>
                                <th class="text-left w-25 textTable">Ce graphique représente
                                    une liste partielle des fonctionnalités disponibles dans signNow, DocuSign,
                                    Adobe Sign, et HelloSign.
                                </th>
                                <th class="thParam text-center BgColorOc leTitleH">beTool
                                </th>
                                <th class="thParam text-center">Monday</th>
                                <th class="thParam text-center">Sales Force</th>
                                <th class="thParam text-center">Zoho</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td class="thParamP">Gerer la comptabilite de(s)
                                    entreprise(s)
                                </td>
                                <td class="BgColorOc">x</td>
                                <td class="text-center">x</td>
                                <td class="text-center">x</td>
                                <td class="text-center">x</td>
                            </tr>
                            <tr>
                                <td class="thParamP">Marketing</td>
                                <td class="BgColorOc">x</td>
                                <td class="text-center">x</td>
                                <td class="text-center">x</td>
                                <td class="text-center">x</td>
                            </tr>
                            <tr>
                                <td class="thParamP">Email</td>
                                <td class="BgColorOc">x</td>
                                <td class="text-center">x</td>
                                <td class="text-center">x</td>
                                <td class="text-center">x</td>
                            </tr>
                            <tr>
                                <td class="thParamP">SMS</td>
                                <td class="BgColorOc">x</td>
                                <td class="text-center">x</td>
                                <td class="text-center">x</td>
                                <td class="text-center">x</td>
                            </tr>
                            <tr>
                                <td class="thParamP">Comptabilite</td>
                                <td class="BgColorOc">x</td>
                                <td class="text-center">x</td>
                                <td class="text-center">x</td>
                                <td class="text-center">x</td>
                            </tr>
                            <tr>
                                <td class="thParamP">Marketing</td>
                                <td class="BgColorOc">x</td>
                                <td class="text-center">x</td>
                                <td class="text-center">x</td>
                                <td class="text-center">x</td>
                            </tr>
                            <tr>
                                <td class="thParamP">Email</td>
                                <td class="BgColorOc">x</td>
                                <td class="text-center">x</td>
                                <td class="text-center">x</td>
                                <td class="text-center">x</td>
                            </tr>
                            <tr>
                                <td class="thParamP">SMS</td>
                                <td class="BgColorOc">x</td>
                                <td class="text-center">x</td>
                                <td class="text-center">x</td>
                                <td class="text-center">x</td>
                            </tr>
                            <tr>
                                <td class="thParamP">Email</td>
                                <td class="BgColorOc">x</td>
                                <td class="text-center">x</td>
                                <td class="text-center">x</td>
                                <td class="text-center">x</td>
                            </tr>
                            <tr>
                                <td class="impTable thParamPP">Prix du forfait par user</td>
                                <td class="BgColorOc priTable">20 <span
                                    class="dolTable"><i
                                    class="fa-solid fa-dollar-sign"></i></span></td>
                                <td class="text-center priTable">30 <span
                                    class="dolTable"><i
                                    class="fa-solid fa-dollar-sign"></i></span></td>
                                <td class="text-center priTable">40 <span
                                    class="dolTable"><i
                                    class="fa-solid fa-dollar-sign"></i></span></td>
                                <td class="text-center priTable">50 <span
                                    class="dolTable"><i
                                    class="fa-solid fa-dollar-sign"></i></span></td>
                            </tr>
                            <tr class="noHover">
                                <td></td>
                                <td class="BgColorOc leTitleB">
                                    <v-btn text light href="https://application.betool.fr/register?acces=freetrial">Commencez<br>l'essai gratuit</v-btn>

                                </td>
                                <td class="text-center"></td>
                                <td class="text-center"></td>
                                <td class="text-center"></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    </div>

</template>

<script>
export default {
    name: "tableCompare"
}
</script>

<style scoped>

</style>