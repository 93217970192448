import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import '@fortawesome/fontawesome-free/css/all.css'
import '../src/assets/custom.css'
import '../src/assets/customMobile.css' // Correction du nom du fichier
import router from "./router/index";
import 'bootstrap/dist/css/bootstrap.css';
import VueI18n  from 'vue-i18n';
import messagesEn from './translator/en.js'
import messagesFr from './translator/fr.js'

Vue.use(VueI18n);

Vue.config.productionTip = false
const messages = {
  en: messagesEn,
  fr: messagesFr
}

const lang = localStorage.getItem('lang');

// Configurer la langue par défaut en utilisant la valeur stockée ou la valeur par défaut si elle n'est pas présente dans le Local Storage
const defaultLocale = lang || 'fr';

// Configurer VueI18n avec la langue par défaut
const i18n = new VueI18n({
  locale: defaultLocale,
  messages,
});

new Vue({
  i18n,
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app');