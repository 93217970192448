<template>
    <div id="integrations" class="pageStructure">
        <div class="allSection">
            <section class="sectionGauche pt-9 pb-15"
                     style="background: linear-gradient(rgba(29, 131, 211, 0.38) 0px, rgba(29, 131, 211, 0));">
                <div class="container">
                    <div class="my-7"><h4 class="text-center w-100"><i
                        class="fas fa-building titleIcon mr-2" aria-hidden="true"></i> {{ $t('message.about_us') }}</h4></div>
                </div>
            </section>
            <section id="Introduction" class="bb-section">
                <div class="container">
                    <div>
                        <h1 class="title-xl mb-lg mt-3">
                            <div class="item-line"
                                 style="display: block; text-align: left; position: relative;">
                                <div
                                    style="position: relative; display: inline-block; transform: matrix(1, 0, 0, 1, 0, 0);">
                                    {{ $t('message.introduction_title') }}
                                </div>
                            </div>
                        </h1>
                        <p>{{ $t('message.introduction_text') }}</p>
                    </div>
                </div>
            </section>
            <section id="Histoire" class="bb-section">
                <div class="container">
                    <div>
                        <h1 class="title-xl mb-lg mt-3">
                            <div class="item-line" style="display: block; text-align: left; position: relative;">
                                <div style="position: relative; display: inline-block; transform: matrix(1, 0, 0, 1, 0, 0);">
                                     {{ $t('message.history_title') }}
                                </div>
                            </div>
                        </h1>
                        <p v-html="$t('message.history_text')"></p>
                    </div>
                </div>
            </section>
            <section id="Team" class="text-center bb-section">
                <div class="container">
                    <h1 class="title-xl mb-lg mt-3">
                        <div class="item-line"
                             style="display: block; text-align: left; position: relative;">
                            <div style="position: relative; display: inline-block; transform: matrix(1, 0, 0, 1, 0, 0);">
                                {{ $t('message.team_title') }}
                            </div>
                        </div>
                    </h1>
                    <div class="row">
                        <div data-zanim-trigger="scroll" class="col mb-4">
                            <div class="overflow-hidden"><h2 class="fs-md-5">{{ $t('message.administration_title') }}</h2></div>
                            <div class="overflow-hidden">
                                <hr class="hr-short border-black">
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-lg-10">
                            <div class="row">
                                <div v-for="teamMember in teamMembers" :key="teamMember.name" class="col-6 col-md-3 col-lg-3 px-2 team-item mb-4">
                                    <div class="hoverbox w-75 rounded-circle overflow-hidden mx-auto">
                                        <img :src="teamMember.image" alt="" class="img-fluid">
                                        <div class="hoverbox-content">
                                            <div class="d-flex align-items-end justify-content-center h-100">
                                                <ul class="list-inline team-item-social-icons mt-2 px-0">
                                                    <li class="list-inline-item">
                                                        <a v-if="teamMember.linkedin" :href="teamMember.linkedin"  style="color: #0770cf!important;">
                                                            <i class="grow-3 fab fa-linkedin-in"></i>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <h6 class="font-weight-bold mt-2 mb-1">
                                        <a href="#!" class="text-black">{{ teamMember.name }}</a>
                                    </h6>
                                    <h6 class="ls-0">{{ teamMember.role }}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col mb-4">
                            <div class="overflow-hidden"><h2 class="fs-md-5">{{ $t('message.developers_title') }}</h2></div>
                            <div class="overflow-hidden">
                                <hr class="hr-short border-black">
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-lg-10">
                            <div class="row">
                                <div v-for="developer in developers" :key="developer.name" class="col-6 col-md-3 col-lg-3 px-2 team-item mb-4 mb-lg-0">
                                    <div class="hoverbox w-75 rounded-circle overflow-hidden mx-auto">
                                        <img :src="developer.image" alt="" class="img-fluid">
                                        <div class="hoverbox-content">
                                            <div class="d-flex align-items-end justify-content-center h-100">
                                                <ul class="list-inline team-item-social-icons mt-2 px-0">
                                                    <li class="list-inline-item" v-for="link in developer.links" :key="link.url"  style="color: #0770cf!important;">
                                                        <a v-if="link.url" :href="link.url" ><i :class="link.icon" class="fab" aria-hidden="true"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <h6 class="font-weight-bold mt-2 mb-1">
                                        <span href="#!" class="text-black">{{ developer.name }}</span>
                                    </h6>
                                    <h6 class="ls-0">{{ developer.role }}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div data-zanim-timeline="{}" data-zanim-trigger="scroll" class="col mb-4">
                            <div class="overflow-hidden">
                                <h2 class="fs-md-5">Relation client</h2>
                            </div>
                            <div class="overflow-hidden">
                                <hr class="hr-short border-black">
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-lg-10">
                            <div class="row">
                                <div v-for="member in teamMembersOther" :key="member.name" class="col-6 col-md-3 col-lg-3 px-2 team-item mb-4 mb-lg-0">
                                    <div class="hoverbox w-75 rounded-circle overflow-hidden mx-auto">
                                        <img :src="member.image" alt="" class="img-fluid">
                                        <div class="hoverbox-content">
                                            <div class="d-flex align-items-end justify-content-center h-100">
                                                <ul class="list-inline team-item-social-icons mt-2 px-0">
                                                    <li v-for="(link, index) in member.socialLinks" :key="index" class="list-inline-item">
                                                        <a v-if="link.url" :href="link.url"  style="color: #0770cf!important;">
                                                            <i  :data-fa-transform="link.transform" :class="link.icon" aria-hidden="true"></i>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <h6 class="font-weight-bold mt-2 mb-1">
                                        <a href="#!" class="text-black">{{ member.name }}</a>
                                    </h6>
                                    <h6 class="ls-0">{{ member.role }}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="valeur" class="bb-section">
                <div class="container">
                    <div class="valeurAll">
                        <h1 class="title-xl mb-lg mt-3">
                            <div class="item-line" style="display: block; text-align: left; position: relative;">
                                <div style="position: relative; display: inline-block; transform: matrix(1, 0, 0, 1, 0, 0);">
                                    {{ $t('message.values') }}
                                </div>
                            </div>
                        </h1>
                        <div class="row text-center mt-9">
                            <div class="col-12 col-md-6">
                                <div class="valeurCube">
                                    <h6>{{ $t('message.innovationCreativity') }}</h6>
                                    <p>{{ $t('message.innovationCreativityDescription') }}</p>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="valeurCube">
                                    <h6>{{ $t('message.transparencyIntegrity') }}</h6>
                                    <p>{{ $t('message.transparencyIntegrityDescription') }}</p>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="valeurCube">
                                    <h6>{{ $t('message.connectivity') }}</h6>
                                    <p>{{ $t('message.connectivityDescription') }}</p>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="valeurCube">
                                    <h6>{{ $t('message.excellence') }}</h6>
                                    <p>{{ $t('message.excellenceDescription') }}</p>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="valeurCube">
                                    <h6>{{ $t('message.sustainability') }}</h6>
                                    <p>{{ $t('message.sustainabilityDescription') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="produit" class="bb-section">
                <div class="container">
                    <div class="produitAll">
                        <h1 class="title-xl mb-lg mt-3">
                            <div class="item-line" style="display: block; text-align: left; position: relative;">
                                <div style="position: relative; display: inline-block; transform: matrix(1, 0, 0, 1, 0, 0);">
                                    {{ $t('message.produits_services') }}
                                </div>
                            </div>
                        </h1>
                        <div class="row text-center mt-9">
                            <div class="col-12 col-md-3">
                                <div class="produitCube">
                                    <div><i class="fal fa-table" aria-hidden="true" style="color: orangered;"></i></div>
                                    <h6>{{ $t('message.produit_betooll') }}</h6>
                                    <p>{{ $t('message.produit_betooll_description') }}</p>
                                </div>
                            </div>
                            <div class="col-12 col-md-3">
                                <div class="produitCube">
                                    <div><i class="fad fa-shapes" aria-hidden="true" style="color: rgb(113, 30, 246);"></i></div>
                                    <h6>{{ $t('message.flexibilite') }}</h6>
                                    <p>{{ $t('message.flexibilite_description') }}</p>
                                </div>
                            </div>
                            <div class="col-12 col-md-3">
                                <div class="produitCube">
                                    <div><i class="fas fa-chart-network" aria-hidden="true" style="color: rgb(0, 175, 123);"></i></div>
                                    <h6>{{ $t('message.connectivite') }}</h6>
                                    <p>{{ $t('message.connectivite_description') }}</p>
                                </div>
                            </div>
                            <div class="col-12 col-md-3">
                                <div class="produitCube">
                                    <div><i class="far fa-expand-wide" aria-hidden="true" style="color: rgb(26, 61, 203);"></i></div>
                                    <h6>{{ $t('message.scalabilite') }}</h6>
                                    <p>{{ $t('message.scalabilite_description') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="support" class="bb-section mt-8 mb-10" style="background-color: rgba(239, 220, 255, 0.25);">
                <div class="container">
                    <div class="produitAll">
                        <h1 class="title-xl mb-lg mt-3">
                            <div class="item-line" style="display: block; text-align: left; position: relative;">
                                <div style="position: relative; display: inline-block; transform: matrix(1, 0, 0, 1, 0, 0);">
                                    {{ $t('message.support_commercial_technique') }}
                                </div>
                            </div>
                        </h1>
                        <div class="row mt-9">
                            <div class="col-12">
                                <div class="supportCube d-flex">
                                    <div class="imgContentAU"><img src="https://www.objectif-crm.com/assets/images/supportTech.png" class="imgAboutUs" style="padding: 10px;"></div>
                                    <div>
                                        <h6 class="m-0 p-0 my-4">{{ $t('message.service_client_qualite') }}</h6>
                                        <p>{{ $t('message.service_client_qualite_description') }}</p>
                                    </div>
                                </div>
                                <div class="supportCube d-flex">
                                    <div class="imgContentAU"><img src="https://www.objectif-crm.com/assets/images/Commercial.png" class="imgAboutUs"></div>
                                    <div>
                                        <h6 class="m-0 p-0 my-4">{{ $t('message.service_commercial') }}</h6>
                                        <p>{{ $t('message.service_commercial_description') }}</p>
                                    </div>
                                </div>
                                <div class="supportCube d-flex">
                                    <div class="imgContentAU"><img src="https://www.objectif-crm.com/assets/images/consultation.png" class="imgAboutUs"></div>
                                    <div>
                                        <h6 class="m-0 p-0 my-4">{{ $t('message.consultation_gratuite') }}</h6>
                                        <p>{{ $t('message.consultation_gratuite_description') }}</p>
                                    </div>
                                </div>
                                <div class="supportCube d-flex">
                                    <div class="imgContentAU"><img src="https://www.objectif-crm.com/assets/images/Formation.png" class="imgAboutUs"></div>
                                    <div>
                                        <h6 class="m-0 p-0 my-4">{{ $t('message.formation_support') }}</h6>
                                        <p>{{ $t('message.formation_support_description') }}</p>
                                    </div>
                                </div>
                                <div class="supportCube d-flex" style="border-bottom: none;">
                                    <div class="imgContentAU"><img src="https://www.objectif-crm.com/assets/images/Partenaire.png" class="imgAboutUs"></div>
                                    <div>
                                        <h6 class="m-0 p-0 my-4">{{ $t('message.partenariats_commerciaux') }}</h6>
                                        <p>{{ $t('message.partenariats_commerciaux_description') }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
export default {
    name: "aboutUs",
    data(){
        return{
            teamMembers: [
                {
                    name: 'GABAY Joseph',
                    role: 'CEO & Foundateur',
                    image: 'https://www.techniciansoftsolution.com/assets/img/team/josephgabay.jpeg',
                    linkedin: 'https://www.linkedin.com/in/joseph-gabay-tss',
                },
                {
                    name: 'Yossef Hai SHARVIT',
                    role: 'CFO',
                    image: 'https://www.objectif-crm.com/assets/images/yossefHai.jpeg',
                    linkedin: 'https://www.linkedin.com/in/yossef-hai-sharvit-b4511226a/',
                },
                {
                    name: 'Jennifer SKOURI',
                    role: 'Directrice adminisrative',
                    image: 'https://www.objectif-crm.com/assets/images/jennifer.jpeg',
                    linkedin: '',
                },
                {
                    name: 'Salome SULTAN',
                    role: 'Design',
                    image: 'https://www.techniciansoftsolution.com/assets/img/team/salome.jpeg',
                    linkedin: '',
                },
                // ... more team members here
            ],
            developers: [
                {
                    name: 'Jonathan KALFA',
                    role: 'CTO',
                    image: 'https://www.techniciansoftsolution.com/assets/img/team/jonathank.jpeg',
                    links: [
                        {
                            url: 'https://www.linkedin.com/in/jonathan-kalfa-430212a8',
                            icon: 'grow-3 fab fa-linkedin-in'
                        },
                    ]
                },
                {
                    name: 'AVI COHEN',
                    role: 'Full Stack Developer',
                    image: 'https://www.techniciansoftsolution.com/assets/img/team/avicohen.jpg',
                    links: [
                        {
                            url: 'https://www.linkedin.com/in/avi-cohen-0442a6182/',
                            icon: 'grow-3 fab fa-linkedin-in'
                        },
                    ]
                },
                {
                    name: 'Rahel POLAK',
                    role: 'Developer',
                    image: 'https://www.techniciansoftsolution.com/assets/img/team/7.jpg',
                    links: [
                        {
                            url: 'https://www.linkedin.com/in/rachel-polack-758483194/',
                            icon: 'grow-3 fab fa-linkedin-in'
                        },
                    ]
                },
                {
                    name: 'Sarah MONSENEGO',
                    role: 'Software Engineer',
                    image: 'https://www.techniciansoftsolution.com/assets/img/team/10.jpg',
                    links: [
                        {
                            url: 'https://www.linkedin.com/',
                            icon: 'grow-3 fab fa-linkedin-in'
                        },
                    ]
                },
                // ... more developers here
            ],
            teamMembersOther: [
                {
                    name: 'Samuel BOUKRIS',
                    role: 'Account manager',
                    image: 'https://www.techniciansoftsolution.com/assets/img/team/samuelb.jpeg',
                    socialLinks: [
                        {
                            url: 'https://www.linkedin.com/in/samuel-boukris-58a60b181/',
                            icon: 'fab fa-linkedin-in',
                            transform: 'grow-3'
                        },
                        // {
                        //     url: '#!',
                        //     icon: 'fab fa-instagram',
                        //     transform: 'grow-3'
                        // }
                    ]
                },
                {
                    name: 'Eithan BITTON',
                    role: 'Account manager',
                    image: 'https://www.techniciansoftsolution.com/assets/img/team/eitanb.jpeg',
                    socialLinks: [
                        {
                            url: 'https://www.linkedin.com/',
                            icon: 'fab fa-linkedin-in',
                            transform: 'grow-3'
                        }
                    ]
                },
                {
                    name: 'Michael COHEN',
                    role: 'Directeur support',
                    image: 'https://www.techniciansoftsolution.com/assets/img/team/michaelc.jpeg',
                    socialLinks: [
                        {
                            url: 'https://www.linkedin.com/in/michael-cohen-7b1499246',
                            icon: 'fab fa-linkedin-in',
                            transform: 'grow-3'
                        },
                        // {
                        //     url: '#!',
                        //     icon: 'fab fa-instagram',
                        //     transform: 'grow-3'
                        // }
                    ]
                },
                // ... Other team members here ...
            ]
        }
    }
}
</script>

<style scoped>

</style>