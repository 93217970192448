<template>
    <section class="sectionPub mt-8 pb-15">
        <div class="mr-2 w-100">
            <div class="text-center actionSection v-card v-card--flat v-sheet theme--light">
                <h4>{{ $t('message.sectionPubTitle') }}</h4>
                <div class="py-3">
                    <p class="mb-0 descripH1" style="max-width: 100%;">{{ $t('message.sectionPubText') }}</p>
                </div>
                <div class="v-card__actions infoSpecial VCA justify-content-center">
                    <v-btn outlined light href="https://application.betool.fr/register?acces=freetrial">
                        {{ $t('message.sectionPubButton') }}
                        <v-icon right>fas fa-arrow-right</v-icon>
                    </v-btn>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "sectionPub"
}
</script>

<style scoped>

</style>