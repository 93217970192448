<template>
  <div class="pageStructure">
    <div class="container">
      <div id="documentationApi">
        <h1 class="text-center mt-8">{{ $t('message.api_documentation_title') }}</h1>
        
        <div class="d-flex" style="height: calc(100vh - 200px)">
          <v-card flat height="100%" width="256">
            <v-navigation-drawer permanent>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="text-h6">API</v-list-item-title>
                  <v-list-item-subtitle>Documentation</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list dense nav>
                <v-list-item-subtitle class="mb-3">
                  {{ $t('message.endpoints') }}
                </v-list-item-subtitle>
                
                <v-list-item link>
                  <v-list-item-icon style="width: 30px; align-items: center; justify-content: center">
                    <v-icon>fa-regular fa-people-group</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ $t('message.users') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item link>
                  <v-list-item-icon style="width: 30px; align-items: center; justify-content: center">
                    <v-icon>fa-solid fa-icons</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ $t('message.processes') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-navigation-drawer>
          </v-card>

          <div class="w-100 h-100">
            <div class="d-flex justify-content-center align-items-center h-100 w-100" style="box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); border-radius: 4px;">
              <div class="text-center">
                <div style="font-size: 45px">🔜</div>
                <div>{{ $t('message.coming_soon') }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "documentationApi"
}
</script>

<style scoped>
</style>