<template>
    <div>
        <section id="DMCA"  style="padding-top: 120px">
            <div class="container">
                <h1>{{ $t('message.dmcaPolicyTitle') }}</h1>
                <p class="text-center mb-15">{{ $t('message.dmcaPolicyEffectiveDate') }}</p>
                <p>{{ $t('message.dmcaPolicyDescription1') }}</p>
                <p>{{ $t('message.dmcaPolicyDescription2') }}</p>

                <ul>
                    <li>{{ $t('message.dmcaPolicyDescription3') }}</li>
                    <li>{{ $t('message.dmcaPolicyDescription4') }}</li>
                    <li>{{ $t('message.dmcaPolicyDescription5') }}</li>
                    <li>{{ $t('message.dmcaPolicyDescription6') }}</li>
                    <li>{{ $t('message.dmcaPolicyDescription7') }}</li>
                    <li>{{ $t('message.dmcaPolicyDescription8') }}</li>
                </ul>

                <p>{{ $t('message.dmcaPolicyDescription9') }}</p>
                <p>{{ $t('message.dmcaPolicyDescription10') }}</p>
                <p>{{ $t('message.dmcaPolicyDescription11') }}</p>
                <p>{{ $t('message.dmcaPolicyDescription12') }}</p>
                <hr>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: "DMCA"
}
</script>

<style scoped>

</style>